@import '~@estimateone/frontend-components/src/css/variables';

.niceOne {
  display: flex;
  align-items: flex-start;
  color: $primary-magic-green;
  line-height: 1.2;
}

.image {
  height: .85em;
  margin-top: .12em;
  margin-left: $spacer-3;
  transform: rotate(-10deg);
}
