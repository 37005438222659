@import '~@estimateone/frontend-components/src/css/variables';
@import '~@estimateone/frontend-components/src/components/HintCard/styles.module.scss';

.creditBalanceContainer {
  @extend .hintCard;
  margin-top: $space-16;
}

.heading {
  @extend .heading;
  margin-bottom: $space-8;
  margin-left: $space-2;
  align-items: left;
  justify-content: space-between;
}

.creditBalanceBody {
  text-align: left;
}

.enquireLinkSpacer {
  margin-right: $space-4;
  display: inline-block;
}

.creditBalanceTitle {
  text-align: left;
}
