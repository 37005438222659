@import '~@estimateone/frontend-components/src/css/variables';

.connectionPreferenceInfoCard {
  grid-template-columns: 0 1fr;

  & > p {
    color: $primary-practical-blue;
    font: $font-p-heavy;
  }

  ul {
    margin: 0;
    font: $font-ps-reg;
    padding-inline-start: 20px;
  }
}
