@import '~@estimateone/frontend-components/src/css/variables';

.columnMenuIcon {
  width: 19px;
  height: 19px;
  margin-right: $space-8;
}

.columnMenuItem {
  padding: $space-8 $space-16;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $space-16;

  label,
  input {
    margin: 0;
  }
}
