@import '~@estimateone/frontend-components/src/css/variables';

.tenderResponses {
  margin-top: $space-24;
}

.emptyState {
  font: $font-ps-reg;
  font-style: italic;
}
