@import '~@estimateone/frontend-components/src/css/variables';

.notifyTable {
  table {
    thead {
      position: relative;
      z-index: 100;

      th {
        top: -$space-32;
      }

      th ~ th {
        width: 140px;
        min-width: 140px;
        max-width: 140px;
      }
    }

    tbody {
      tr {
        td ~ td {
          width: 140px;
          min-width: 140px;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.masterCheckbox {
  position: static;
  margin-left: -$space-8;

  &:hover {
    &:not(.hasError) {
      content: '';
      position: static;

      &::before {
        background: unset;
      }
    }
  }
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
}

.sliderFooterLeft {
  display: flex;
  justify-content: flex-start;
}

.sliderFooterRight {
  display: flex;
  justify-content: flex-end;

  button ~ button {
    margin-left: $space-16;
  }
}

.sliderButton {
  width: 192px;
}

.awardPackageForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notifyPackageText {
  font: $font-p-reg;
}

.notifySelectCount {
  margin-top: 32px;
  margin-bottom: 8px;
  font: $font-ps-reg;
}

.emailPreview {
  margin-top: $space-16;
  padding: $space-24;
  border-radius: 4px;
  background-color: $iron-025;

  p {
    margin-bottom: $space-32;
    color: $iron-600;
    font: $font-ps-reg;
  }

  .emailPreviewFooter p {
    margin-bottom: 0;
    color: $iron-600;
    font: $font-ps-reg;
  }
}

.noEmailExists {
  color: $iron-600;
  font-style: italic;
}
