@import '~@estimateone/frontend-components/src/css/variables';

.quoteStatusTime {
  margin: $space-8 0 0 0;
  font-size: $font-size-xs;
}

.tags {
  display: flex;
}

.quotingStatus {
  margin-right: $space-4;
}
