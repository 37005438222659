@import '~@estimateone/frontend-components/src/css/variables';

.addNoteContainer {
  padding-bottom: 0;
}

.notesSlideoutHeader {
  margin: 0;
  color: $white;
  font: $font-heading-2;
  line-height: 125%;
}

.notesSlideoutFooter {
  margin: 0;

  button {
    width: 192px;
  }
}

.addNoteIntroText {
  margin: 0 0 $space-8;
  color: $primary-practical-blue;
  font: $font-p-reg;
}

.notesHistoryContainer {
  height: 100%;
  padding-top: 0;
}

.notesHistoryHeader {
  margin: $space-32 0 $space-16 0;
  color: $primary-practical-blue;
  font: $font-heading-2;
}
