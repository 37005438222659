.alert {
  margin-bottom: $spacer-3;
  padding: $spacer-3;
  color: $primary-practical-blue;
  font: $font-p-reg;

  &.alert-dismissible {
    padding-right: calc(#{$spacer-3} + 20px);

    &.data-invalid {
      opacity: 0.5;
    }

    .close {
      top: 0;
      opacity: 1;
      font-size: 1.5rem;

      .icon-close {
        @extend .icon-xs;
      }
    }
  }

  &.alert-default {
    border-radius: 4px;
    box-shadow: 0 0 16px rgba($black, 0.08);
    background-color: $white;

    &.alert-dismissible {
      .close {
        color: $iron-300;
      }
    }
  }

  &.alert-success {
    border-color: $magic-600;
    background-color: $magic-050;
  }

  &.alert-info {
    border-color: $practical-600;
    background-color: $practical-050;
  }

  &.alert-danger {
    border-color: $crimson-600;
    background-color: $crimson-050;
  }

  &.alert-warning {
    border-color: $energy-600;
    background-color: $energy-050;
  }

  a {
    @extend %a-primary;
  }
}

.hide-warning {
  display: none;
}

div.alert {
  &[data-notify='container'] {
    max-width: 280px;
    transition: all 3s ease;
    border: 1px solid $silver-chalice;
    border-radius: 5px;
    box-shadow: 0 0 13px 1px rgb(0 0 0 / 25%);
    background: $wild-sand;
    color: $tundora;

    &.alert-warning {
      background: $peach;
      color: $heath;
    }

    span[data-notify='icon'] {
      float: left;
      margin-right: 8px;
      padding-right: 8px;
      display: block;
      opacity: 0.6;
      border-right: 1px solid $silver;
      font-size: 19px;
    }

    span[data-notify='title'] {
      display: block;
      font-weight: bold;
    }
  }
}

div.alert-e1-success[data-notify='container'] {
  min-width: 330px;
  padding: $spacer-3 $spacer-5;
  border-radius: 4px;
  box-shadow: 0 5px 15px 0 rgba($primary-practical-blue, 0.3);
  background: $primary-practical-blue;
  color: $white;
  font: $font-ps-reg;

  > span[data-notify='title'],
  > button[data-notify='dismiss'] {
    color: $iron-300;
  }

  > button[data-notify='dismiss'] {
    margin: -3px -5px 0 0;
    opacity: 1;
    text-shadow: none;

    &:hover {
      color: $white;
    }
  }
}
