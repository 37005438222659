#subnav {
  .layout {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .subnavicon {
    color: $practical-900;
  }

  .subnavtitle {
    h2 {
      margin: initial;
    }
  }

  .subnavlink {
    display: flex;
    align-items: center;
    gap: 8px;

    .subnavlink__text {
      @extend %a-link;
      font-weight: 600;
    }
  }
}
