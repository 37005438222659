@import '~@estimateone/frontend-components/src/css/variables';

.itemList {
  padding: $spacer-3 0;
  list-style: none;

  &:last-of-type {
    padding-bottom: 0;
  }

  > li {
    display: flex;
    justify-content: space-between;
    line-height: 2;

    &.green {
      color: $primary-magic-green;
      font-weight: $font-semibold;
    }
  }
}

.label {
  margin-right: $spacer-5;
}

.descriptionLabel {
  font-size: $font-size-xs;
  font-weight: $font-semibold;
}

.orderItems {
  border-top: 1px dashed $iron-200;
  border-bottom: 1px dashed $iron-200;
}

.orderPaymentText p {
  margin: 0;
  color: $practical-500;
  font-size: $font-size-sm;
  font-weight: $font-semibold;
  line-height: $font-lh-lg;

  &:last-child {
    margin-bottom: $space-16;
  }
}

.total {
  border-top: 1px solid $primary-practical-blue;
  font-weight: $font-semibold;
}
