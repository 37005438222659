@import '~@estimateone/frontend-components/src/css/variables';

.yesterday {
  color: $color-date-past;
}

.today {
  color: $color-date-today;
}

.tomorrow {
  color: $color-date-tomorrow;
}

.past {
  color: $iron-600;
}

.future {
  color: $color-date-future;
}

.projectDate {
  position: relative;
  white-space: nowrap;
}

.disclaimerSymbol {
  position: absolute;
  right: -0.475em;
}
