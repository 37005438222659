@import '~@estimateone/frontend-components/src/css/variables';

.projectSuppliers {
  margin-bottom: $space-32;
  display: flex;
  flex-direction: column;
  gap: $space-16;

  > h3 {
    margin-bottom: 0;
  }
}

.noTradesAlert {
  color: $primary-practical-blue;
  font: $font-p-reg;
}

.noTradesAlertLink {
  margin-left: $space-4;
  color: $primary-practical-blue;
  font: $font-p-reg;
}
