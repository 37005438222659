@import '~@estimateone/frontend-components/src/css/variables';

.confirmInvites {
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.addressBookMessage {
  margin-bottom: $space-48;
}

.sendInvites {
  font: $font-p-heavy;
}

.multiButtonContainer {
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}
