.footer {
  width: 100%;
  min-width: $min-container-width;
  margin-top: $spacer-5;
  padding: $spacer-5 0;
  flex-shrink: 0;
  background-color: $iron-025;

  .inner-footer {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 0 $spacer-5;
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    color: $iron-600;
    font: $font-tiny-reg;
  }
}

.maintenance-warning {
  padding: $spacer-3 0;
  color: $white;
  text-align: center;

  span {
    max-width: $max-container-width;
    margin: 0 auto;
    display: inline-block;
  }
}

.bottom-bar {
  position: fixed;
  z-index: 150;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba($iron-300, 0.4);
  text-align: center;

  @media print {
    display: none;
  }

  .cookie-warning,
  .staging-warning {
    padding: $spacer-3 0;
    color: $white;
  }

  .cookie-warning {
    position: absolute;
    z-index: 500;
    bottom: 0;
    width: 100%;
    background: $volt-600;
    text-align: left;

    p,
    .cookie-warning__link {
      font: $font-ps-reg;
    }

    .cookie-warning__link {
      font-weight: $font-medium;
    }
  }

  .staging-warning {
    background-color: lighten($crimson-700, 30%);
  }
}

.table-control-panel-container {
  background: $primary-practical-blue;
  color: $white;

  .table-control-panel {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: $spacer-3 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      padding: 0;
      color: $white;
      font: $font-ps-reg;
    }
  }
}
