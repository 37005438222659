@import '~@estimateone/frontend-components/src/css/variables';

.statContainer {
  padding: $space-8 0 $space-24;
  display: flex;
}

.statGroup {
  padding: 0 $space-32 0 0;
  display: flex;
  gap: $space-16;

  & + .statGroup {
    padding: 0 $space-32;
    border-left: 1px solid $iron-200;
  }
}

.statTitle {
  @extend %font-mini-caps;
  margin: 0 0 $space-4;
}

.statCount {
  margin: 0 $space-4 0 0;
  display: inline;
  color: $primary-practical-blue;
  font: $font-p-heavy;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.statNeutral {
  color: $practical-900;
}

.statSuccess {
  color: $power-700;
}

.statDanger {
  color: $crimson-600;
}

.statPercentage {
  display: inline;
  color: $primary-link-blue;
  font: $font-tiny-reg;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.statCurrencySymbol {
  margin-right: $space-4;
  font: $font-ps-heavy;
}

.financialStat {
  min-width: 100px;
}

.varianceTitle {
  @extend %font-mini-caps;
  float: left;
  margin: 0 0 $space-4;
}

.varianceTooltip {
  float: left;
  margin-left: $space-4;
  font-size: x-small;
}
