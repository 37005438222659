@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../../../mixins';

.contactDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $iron-700;

  .redactedText {
    @include redactedText($iron-500);
  }

  .contactPhone {
    margin-bottom: $spacer-2;
  }

  .builderEmail {
    padding-top: $spacer-1;
    background: none;
    text-decoration: underline;
    text-underline: $iron-600;

    &:hover {
      background: none;
    }
  }
}
