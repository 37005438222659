@import '~@estimateone/frontend-components/src/css/variables';

.errorIconContainer {
  padding: $space-24 $space-16;
  display: flex;
  flex: auto;
  align-items: center;
}

.container {
  padding: $space-24 $space-16;
  flex: auto;
}
