@import '~@estimateone/frontend-components/src/css/variables';

.deleteLogoButton {
  position: relative;
  width: 100px;
  height: 100px;
}

.deleteCompanyLogoContainer {
  position: absolute;
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $practical-900;
  color: $white;
  inset: 0;

  &:hover {
    opacity: 0.8;
  }
}

.text {
  margin: 0;
  font: $font-ps-med;
}

svg.deleteIcon {
  stroke: $white;
}
