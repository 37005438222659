.action-bar {
  position: absolute;
  z-index: 300;
  bottom: $spacer-6;
  right: $spacer-5;
  display: flex;
  flex-direction: row;
  gap: $space-8;
}

// this is functionality moved from help.scss, some of the older sliders
// move these buttons instead of overlaying
body.super-slider-active {
  .action-bar {
    right: ($slider-width + 20);
    box-shadow: none;
  }
}
