@import '~@estimateone/frontend-components/src/css/variables';

.subHeader {
  margin-top: $spacer-3;
}

.sectionHeading {
  margin: $spacer-6 0 $spacer-4 0;
}

.section {
  margin-top: $spacer-3;
}

.helpLink {
  margin-left: $spacer-1;
}
