@import '~@estimateone/frontend-components/src/css/variables';

.noDataText {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
}

.companyName {
  margin-right: 0.5rem;
  font: $font-ps-reg;
}

.awardedTag {
  display: inline-flex;
}

.quoteAmount {
  text-align: right;
}

.quoteAmountHeader {
  flex-grow: 1;
  text-align: right;
}
