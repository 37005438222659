@import '~@estimateone/frontend-components/src/css/variables';

.progressContainer {
  display: flex;
  flex-direction: column;
  gap: $space-32;

  h3 {
    margin: 0;
  }
}

.progressHeading {
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.progressInfo {
  color: $iron-600;
  font: $font-ps-reg;
}
