@import '~@estimateone/frontend-components/src/css/variables';

.title {
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.errorAlert {
  display: flex;
  color: $color-error;
  gap: $space-8;
}

.errorIcon {
  stroke: $color-error;
  fill: $color-error;
}
