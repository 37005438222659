@import '~@estimateone/frontend-components/src/css/variables';

.distance-filter-container {
  line-height: 43px;
}

.project-print {
  &.container {
    padding: 30px;

    .project-print-title {
      margin-bottom: 20px;
      padding-bottom: $spacer-3;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $mine-shaft;
      background: none;
      line-height: 36px;

      .project-id {
        font-weight: bold;
      }
    }

    .project_info_panel {
      display: flex;
      justify-content: space-between;

      div {
        flex-basis: 0;
        flex-grow: 1;

        .project-info {
          font: $font-intro;
        }
      }
    }

    .has-trade {
      font-weight: bold;

      &:before {
        content: '\e013';
        font-family: 'Glyphicons Halflings';
        line-height: 11px;
      }
    }
  }
}

a {
  &.rfq-revision-link-trigger {
    color: $matisse;
    font-weight: bold;
    text-decoration: none;
  }
}
