@import '~@estimateone/frontend-components/src/css/variables';

.grid {
  margin: $space-48 0 $space-64;
  display: grid;
  column-gap: $space-80;
  grid-template-columns: 1fr 2fr;

  h2 {
    margin: 0;
    font: $font-heading-3;
  }
}

.footer {
  padding: $space-32 0;
  background-color: $iron-050;

  .footerWrapper {
    display: flex;
    justify-content: space-between;
  }

  .footerButton {
    min-width: 192px;
  }
}

.loadingSpinner {
  margin-top: $space-16;
}
