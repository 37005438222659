@import '~@estimateone/frontend-components/src/css/variables';

.dates {
  margin-top: $space-24;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.date {
  width: 264px;
}

.discipline {
  margin-top: $space-24;
}

.buttons {
  margin-top: $space-48;
  display: flex;

  :first-child {
    margin-right: $space-16;
  }
}

.modalHeading {
  margin: 0 0 32px;
  font: $font-heading-3;
}
