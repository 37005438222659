@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeading {
  margin: 0;
  font: $font-heading-2;
}

.stepperHeader {
  padding: $space-24;
  display: flex;
  align-items: center;
  justify-content: center;
}
