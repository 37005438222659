@import '~@estimateone/frontend-components/src/css/variables';

.cta {
  padding: $spacer-3 $spacer-5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

.buttonContainer {
  margin-left: $spacer-7;
  display: flex;

  button,
  a {
    white-space: nowrap;

    + button,
    + a {
      margin-left: $spacer-2;
    }
  }
}

// Colour Variants
.green {
  background-color: $magic-050;
}

.yellow {
  background-color: $energy-050;
}

// Size variants
.small {
  font: $font-p-heavy;
}

.medium {
  font: $font-intro;
}

.large {
  font: $font-heading-3;
}
