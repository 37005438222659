@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../../../../css/_shared/borders';

.setting {
  display: grid;
  grid-template-columns: 1fr;
}

.settingHeading {
  margin: 0 0 $space-24;
  font: $font-intro;
}

.toggleWrapper {
  margin: 0 0 $space-16;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font: $font-p-heavy;
}

.explanationCard {
  padding: $space-24;
  display: grid;
  border-radius: $border-radius-base;
  background-color: $practical-025;
  gap: $space-8;

  h3 {
    margin: 0;
    font: $font-p-heavy;
  }

  ul {
    padding: 0 $space-24;
  }

  li {
    margin: $space-8 0;
    font: $font-p-reg;
  }
}

.alert {
  margin-bottom: $space-24;
}

.alertContents {
  display: flex;

  h3 {
    margin: 0;
    font: $font-p-heavy;
  }

  p {
    margin: 0;
  }
}
