@import '~@estimateone/frontend-components/src/css/variables';

.keyFeature {
  display: flex;

  + .keyFeature {
    margin-top: $spacer-3;
  }
}

.tick {
  width: auto;
  height: 3.6rem;
  margin-right: $spacer-4;
}

.heading {
  margin-bottom: $spacer-2;
  font: $font-heading-3;
}
