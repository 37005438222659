.datepicker table tr td {
  &.day:hover,
  &.focused {
    background-color: $magic-050;
  }

  &.active {
    &,
    &:active,
    &.active,
    &.highlighted:active,
    &.highlighted.active {
      background-color: $primary-magic-green;
      text-shadow: none;

      &:hover {
        background-color: $magic-700;
      }
    }
  }

  &.disabled {
    &,
    &:hover {
      border-radius: 0;
      background-color: $iron-025;
      color: $iron-300;
    }
  }
}

/**
 Bug fix layout issues between bootstrap 3 and react-datepicker
 */

.date-picker > div > div.react-datepicker-wrapper,
.date-picker > div > div.react-datepicker-wrapper > div.react-datepicker__input-container {
  display: block !important;
}
