@import '~@estimateone/frontend-components/src/css/variables';

.officeLocationContainer {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.officeLocationContainer {
  & > li {
    position: relative;
    margin-right: 13px; // special case where $spacer doesn't support.
    margin-bottom: 0;
    font-size: $font-size-sm;
    font-weight: $font-semibold;
  }

  & > li::after {
    content: '';
    position: absolute;
    top: 3px;
    width: 4px;
    height: 4px;
    margin: 5px;
    border-radius: 50%;
    background-color: $magic-600;
  }

  & > li:last-child::after {
    display: none;
  }
}
