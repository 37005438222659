@import '~@estimateone/frontend-components/src/css/variables';

.projectSettingsSection {
  margin-top: $space-48;
  display: grid;
  grid: 1fr / 1fr;
}

.headingSection {
  display: flex;
  align-items: center;
}

.settingsTitle {
  margin-top: $space-16;
  font: $font-p-reg;
}

.optionsContainer {
  margin-top: $space-4 !important;
  margin-bottom: $space-24 !important;
}

.optionLabel {
  font: $font-p-reg;
}

.optionButton {
  margin-bottom: $space-8 !important;
}
