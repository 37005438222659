.project_map {
  .map_legend {
    margin: 10px 0 15px;
    padding: 10px;
    border-top: 1px solid $seashell;
    border-bottom: 1px solid $seashell;
    font-size: 11px;

    img {
      margin-right: 5px;
    }
  }

  #map-view {
    clear: both;
    width: 100%;
    height: 700px;
    margin-top: 0;
    display: block;
    background: $athens-gray;
  }
}

.project-infowindow {
  max-width: 300px;

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 16px;
  }

  p {
    padding: 10px 0;
    color: $emperor;
    font-size: 11px;
    line-height: 14px;
  }

  .tenders {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $catskill-white;
    font-size: 12px;

    a {
      color: $astral;
      text-decoration: underline;
    }

    .map-info-panel-date {
      margin-left: 0.5em;
    }
  }
}
