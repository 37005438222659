@import '~@estimateone/frontend-components/src/css/variables';

.tradeContainer {
  min-height: $space-24;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  row-gap: $space-4;

  >* {
    margin-right: $space-4;
  }

  .showHideLinks {
    margin-left: $space-2;
    font-size: $font-size-sm;
  }
}
