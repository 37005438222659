@import '~@estimateone/frontend-components/src/css/variables';

.addressFieldWrapper {
  width: 100%;
  display: grid;
  grid-gap: $space-12;
  grid-template-columns: 1fr;
}

.adjacentFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $space-24;
}
