@import '~@estimateone/frontend-components/src/css/variables';

.container {
  padding: $space-24 $space-16;
  display: flex;
  column-gap: $space-16;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}

.loadingState {
  padding: $space-24 $space-16;
  flex: auto;
}

.hoverEffect {
  cursor: pointer;

  &:hover {
    background-color: $magic-050;
  }
}

.verifiedAbnIcon {
  stroke: $practical-500;
}

.errorAbnIcon {
  color: $crimson-500;
  font-size: #{$default-font-size * 2};
  font-weight: $font-semibold;
}
