@import '~@estimateone/frontend-components/src/css/variables';

.resultDescription {
  padding-bottom: $space-16;
  font: $font-ps-reg;
}

.resultList {
  display: grid;
  list-style: none;
  gap: $space-16;
}
