@import '~@estimateone/frontend-components/src/css/variables';

.infoPackageName {
  font: $font-p-heavy;
}

.infoText {
  padding-left: $space-16;
  display: flex;
  flex-direction: column;
  gap: $space-16;
}

.alert {
  margin-top: $space-32;
}

.alertContent {
  display: flex;
  font: $font-p-reg;
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
}

.sliderButton {
  width: 192px;
}

.optionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionTag {
  height: 24px;
  margin-left: $space-8;
}

.noOptionsText {
  width: 100%;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
  text-align: left;
}

.awardPackageForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
