@import '~@estimateone/frontend-components/src/css/variables';

.cardContainer {
  padding: $space-16;
  border: 1px solid $iron-100;
  border-radius: $space-4;
  background-color: $white;
  color: $practical-900;
  font-family: $default-font-family;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.builderName {
  margin-bottom: $space-4;
  font: $font-ps-heavy;
}

.projectName {
  font: $font-p-med;
}

.cardTags {
  margin: $space-8 0;
  display: flex;
  flex-wrap: wrap;
  gap: $space-8;
}

.historyTag {
  border: 1px solid $iron-100;
  border-radius: $space-4;
  color: $iron-600;
}
