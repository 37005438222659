@import '~@estimateone/frontend-components/src/css/variables';

.settingTitle {
  font: $font-p-heavy;
}

.settingOptionsContainer {
  margin-top: $space-4 !important;
  margin-bottom: $space-24 !important;
}

.settingOptionButton {
  margin-bottom: $space-8 !important;
}

.settingOptionLabel {
  font: $font-p-reg;
}

.settingSaveButtonContainer {
  padding-top: $space-24;
  display: flex;
  justify-content: flex-end;
}

.settingOptionSaveButton {
  width: 192px;
}
