@import '~@estimateone/frontend-components/src/css/variables';

.nameAndConnectionIndicator {
  display: block;

  & > div {
    margin: $space-8 $space-8 $space-8 0;
    display: inline-flex;
  }
}

.heading {
  margin: 0 $space-8 0 0;
  display: inline;
  font: $font-heading-2;
}

.altCompanyName {
  padding-top: $space-12;
  display: inline-flex;
  align-items: flex-start;
  color: $iron-600;
  font: $font-p-reg;
}
