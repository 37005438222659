@import '~@estimateone/frontend-components/src/css/variables';

.emailPreview {
  padding: $space-24;
  border-radius: 4px;
  background-color: $iron-025;
  color: $iron-600;
  font: $font-ps-reg;
}

.greeting {
  margin-bottom: $space-16;
  font: $font-p-reg;
}

.detailsContainer {
  margin-top: $space-24;
  margin-bottom: $space-24;
  padding: $space-24;
  border: solid 1px $iron-200;
  border-radius: 4px;
}

.lineBreakAfter {
  margin-bottom: $space-16;
}

.detailItem {
  margin: 0;
}

.signature {
  margin: 0;
}
