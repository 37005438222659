@import '~@estimateone/frontend-components/src/css/variables';

.content {
  color: $practical-900;
  font: $font-ps-reg;
  white-space: pre-wrap;
}

.buttonContainer {
  margin-top: $space-4;
  display: flex;
  justify-content: flex-end;
  font: $font-ps-heavy;
}
