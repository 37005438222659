@import '~@estimateone/frontend-components/src/css/variables';

.notifyTableHeaderCheckbox {
  margin: 0 auto;
  margin-top: -6px;

  svg {
    margin-left: 0;
  }
}

.notifyToggleLabel {
  cursor: pointer;
  font: $font-p-reg;
}

.notifyStepTableHeading {
  margin: 0;
  font: $font-heading-3;
}

.notifyStepTableHeadingToggleContainer {
  display: flex;
  align-items: baseline;
  gap: $space-8;

  p {
    margin-bottom: 0;
  }
}

.notifyStepTableHeadingHelp {
  display: flex;
  align-items: center;
  gap: 6px;
}

.notifyTablePackageTitleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $space-8;
}

.notifyTableHeaderCheckboxColumnText {
  width: 100%;
  height: 100%;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-8;
}

.notifyTableHeaderCheckboxColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-8;
}

.notifyTableHeaderCheckboxColumnCheck {
  min-height: 16px;
  margin: auto;
  margin-top: $space-16;
  margin-bottom: calc($space-8 / 2);

  svg {
    margin-left: 0;
  }
}

.notifyStepDisabledData {
  color: $iron-300;
}

.notifyTableHeaderSubText {
  font-weight: normal;
}

.notifyStepContainer {
  display: flex;
  color: $primary-practical-blue;
  gap: 4.5rem;
}

.notifyStepTableContainer {
  min-width: 720px;

  table th {
    vertical-align: bottom;
    white-space: normal !important;
  }

  table th:first-child {
    z-index: 2;
  }

  table th:nth-child(-n + 2) {
    vertical-align: middle;
  }

  table th:nth-child(n + 2) {
    max-width: calc(120px - 24px);
  }

  tbody tr td:nth-last-child(-n + 3) {
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .notifyStepTableContainer {
    min-width: 520px;
  }
}

.notifyStepTableHeaderContainer {
  margin: $space-24 0 $space-16 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifyStepNoPackagesEmptyStateText {
  margin-top: $space-16;
  color: $iron-600;
  font-style: italic;
  text-align: center;
}

.notifyStepMessageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notifyStepPreviewHeading {
  margin: 0 0 $space-24;
  font: $font-heading-3;
}

.notifyStepEmailSubject {
  width: 100%;
}

.notifyStepEmailMessage {
  textarea {
    width: 100%;
    margin-top: $space-8;
    border: 2px solid $iron-100;
    border-radius: 4px;
  }
}

.notifyStepEmailMessageError {
  textarea {
    border-color: $crimson-600;
  }
}

.notifyStepEmailMessage:focus-within {
  textarea {
    border-color: $primary-practical-blue;
  }
}

.notifyStepEmailMessageErrorText {
  margin-top: $space-8;
  color: $crimson-600;
  font-size: $font-size-sm;
  font-weight: $font-semibold;
  line-height: 1.5;
}

.notifyStepEmailMessageContainer {
  margin-top: $space-24;
  display: flex;
  flex-direction: column;
}

.notifyStepEmptyStateText {
  color: $iron-600;
  font-style: italic;
}

.downloadedTitleCentered {
  margin: auto;
  text-align: center;
}
