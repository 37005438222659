@import '~@estimateone/frontend-components/src/css/variables';

.profileBadgeIconWrapper {
  box-sizing: border-box;
  width: $space-40;
  height: $space-40;
  padding: $space-8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $space-8;

  &.info {
    background-color: $practical-025;
  }

  &.error {
    background-color: $crimson-050;
  }
}
