@import '../../../../../../css/_shared/variables';
@import '../../../mixins';

.hideShowWrapper {
  a.hideShow {
    margin-bottom: $spacer-5;
    display: inline-block;
    color: $practical-900;
    font-size: 12.5px;
    font-weight: bold;
    text-decoration: none;
  }

  .disableHideShowAnchor {
    pointer-events: none;
  }

  .fadedChevron {
    margin-left: 6px;
    opacity: 0.2;
  }
}
