@import '~@estimateone/frontend-components/src/css/variables';

div.card {
  border-width: 1px;
  border-radius: 12px;
  background-color: $white;

  & > div {
    border-bottom-width: 1px;
  }
}
