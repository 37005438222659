@import '~@estimateone/frontend-components/src/css/variables';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.employeeIcon {
  fill: transparent;
}
