@import '~@estimateone/frontend-components/src/css/variables';

.editableDate {
  background-color: inherit;

  &:hover {
    margin: 1px;
    border: 1px solid $iron-500;
    border-radius: 2px;
    background-color: $white;
  }

  &:focus {
    margin: 0;
    padding: 6px;
    border: 2px solid $primary-magic-green;
    border-radius: 4px;
    background-color: $white;
  }

  &::placeholder {
    color: $iron-400;
    font: $font-ps-reg;
  }
}

.editableDate {
  box-sizing: content-box;
  width: 104px;
  height: 20px;
  margin: 2px;
  padding: 6px;
  border: 0;
  color: $practical-900;
  font: $font-ps-med;

  &::placeholder {
    color: $iron-400;
    font: $font-ps-reg;
  }

  &:focus {
    margin: 0;
    border: 2px solid $primary-magic-green;
    border-radius: 4px;
  }
}
