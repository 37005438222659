@import '~@estimateone/frontend-components/src/css/variables';

.topButtons {
  margin-bottom: $space-8;
}

.fullSetWarning {
  padding-top: 6px;
  padding-right: 4px;
  display: inline-block;
  color: $crimson-500;
}

.btnContainer {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 50% 50%;

  button,
  div,
  a {
    margin-left: $spacer-1;
    flex-basis: 0;
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }
  }
}
