@import '~@estimateone/frontend-components/src/css/variables';

.networkToggleContainer {
  label {
    margin-bottom: 0;
  }

  input:focus{
    outline: none;
  }
}

.divider {
  width: 1px;
  height: 36px;
  background-color: $iron-100;
}
