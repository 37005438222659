@import '~@estimateone/frontend-components/src/css/variables';

.body {
  width: 100%;
  margin: 0 auto;
  padding: $space-24 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: $space-24;
}

.details {
  flex-shrink: 1;
}

.button {
  flex-shrink: 0;
}
