@import '~@estimateone/frontend-components/src/css/variables';

.accordianTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.tenderResponses {
  margin-top: $space-32;
}

.LoadingSpinner {
  margin-top: $space-16;
}
