@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../../mixins.scss';

.stageAwarded {
  background: mix($practical-050, $white, 50%);
}

.stage-private {
  background: rgba($practical-500, 0.03);
}

.builderNameContainer {
  display: flex;
  justify-content: space-between;
}

.builderName {
  margin-bottom: $spacer-2;

  &.redactedText {
    @include redactedText($iron-500);
  }

  a {
    font-size: $default-font-size;
    font-weight: $font-semibold;
  }
}

.quotesDueCell {
  min-width: 200px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
}

.constructionStartEnd {
  .start {
    margin-bottom: 0;
    font-size: 0.9em;
  }

  .end {
    font-size: 0.9em;
  }

  .date {
    float: right;
  }
}

.constructionStartEndPaywalled {
  padding: $spacer-2;
  border-radius: 4px;
  background-color: $iron-025;

  svg {
    fill: $iron-600;
  }

  .estDatesText {
    margin-left: $spacer-2;
  }

  .upgradeLink {
    margin-left: $spacer-4;
  }
}

.sdRow {
  &:not(:first-child) {
    /* stylelint-disable selector-pseudo-class-disallowed-list */
    margin-top: $spacer-2;

    button:not(:disabled),
    a:not(:global(.disabled)) {
      color: $primary-practical-blue;
    }
  }
}

.withdrawnStageRow {
  color: $iron-600;

  a {
    color: $iron-600;
  }
}

.label {
  padding-bottom: $spacer-1;
  display: block;
  font: $font-tiny-heavy;
}

.stageDocs {
  width: 40%;
}

.incognito {
  font: $font-p-heavy;
}
