@import '~@estimateone/frontend-components/src/css/variables';

.header {
  padding: $space-32 0;
  background-color: $primary-practical-blue;
  color: $white;

  h1 {
    margin: 0;
    font: $font-heading-2;
  }
}

.breadcrumb {
  margin-bottom: $space-8;
  display: flex;
  align-items: center;

  a {
    margin-left: $space-4;
    color: $white;
    font: $font-ps-reg;
  }
}
