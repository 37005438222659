@import '~@estimateone/frontend-components/src/css/variables';

.companyHeader {
  width: 100%;
  padding: $space-24;
  display: flex;
  align-items: flex-start;
  gap: $space-16;
}

.companyNameColumn {
  width: 100%;
  margin-bottom: $space-4;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.nameAndLocationText {
  display: flex;
  flex-direction: column;
  color: $primary-practical-blue;
  font: $font-p-reg;
  gap: $space-8;
}

.accountStatusColumn {
  margin-bottom: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: $space-16;
}
