@import '~@estimateone/frontend-components/src/css/variables';

.cardPicker {
  text-align: left;
}

.cardSelector {
  padding: $spacer-2 0;
}

.creditCard {
  padding: $spacer-3 0;
  display: flex;
  flex-direction: row;
  font: $font-ps-reg;

  > input {
    margin: 0;
  }
}

.cardLabel {
  padding-left: $spacer-1;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}
