@import '~@estimateone/frontend-components/src/css/variables';

$awarded-icon-color: #efbe33;

.modalTitle {
  margin-bottom: $spacer-4;
  text-align: center;

  .headingProjectName {
    font: $font-p-heavy;
  }

  h2 {
    margin-bottom: 0;
  }

  h4 {
    margin-top: $spacer-2;
  }

  svg {
    stroke: $awarded-icon-color;
    fill: $awarded-icon-color;
  }
}

.modalTitleNoIcon {
  margin-top: $spacer-5;
}

.buttonContainer {
  margin-top: $spacer-5;
  display: flex;
  justify-content: flex-start;

  button {
    margin-right: $spacer-2;
  }
}

.modalContent {
  padding: 0 $spacer-4;

  h2 {
    margin-top: $spacer-4;
  }

  .descriptionText {
    font-size: $font-size-sm;
  }
}

.errorMessageContainer {
  margin-top: $spacer-4;
}

.checkboxContainer {
  margin: $spacer-2 0 $spacer-3 $spacer-2;
  list-style-type: none;

  .checkboxListItem {
    margin: $spacer-3 0;
  }
}

.awardedSubbieRadioButtonGroupWrapper {
  padding-bottom: $space-24;

  label {
    font-weight: normal;
  }
}

.contactDetailsContainer {
  margin: $spacer-3 0 $spacer-2 0;
}
