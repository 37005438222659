@import '../styles.scss';

.table {
  width: 100%;
  margin-top: $space-8;
  border-collapse: collapse;

  thead {
    font: $font-tiny-heavy;
    text-align: left;
    white-space: nowrap;

    th {
      position: sticky;
      top: 0;
      padding: $space-12;
      background-color: $iron-050;

      &:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $iron-050;
      font: $font-ps-reg;

      &.expandible {
        td {
          background: #f7f7f7;
          font-weight: $font-semibold;
        }
      }
    }

    td {
      padding: $space-12;
    }
  }

  .fileNameCell {
    display: flex;
    gap: $space-8;
  }

  .folderCell {
    display: flex;
    gap: $space-8;
  }
}

.detailsForm {
  max-width: 616px;

  .fieldWrapper {
    margin-bottom: $space-24;
  }

  .descriptionField {
    width: 100%;
    margin-top: 8px;
    padding: 10px 14px;
    border-width: 2px;
    border-color: $iron-100;
    border-radius: 4px;
  }
}
