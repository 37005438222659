@import '../../../../../../../../../css/_shared/variables';

.tradeSection {
  margin-top: $spacer-4;
  padding: 0 30px;
  text-align: left;
}

.tradesHeaderCreditExperiment {
  margin-bottom: $spacer-1;
  font: $font-p-heavy;
}

.outofUnlockMatching {
  display: flex;
  align-items: center;
  stroke: $electric-500;
  fill: $electric-500;
  color: $primary-practical-blue;
  font: $font-ps-heavy;
}

.matchingTrades {
  margin-bottom: $space-8;
  margin-left: 0;
}

.matchingTrade {
  display: flex;
  align-items: center;
  color: $primary-practical-blue;
  font: $font-ps-heavy;
}

.outOfUnlocksIcon {
  stroke: $electric-500;
  fill: $electric-500;
}

.hasUnlocksIcon {
  stroke: $magic-600;
}

.noTradeMatches {
  display: flex;
  align-items: center;
  color: $crimson-700;
  font: $font-ps-heavy;

  svg {
    stroke: $crimson-700;

    circle {
      fill: $crimson-700;
    }
  }
}

.noTradeMatchesText {
  margin-left: $spacer-1;
}

.tradesInvolved {
  font: $font-ps-reg;
}

.matchingTradeName {
  margin-left: $spacer-1;
}

.andMore {
  font: $font-ps-reg;
}
