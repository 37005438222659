@import '~@estimateone/frontend-components/src/css/variables';

.modalSection {
  padding: 0 $space-16;
  display: grid;
  row-gap: $space-32;
}

.modalContent {
  display: grid;
  row-gap: $space-24;
}

.modalHeader {
  font: $font-heading-3;
}

.removeConnectionProfileButtons {
  display: flex;
  column-gap: $space-16;
}

.textContainer {
  margin-top: $space-2;
  margin-bottom: $space-2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modalRadioButtonGroup {
  padding-bottom: $space-8;

  & .modalRadioButton {
    margin-bottom: $space-12;
  }

  label {
    font: inherit;
  }
}

.modalInputRow {
  margin-bottom: $space-2;
  margin-left: $space-32;
}
