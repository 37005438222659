body.signup-marketing {
  $supplier-field-font-size: $default-font-size + 4;
  background-color: $oxford-blue;

  .error-msg {
    float: left;
    margin-right: 5px;

    .help-block {
      color: $tree-poppy;
      font-size: 13px;
    }
  }

  .autocomplete_address_form {
    .help-block {
      margin-top: 22px;
    }
  }

  .manualaddress-trigger {
    left: 0;
  }

  .bottom-bar {
    display: none;
  }

  .e1-link {
    color: $caribbean-green;
    font-weight: 600;
  }

  .select2-container--bootstrap .select2-dropdown {
    border-color: $caribbean-green;
  }

  .select2-container--bootstrap .select2-results > .select2-results__options {
    background: $river-bed;
  }

  .select2-container--bootstrap .select2-results__option {
    color: $white;

    &[aria-selected='true'],
    &--highlighted[aria-selected] {
      background-color: $caribbean-green;
    }
  }

  // Selection
  ::selection {
    background: $caribbean-green;
  }

  .e1-logo {
    margin: $spacer-6 0 $spacer-5 0;

    @media (min-width: 1024px) {
      margin-top: $spacer-7;
      margin-bottom: $spacer-6;
    }
  }

  .signup-close-btn {
    position: fixed;
    top: $spacer-3;
    right: $spacer-3;
    color: $bali-hai;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

    &:focus,
    &:hover {
      color: $bali-hai;
      text-decoration: none;
    }

    i {
      font-size: 14px;

      &:before {
        margin-top: -1px;
      }
    }

    span {
      display: none;

      @media (min-width: 640px) {
        display: inline-block;
      }
    }
  }

  .header {
    height: 72px;
    padding: 49px 0 0;
    background: none;
  }

  .content {
    max-width: 768px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    background: none;

    @media (max-width: 767px) {
      max-width: 420px;
      margin-top: 0;
    }

    .right-col {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    h1 {
      margin-top: 0;
      color: $primary-magic-green;
      font-size: 1.8rem;
      line-height: 2.1rem;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1.3rem;
        line-height: 1.5rem;
      }
    }

    h5 {
      margin-bottom: 30px;
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .signup-form-container {
      padding: 0;
      border: none;
      background: none;

      form {
        input {
          color: $white;

          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            color: $heather;
          }
        }

        input[type='text'] {
          width: 100%;
          height: 48px;
          display: block;
          border: solid 2px rgb(184 195 211 / 20%);
          border-radius: 4px;
          background: rgb(255 255 255 / 12%);
          font-size: 14px;

          @media (min-width: 768px) {
            font-size: 16px;
          }
        }

        input[type='text']:focus {
          border: solid 2px $caribbean-green;
        }

        .select2-container--bootstrap {
          width: 100% !important;
          margin: 0;
          padding: 0;
          display: inline-block;
        }

        .select2-dropdown {
          background: rgb(255 255 255 / 12%);
        }

        .select2-container--bootstrap .select2-selection--multiple {
          .select2-search--inline .select2-search__field {
            width: 100% !important;
          }

          .select2-selection__choice {
            padding: 2px 5px 1px;
            background: $white;
            color: $oxford-blue;
            font-size: 12px;
          }

          .select2-selection__choice__remove {
            float: right;
            margin-left: 5px;
          }
        }

        .select2-container--bootstrap.select2-container--focus .select2-selection,
        .select2-container--bootstrap.select2-container--open .select2-selection {
          border-color: $caribbean-green;
          box-shadow: none;
        }

        .select2-selection__choice__remove {
          color: $oxford-blue;
        }

        .select2-container--bootstrap .select2-selection {
          min-height: 48px;
          padding-top: 6px;
          border: solid 2px rgb(184 195 211 / 20%);
          border-radius: 4px;
          background: rgb(255 255 255 / 12%);
          color: $white;
          line-height: 1.5;
        }

        .select2-results__option {
          background-color: rgb(255 255 255 / 12%);
        }
      }

      div.signup-supplier-choice {
        color: $catskill-white;

        label.supplier-choice-text {
          margin-top: $spacer-2;
          margin-bottom: $spacer-3;
        }

        div.radio {
          label {
            padding-top: 0;
            font-weight: bold;
          }
        }

        div.form-group.has-error label {
          color: $tree-poppy;
        }
      }

      .manualaddress-trigger {
        background: none;
        color: $caribbean-green;
        font-weight: bold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .terms {
        padding-bottom: 30px;
        color: $heather;
        font-size: 14px;
        text-align: center;

        @media (min-width: 1024px) {
          width: 365px;
        }

        a {
          color: $caribbean-green;
        }
      }
    }

    .benefits-panel {
      h3 {
        margin-bottom: 16px;
        padding: 0;
        color: $heather;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
      }

      ul.e1-ul-list {
        padding: 0 0 0 20px;
        list-style-type: none;
        line-height: 1.5rem;

        @media (min-width: 640px) {
          padding: 0 0 0 10px;
        }

        @media (min-width: 1024px) {
          padding: 0 0 0 20px;
        }

        li {
          position: relative;
          margin-bottom: 0.5rem;
          color: $white;
          font-size: 16px;
          font-weight: 600;

          &:before {
            content: '\2022';
            position: absolute;
            margin-left: -20px;
            color: $caribbean-green;
            font-size: 15px;
          }
        }
      }

      .testimonial {
        margin-top: 36px;

        &__image {
          width: 74px;
          height: 74px;
          margin-left: 15px;
          padding: 0;
          border-radius: 180px;
          background-image: url('#{$image-path}Grant-Manning-Profile@2x.jpg');
          background-size: 74px;
        }

        &__testimonial {
          padding-right: 0;

          h5 {
            margin-bottom: 16px;
            padding: 0;
            color: $white;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;

            @media (min-width: 768px) {
              padding-right: 20px;
              font-size: 16px;
            }
          }

          h4 {
            margin: 0;
            padding: 0;
            color: $heather;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
          }
        }
      }
    }
  }

  .mfp-bg {
    opacity: 0.5;
  }
}
