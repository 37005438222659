@import '~@estimateone/frontend-components/src/css/variables';

.divider {
  height: 2px;
  margin: $space-16 0;
  background-color: $iron-100;
}

.iconLinksContainer {
  display: flex;
  justify-content: space-between;
  gap: $space-32;
}

.infoBox {
  margin-right: $space-16;

  h3 {
    margin-top: 16px !important;
  }
}

.iconLink {
  padding: $space-16;
  flex: 1 1 0px;
  border: $space-2 solid $iron-100;
  background: none;
  color: inherit;
  text-align: center;
  text-decoration: none !important;
}

.iconLink:hover {
  background-color: $iron-025;
}

.iconLinkText {
  margin-top: $space-16;
  font-size: 1rem;
  font-weight: 700;
}
