@import '~@estimateone/frontend-components/src/css/variables';

.form {
  display: flex;
  flex-direction: column;
  gap: $space-16;
}

.coreTrades,
.serviceArea {
  > label {
    margin: 0 0 $space-12;
  }
}
