@mixin redactedText($color) {
  position: relative;
  margin: 1px;
  white-space: pre;
  user-select: none;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1.22em;
    background: $color;
  }
}
