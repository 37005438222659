@import '~@estimateone/frontend-components/src/css/variables';

@mixin layout {
  padding: $space-24 $space-16;
  display: flex;
  column-gap: $space-16;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}

.viewLayout {
  @include layout;
}

.hoverEffect {
  cursor: pointer;
  flex: auto;

  &:hover {
    background-color: $magic-050;
  }
}

.editLayout {
  @include layout;
}

.loadingState {
  @include layout;

  & > div {
    flex: auto;
  }
}
