@import '~@estimateone/frontend-components/src/css/variables';

.officeLists {
  padding-left: 0;
}

.officeListHeadingWrapper {
  margin-bottom: $space-16;
  display: flex;
  align-items: center;
}

.officeListHeader {
  margin: 0 $space-40 0 0;
  color: $primary-practical-blue;
  font: $font-p-heavy;
}

.officeSkeleton {
  margin-bottom: $space-40;
}

.officeCard {
  margin-bottom: $space-24;
}

.officeCardContainer {
  display: flex;
  container-type: inline-size;
  gap: $space-12;
}

.officeCardDetails {
  display: flex;
  flex-grow: 1;
  gap: $space-12;

  @container (max-width: 400px) {
    flex-direction: column;
    gap: 0;
  }
}

.officeCardAddress {
  flex-basis: 50%;
}

.officeCardContact {
  flex-basis: 50%;
  text-align: right;

  @container (max-width: 400px) {
    text-align: left;
  }
}

.officeCardHeader {
  font-weight: 700;
}

.officeCardServiceArea {
  > p {
    margin-bottom: $space-2;
    font: $font-p-med;
  }
}
