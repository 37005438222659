.stage-wizard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wizard-progress-bar {
  display: flex;
  text-align: center;

  .step {
    width: 5.5rem;
    margin-right: $spacer-2;

    &.completed {
      a {
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
          .step-number {
            background-color: $practical-700;
          }
        }
      }
    }

    &.finish {
      margin-right: 0;
    }
  }
}

.step-number {
  width: 2em;
  height: 2em;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $iron-100;
  color: $iron-600;
  font: $font-heading-3;

  .active & {
    background-color: $primary-magic-green;
    color: $primary-practical-blue;
  }

  .completed & {
    background-color: $primary-practical-blue;
    color: $white;
  }
}

.step-name {
  color: $iron-600;
  font: $font-tiny-med;

  .active &,
  .completed & {
    color: $primary-practical-blue;
  }
}

.form-horizontal {
  .control-label {
    text-align: left;
  }
}

.correspondence-message {
  display: block;

  .row-message {
    margin-bottom: 0;

    .form-group {
      margin-bottom: 0;
    }
  }

  textarea {
    resize: none;
  }
}

.no-correspondence-necessary {
  display: none;
}

form.tender-trades-form {
  button.save-trades-button {
    margin-top: -50px;
  }
}

// Documents
.uploading-file-ctn {
  margin-bottom: 20px;
}

.doc-options {
  a.btn {
    margin-bottom: 10px;
  }
}

a.passive {
  opacity: 0.7;
}

.stage-monitor-filter.active,
.stage-monitor-filter.active:focus,
.stage-monitor-filter.active:hover {
  border: 1px solid $shakespeare;
  background-color: $boston-blue;
  color: $white;
}

.btn.stage-monitor-filter,
.btn.stage-monitor-filter:hover,
.btn.stage-monitor-filter:active,
.btn.stage-monitor-filter:focus {
  outline: none;
  outline: 0;
}

.btn.stage-monitor-filter span.count {
  margin-left: 2px;
  padding: 2px 3px;
  color: $iron-500;
  font-size: 11px;
}

.btn.stage-monitor-filter.active span.count {
  color: $white;
}

table.invite-colleagues-table {
  tbody {
    tr {
      td {
        padding: 1px 16px 1px 10px;
      }
    }
  }
}

table.package-notify-table {
  thead {
    tr {
      th {
        white-space: nowrap;
      }

      th.text-right {
        text-align: right;
      }
    }
  }
}

#packages-not-downloaded {
  padding: 15px 25px;

  ul {
    li {
      margin: 9px 0;
    }
  }
}
