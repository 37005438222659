@import '~@estimateone/frontend-components/src/css/variables';

.modalSection {
  padding: 0 $space-16;
  display: grid;
  row-gap: $space-24;
}

.textOr {
  padding-top: $space-16;
  text-align: center;
}

.modalHeader {
  font: $font-heading-3;
}

.connectedProfileButtons {
  display: flex;
  column-gap: $space-16;
}

.learnMoreLink {
  font: $font-p-reg;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $space-12;
}

.matchingCompaniesContainer {
  display: grid;
  row-gap: $space-16;
}

.buttonContainer {
  display: grid;
  row-gap: $space-16;
}
