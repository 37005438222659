@import '~@estimateone/frontend-components/src/css/variables';

.modalHeading {
  margin: 0 0 $space-32;
  font: $font-heading-3;
}

.modalButtonDiv {
  margin-top: $space-32;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.modalButton {
  width: 50%;
}

.modalInfo {
  margin-left: $space-24;
}
