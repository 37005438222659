@import '~@estimateone/frontend-components/src/css/variables';

.headerContainer {
  padding: $space-24 0;
}

.breadcrumbContainer {
  width: fit-content;
  height: $space-24;
  margin: 0 0 $space-8;
  display: flex;
  align-items: center;
  color: $primary-practical-blue;
  gap: $space-4;
}

.breadcrumbLink {
  padding: 0;
  cursor: pointer;
  border: none;
  background: linear-gradient(to top, $primary-magic-green 1px, transparent 1px);
  color: $primary-practical-blue;
  font: $font-ps-med;
  line-height: 1;
  text-decoration: none;

  &:active {
    background: linear-gradient(to top, $primary-practical-blue 1px, transparent 1px);
  }

  &:focus {
    outline: thick auto $primary-link-blue;
    outline-offset: $space-4;
  }

  &:hover {
    outline: none;
    background: linear-gradient(to top, $magic-500 30%, transparent 30%);
    color: $primary-practical-blue;
  }
}

.packageDetails {
  display: flex;
  align-items: center;
}

.packageName {
  margin: 0;
  color: $primary-practical-blue;
  font: $font-heading-2;
}

.packageStatus {
  padding-right: $space-48;
  padding-left: $space-16;
}

.backIcon {
  margin: 4px;
  padding: 1px;
}

.fileIcon {
  margin-right: 8px;
}

.buttonWrapper {
  margin-left: auto;
  display: flex;
}

.scopesCreateButton {
  margin-right: $space-16;
}

.flaggedContainer {
  margin-right: $space-8;
  margin-left: -$space-24;
  display: block;
}

.unflaggedContainer {
  display: none;
}

div[id='package-dashboard-header'] {
  margin-bottom: $space-32;
}

div[id='package-dashboard-header']:hover .unflaggedContainer {
  margin-right: $space-8;
  margin-left: -$space-24;
  display: block;
}
