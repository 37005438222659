@import '~@estimateone/frontend-components/src/css/variables';

.billingOptions {
  padding: $spacer-4 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.saving {
  font: $font-p-heavy;
}

.billingCycleOption {
  display: contents;
  flex-basis: 50%;

  + .billingCycleOption {
    > div {
      margin-left: $spacer-2;
    }
  }
}

.billingCycleName {
  font: $font-p-heavy;

  &.noSaving {
    // billingCycleName font line-height + Card.Banner padding-top + bottom + border-width
    padding-top: calc((#{$font-size-md} * #{$font-lh-md}) + (#{$spacer-2} * 2) + 2px);
  }
}

.price {
  margin: 0;
}

// Need the `p` to override FEC styles (that was a mistake)
p.billingCycleFrequency,
p.exGST,
p.recurringBilling {
  margin: 0;
  font: $font-tiny-reg;

  &:last-child {
    margin-top: $spacer-3;
  }
}
