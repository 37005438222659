@import '~@estimateone/frontend-components/src/css/variables';

.footerSectionDefaultWrapper {
  margin: $space-24 30px 0 30px;
  padding: $space-12;
  border-radius: 4px;
  background-color: $steel;
  font: $font-ps-reg;
  text-align: left;
}

.footerSectionLiteToEssentialWrapper {
  margin: $space-24 30px 0 30px;
  padding: $space-12;
  border-radius: 4px;
  background-color: $electric-025;
  font: $font-ps-reg;
  text-align: left;
}

.highlightText {
  font: $font-ps-heavy;
}

.creditBalance {
  font: $font-p-reg;
}
