.help-site-tab,
.help-tab {
  padding: $spacer-3;
  border: 2px solid $primary-practical-blue;
  border-radius: 50px;
  box-shadow: $shadow-standard;
  background: $primary-practical-blue;
  color: $white;

  &:active,
  &:focus,
  &:hover {
    background: $primary-magic-green;
    color: $primary-practical-blue;
  }

  &:active,
  &:hover {
    cursor: pointer;
  }

  &:active {
    background: $magic-500;
  }

  &:focus {
    border-color: $primary-practical-blue;
  }

  @media print {
    display: none;
  }

  .icon {
    vertical-align: text-bottom;
  }
}

.helper-dismiss {
  position: relative;
  z-index: 2000;
  right: -15px;
  float: right;
  border: none;
  background: none;
  color: $white;

  &:hover {
    opacity: 1;
    color: $white;
    text-decoration: none;
  }
}

.helper-container {
  position: fixed;
  z-index: 600;
  top: 0;
  bottom: 0;
  right: -420px;
  width: 310px;
  height: 100%;
  padding: 0;
  display: table;
  box-shadow: inset 10px 0 5px 0 rgb(0 0 0 / 15%);
  background: $ebony-clay;
  color: $white;
  text-align: left;

  &.loading {
    .loading-indicator {
      display: block;
    }
  }

  &.searching {
    .helper-content {
      display: none;
    }

    .help-search-results-container {
      display: block;
    }

    .no-results {
      display: block;
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: $white;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.4em;
  }

  h3 {
    margin-bottom: 15px;
    color: $silver-chalice;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5em;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  p {
    margin: 7px 0 20px;
    color: $wild-sand;
    font-size: 13px;
    line-height: 1.63em;
    -webkit-font-smoothing: antialiased;
  }

  a {
    border-bottom: 1px dotted $mine-shaft;
    color: $silver-chalice;
    text-decoration: none;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -50px;
    color: $boulder;
    font-size: 50px;
    -webkit-font-smoothing: antialiased;
  }

  .no-results {
    display: none;
  }

  .help-search-results-container {
    display: none;
  }

  .loading-indicator {
    width: 26px;
    height: 26px;
    margin: 30px;
    display: none;
  }

  .response-explain {
    float: right;
    font-size: 11px;
    line-height: 30px;

    a {
      border: none;
    }
  }

  ul.nav-tabs {
    margin: 0 (-$container-y-padding);
    margin-bottom: 25px;
    padding: 0 30px;
    background: $gallery;
  }
}

.helper-container .helper-controls {
  padding: 20px 30px 0;
  color: $dusty-gray;

  h3 {
    margin-bottom: 0;
    color: $silver;
    font-family: 'Trebuchet MS', 'Arial', 'Helvetica', sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    text-shadow: 0 -1px 0 $ebony-clay;
    text-transform: none;
  }

  .help-search-container {
    height: 40px;
    margin: 10px -30px 0;
    padding: 5px 5px 5px $spacer-3;
    background: rgb(0 0 0 / 30%);
  }

  .help-search {
    width: 230px;
    height: 30px;
    margin-top: 0;
    border: none;
    background: none;
    color: $white;
    font: $font-tiny-reg;
  }
}

.helper-content,
.help-search-results-container {
  height: 100%;
  max-height: 100%;
  padding: 30px 30px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background: $ebony-clay;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: $river-bed;
  }

  ul {
    padding-left: 20px;

    li a {
      border-bottom: 1px dotted $viridian;
      color: $viking;
      font-size: 14px;
    }

    li {
      margin-bottom: 10px;
    }
  }
}

.helper-top,
.helper-middle,
.helper-bottom {
  width: 100%;
  display: table-row;
}

.helper-top {
  .btn-help {
    width: 250px;
    margin-top: 15px;
    padding: 10px;
    display: block;
    border: none;
    background: rgba($white, 0.1);
    color: $white;

    &:hover {
      background: rgba($white, 0.2);
      color: $white;
    }
  }
}

.helper-middle {
  height: 100%;
  overflow: auto;
}

.helper-bottom {
  height: 140px;
}

.helper-footer {
  width: 100%;
  height: 100%;
  padding: 0 30px;

  .btn-help {
    width: 250px;
    margin-top: 15px;
    padding: 10px;
    display: block;
    border: none;
    background: rgba($white, 0.1);
    color: $white;

    &:hover {
      background: rgba($white, 0.2);
      color: $white;
    }
  }
}

body.helper-active {
  .helper-container {
    right: 0;
  }

  .container-fluid {
    margin-right: 310px;
  }

  .footer {
    margin-right: 310px;
  }

  .help-tab {
    visibility: hidden;
  }

  .super-slider-outer-container {
    margin-right: 310px;
  }

  .helper-aware {
    margin-right: 310px;
  }

  #render-drawer-ui-container.address-book-drawer > div {
    div[class^='Drawer-module__overlay'] {
      width: calc(100vw - 310px);
    }

    > div {
      margin-right: 310px;
    }
  }
}

table.rfq-response-explain {
  td {
    padding: 5px 15px 5px 0;
    vertical-align: top;

    a {
      border: none;
    }
  }

  td:first-child {
    width: 25%;
    padding-top: 8px;
  }
}
