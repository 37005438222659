.icon-left {
  margin-right: $spacer-2;
}

.icon-right {
  margin-left: $spacer-2;
}

.glyphicon-spin {
  animation: spin 1000ms infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

a.docs-icon,
span.docs-icon {
  float: left;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: block;
  background: url('#{$image-path}pdf-icon.png') no-repeat transparent;

  &:hover {
    text-decoration: none;
  }
}

div.note-icon-container,
div.keyword-matches-icon-container {
  cursor: pointer;
  display: block;
  text-align: center;
}

.icon-access-control {
  img {
    height: 80px;
  }
}
