body.signup {
  min-height: 100%;

  .header {
    width: 100%;
    margin-bottom: $spacer-6;
    padding: $spacer-5 0;
    background: url('#{$image-path}signup-header-bg.jpg') $primary-practical-blue;
    background-size: cover;
    text-align: center;

    .e1-logo {
      margin: $spacer-2 0;
      opacity: 0.5;
    }

    h1,
    h2,
    h4 {
      color: $white;
      line-height: 1.5em;
    }

    h4 {
      font-weight: $font-regular;
    }
  }

  .benefits-panel {
    padding: $spacer-2;
  }

  .benefit-outline {
    li {
      &:first-child {
        > h4 {
          margin-top: 0;
        }
      }

      h4 {
        margin: $spacer-3 0 $spacer-2;
      }
    }
  }

  .trusted-by {
    margin: $spacer-5 0;

    h5 {
      text-transform: uppercase;
    }

    .logos {
      width: 623px;
      height: 82px;
      margin: $spacer-3 auto $spacer-2 auto;
      background: url('#{$image-path}signup-logos.png') no-repeat;
    }
  }

  .thank-you-outer {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba($primary-practical-blue, 0.9);
    text-align: center;
    inset: 0;

    &.open {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .thank-you {
    z-index: 1001;
    text-align: center;

    h1,
    h4 {
      color: $practical-050;
    }

    h4 {
      font-weight: $font-regular;
    }
  }

  div.marketing-consent-check-boxes {
    > div {
      margin-bottom: 1em;

      .checkbox {
        padding-left: 1.5em;
        display: inline;
      }

      label {
        display: inline;
        text-transform: none !important;
      }
    }
  }
}

.join-account-suggestions-list {
  text-align: left;

  li {
    margin-bottom: $spacer-3;
    padding-bottom: $spacer-2;
    list-style: none;
    border-bottom: 1px solid $iron-050;

    &:last-child {
      border-bottom: none;
    }

    input,
    label {
      cursor: pointer;
    }

    span {
      margin-left: $spacer-2;
      color: $primary-practical-blue;
    }

    p {
      margin-left: $spacer-4;
    }
  }
}
