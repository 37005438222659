@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeading {
  margin: 0;
  font: $font-heading-2;
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
}

.sliderButton {
  width: 360px;
}

.masterCheckbox {
  position: static;
  margin-left: -$space-8;

  &:hover {
    &:not(.hasError) {
      content: '';
      position: static;

      &::before {
        background: unset;
      }
    }
  }
}

.addMemberTableContainer {
  th {
    z-index: 2;
    top: -32px;
  }
}
