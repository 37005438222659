@import '~@estimateone/frontend-components/src/css/variables';

.notesWidgetContainer {
  button {
    padding: 0;
    cursor: pointer;
    border: none;
    outline: inherit;
    background: none;
    color: inherit;
    font: inherit;
  }
}

.notesWidget {
  display: inline-flex;
  gap: $space-8;
}

.notesCounter {
  border-color: $steel;
}

.latestNote {
  margin-left: 8px;
  display: -webkit-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
