@import '~@estimateone/frontend-components/src/css/variables';

.headerContainer {
  padding-top: $spacer-4;
  background-color: $steel;
}

.contactInfoContainer {
  display: flex;
  flex-direction: column;
}
