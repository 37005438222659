@import '~@estimateone/frontend-components/src/css/variables';

.sliderSubHeader {
  margin-top: $spacer-2;
  font-size: $font-size-lg;
  font-weight: $font-medium;
}

.sliderFooter {
  display: flex;
  justify-content: flex-end;
}
