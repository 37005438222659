@import '~@estimateone/frontend-components/src/css/variables';

.icon {
  margin-right: $space-8;
  stroke: none;
  fill: $iron-300;
  color: $iron-300;
}

.modalHeading {
  margin-top: 0 !important;
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.modalActions {
  margin-top: $space-40;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.modalRadioContainer {
  margin-top: $space-8;
}

.modalForm {
  width: 100%;
}
