@import '../../css/_shared/variables';

.auto_address_form {
  &.no-country {
    .address-fields {
      display: none;
    }
  }
}

.autocomplete_address_form {
  position: relative;

  .location_autocomplete {
    background: url('#{$image-path}powered_by_google_on_white.png') no-repeat right 1rem center;
    background-size: 96px 12px;
  }

  .manualaddress-trigger {
    position: absolute;
    bottom: -1.5rem;
    right: 0;
  }
}

.input-daterange {
  input {
    text-align: left;
  }

  .input-group-addon {
    &.to {
      border: none;
      background: none;
    }
  }

  .form-control-feedback {
    height: $form-default-item-height;
    line-height: $form-default-item-height;
  }
}

.collection-item {
  padding: 0 15px 15px;

  select.form-control {
    width: 95%;
    display: inline-block;
  }

  .collection-del-item {
    float: right;
    width: 5%;
  }
}

[data-notify='progressbar'] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  margin-bottom: 0;
}

.pac-container {
  z-index: 1500;
}

span.inline-label-offset {
  margin-top: 5px;
}

.edit-package-form {
  &__datepicker--quotes-due-at {
    width: 50%;

    &__help-text {
      margin: -7px 0 0; // Reduce the gap from .form-group from 15px to 8px
      font: $font-ps-reg;
    }
  }
}
