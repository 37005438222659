@import '~@estimateone/frontend-components/src/css/variables';

.noPadding {
  padding: 0;
}

.drawerTabContainer {
  margin-top: -1.5rem;
  padding: 0 $space-24;
}

.drawerBodyContainer {
  flex: 1 1 auto;
  align-content: flex-start;
}

/* stylelint-disable selector-pseudo-class-disallowed-list */
:global {
  // Overrides to CSS that are only applied when the address book tabs are embedded in the Universal Profile Drawer
  // When we've replaced the tabs with React implementations we should get rid of these global styles
  #render-drawer-ui-container .super-slider-body {
    margin: -#{$space-24} -#{$space-24} 0 -#{$space-24};
    padding: 0;
    overflow: unset;
  }

  #render-drawer-ui-container .tab-content > .tab-pane {
    padding: $space-24 $space-24 0 $space-24;
  }

  #render-drawer-ui-container .tab-content > .tab-pane.vcard-container {
    padding: 0;
  }

  #ab-invites,
  #ab-contracts {
    // Gets rid of the extra space at the top of the projects/contracts tabs
    margin-top: -$space-32;
  }

  #render-drawer-ui-container.address-book-drawer e1-network-profile-mf,
  #render-drawer-ui-container.address-book-drawer e1-network-profile-experience-mf {
    display: block;
  }
}
