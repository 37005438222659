@import '~@estimateone/frontend-components/src/css/variables';

.chasTag {
  background-color: $iron-025;

  img {
    width: 16px;
    height: 16px;
    margin-right: $space-4;
  }
}
