@import '../../../css/_shared/variables';

.buildersQuoteList {
  font-size: $font-size-xs;

  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
  }
}

.builderName {
  font-weight: $font-medium;
}

.builderNameNoWrap {
  white-space: nowrap;
}

.quoteDate {
  margin-left: $spacer-3;
  white-space: nowrap;
}
