@import '~@estimateone/frontend-components/src/css/variables';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-48 $space-16;

  .content {
    grid-column: 1 / -1;

    h3 {
      margin: 0 0 $space-24;
      font: $font-heading-3;
    }

    p {
      margin: $space-8 0 0;
    }

    p:last-of-type {
      color: $iron-600;
    }
  }
}

.graphic {
  width: min(100%, 416px);
  margin: 0 auto;
}
