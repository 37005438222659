@import '~@estimateone/frontend-components/src/css/variables';

.accountSettingsScheduleContainer {
  margin: $space-32 0;
}

.accountSettingsScheduleHeading {
  margin: 0 0 $space-16;
  font: $font-heading-3;
}

.accountSettingsScheduleDataContainer {
  display: flex;
  align-items: flex-start;
  gap: $space-64;
}
