@import '~@estimateone/frontend-components/src/css/variables';

div.loading {
  width: 100%;
  min-height: $space-48;
}

.subNavActions {
  display: flex;
  column-gap: $space-12;
}

.tenderWizardSubnavControls {
  width: 100%;
  display: flex;
  justify-content: right;
  gap:8px;
}

.separator {
  width: 2px;
  background-color: $iron-100;
}
