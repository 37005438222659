@import '~@estimateone/frontend-components/src/css/variables';

.modalHeader {
  margin-top: 0;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.modalContent {
  margin-bottom: $spacer-5;
  font: $font-ps-reg;
}
