@import '~@estimateone/frontend-components/src/css/variables';

.statusIcon {
  display: flex;
  align-items: center;

  &:focus-within {
    border-radius: 4px;
    outline: $focus-outline;
  }
}
