@import '~@estimateone/frontend-components/src/css/variables';

.awardedIcon {
  margin-right: $space-8;
  color: $primary-magic-green;
}

.awardedToTitle {
  margin: 0 0 $space-8;
  display: flex;
  align-items: center;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.awardedToTitleText {
  font-weight: 600;
}

.uploadQuoteLink {
  font-weight: 400;

  // TODO: Check with justin whether he wants this smaller so it doesn't blend in with the title
  // font-size: 1rem;
}

.awardedToCompanyAddress {
  margin-bottom: $space-4;
  color: $primary-practical-blue;
  font: $font-ps-reg;
}

.awardedToContactDetails {
  margin: 0;
  color: $primary-practical-blue;
  font: $font-ps-reg;
}
