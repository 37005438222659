@import '~@estimateone/frontend-components/src/css/variables';

.assignToSelect {
  width: 225px;
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  :global(.assignToSelect__control) {
    margin: 2px;
    border: none;
    background-color: inherit;

    &:hover {
      margin: 1px;
      border: solid 1px $iron-500;
      border-radius: 2px;
      background-color: $white;

      :global(.assignToSelect__indicator) {
        padding: 3px;
        opacity: 1;
      }
    }

    &:focus-within {
      margin: 0;
      border: solid 2px $magic-600;
      border-radius: 4px;
      background-color: $white;
    }
  }

  :global(.assignToSelect__value-container) {
    padding-right: 0;
  }

  :global(.assignToSelect__indicator) {
    padding: 3px;
    opacity: 0;
  }

  :global(.assignToSelect__placeholder) {
    color: $iron-400;
  }
}
