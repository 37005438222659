@import '~@estimateone/frontend-components/src/css/variables';

.tenderResponsesTable {
  display: flex;
  flex-direction: column;
}

.filter {
  margin-bottom: $space-16;
  justify-content: flex-end;
}

.filterValue {
  font-weight: $font-semibold;
}

.email {
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  :global(.__react_component_tooltip) {
    max-width: unset;
  }
}

.companyName {
  margin-right: 0.5rem;
  font: $font-ps-reg;
}

.phone {
  white-space: nowrap;
}

.quoteStatusTime {
  margin: $space-8 0 0 0;
  font-size: $font-size-xs;
}

.quoteAmount {
  text-align: right;
}

.quoteAmountHeader {
  flex-grow: 1;
  text-align: right;
}
