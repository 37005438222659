@import '~@estimateone/frontend-components/src/css/variables';

.layout {
  display: flex;
  justify-content: space-between;
  gap: $space-8;
}

.counter {
  width: $space-24;
  height: $space-24;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  font: $font-tiny-heavy;
}
