@import '~@estimateone/frontend-components/src/css/variables';

.companyInfoContainer {
  margin-left: $spacer-4;
  display: flex;
  flex-direction: column;
}

.officeLocation {
  margin-top: $spacer-2;
}

.emailText {
  font: $font-ps-heavy;
}

.phoneText {
  font: $font-ps-reg;
}

.summaryRow {
  margin-top: $space-4;
  font: $font-tiny-reg;

  span {
    display: inline-block;
    vertical-align: top;
  }
}
