@import '~@estimateone/frontend-components/src/css/variables';

.drawerTopBarActionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $font-ps-reg;
  gap: $space-8;
}

.moreActions:focus {
  background-color: rgba($practical-900, 0.2);
}
