@import '~@estimateone/frontend-components/src/css/variables';

.complianceSection {
  margin-bottom: $space-24;
  display: flex;
  flex-direction: column;
  gap: $space-16;
}

.complianceHeadingWrapper {
  display: flex;
  align-items: center;
}

.complianceHeader {
  font: $font-p-heavy;
}

.complianceText {
  font: $font-ps-reg;
}

.complianceLinkButton {
  width: fit-content;
}

.description {
  color: $iron-600;
  font: $font-ps-reg;
}
