@import '~@estimateone/frontend-components/src/css/variables';

.packageActionsBarContainer {
  padding: $space-12;
  border-bottom: 1px solid $iron-100;
  border-radius: $space-8 $space-8 0 0;
  background-color: $iron-050;
}

.quoteDueLabel {
  margin-bottom: 0;
  font: $font-p-heavy;
}

.packageTitle {
  font: $font-heading-3;
}

.packageQoutesDueDatePicker {
  input {
    width: 134px;
    text-align: center;
  }
}
