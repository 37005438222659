@import '~@estimateone/frontend-components/src/css/variables';

.secretStatus {
  @extend %font-mini-caps;
}

.bold {
  margin-right: $space-8;
  font: $font-p-heavy;
}

.gutter {
  margin-top: $space-8;
  margin-bottom: $space-16;
}

.bottomGutter {
  margin-bottom: $space-64;
}
