@import '~@estimateone/frontend-components/src/css/variables';

@mixin griddy($numOfColumns: 1) {
  display: grid;
  grid-template-columns: repeat($numOfColumns, 1fr);

  /**
   * Generates helper classes (that appear in the .d.ts file) for setting the span of a component.
   * - styles.colSpan1
   * - styles.colSpan2
   */
  @for $i from 1 through $numOfColumns {
    .colSpan#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
  }

  > * {
    grid-column: 1 / -1;
  }
}

.filterHeading {
  margin: 0;
  padding-top: $space-16;
  font: $font-heading-2;
}

.footerButtonContainer {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
}

.filterToolbar {
  flex: 0 0 auto;
}

.filterBody {
  padding-bottom: $space-64;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
}

.form {
  @include griddy(2);
  gap: $space-24 $space-16;
}

.searchArea {
  @include griddy(1);
  gap: $space-16 0;

  .radioButton {
    &.checked {
      margin-bottom: 0;
    }

    label {
      font: $font-p-reg;
    }
  }

  .labelContent {
    @include griddy(2);
    margin: 0;
    margin-left: $space-32;
    gap: 0 $space-24;

    p {
      margin: 0;
      color: $iron-600;
      font: $font-ps-reg;
    }

    .filters {
      width: 100%;
      max-height: 80px;
      margin-top: $space-8;
      opacity: 1;
      transition: all 200ms;

      &.hidden {
        max-height: 0;
        margin-top: 0;
        opacity: 0;
      }
    }
  }
}

.checked {
  display: block;
}
