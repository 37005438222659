@import '../../../../../../../css/_shared/variables';

.additionalFeaturesContainer {
  margin-top: $spacer-4;
  padding: 0 30px;
  text-align: left;

  p {
    font: $font-p-heavy;
  }

  .additionalFeaturesItemsContainer {
    width: 100%;
    margin-top: $spacer-3;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .featureItem {
      margin: 0 $spacer-2 $spacer-2 0;
      display: flex;
      flex: 40%;
      align-items: center;
      font: $font-ps-reg;

      .checkIconContainer {
        width: 32px;
        height: 32px;
        margin-right: $spacer-3;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $electric-025;
      }
    }
  }
}

.featureTick {
  stroke: $electric-500;
  fill: $electric-500;
}
