@import '~@estimateone/frontend-components/src/css/variables';

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > p {
    margin-bottom: 0;
  }

  > a {
    margin-left: $space-4;
  }
}

.alertTitle {
  font-weight: 600;
}

.iconContainer {
  margin-right: $space-12;

  > svg {
    position: relative;
    top: $space-4;
    width: 20px;
    height: 20px;
  }
}
