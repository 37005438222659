@import '~@estimateone/frontend-components/src/css/variables';

.quoteCoverage {
  margin-top: $spacer-4;
}

.quoteCoverageAccordionTitle {
  display: contents;

  span + span {
    margin-left: $spacer-2;
  }
}

.summary {
  margin-bottom: $spacer-3;
  font: $font-tiny-reg;
}
