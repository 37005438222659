/** Mixins **/
@mixin badge-round($font-size, $border-width: 0, $base-color: $primary-practical-blue) {
  min-width: 2.5em;
  height: 2.5em;
  padding: 0 0.2em;
  display: inline-block;
  border: $border-width solid $base-color;
  border-radius: 2.5em;
  background-color: $base-color;
  color: $white;
  font-size: $font-size;
  font-weight: $font-regular;
  text-align: center;

  @if $border-width == 0 {
    line-height: 2.75em;
    /* stylelint-disable-next-line at-rule-empty-line-before */
  } @else {
    line-height: calc(2.5em - #{$border-width} - 2px);
  }
}

%beta-badge {
  margin-left: 4px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: $practical-500;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: normal;
}

/** Styles **/
.badge {
  padding: $spacer-1 $spacer-2;
  background-color: rgba($primary-practical-blue, 0.75);
  color: $white;
  font-size: 75%;
  font-weight: $font-regular;

  .header &,
  .super-slider-header & {
    background: rgba($white, 0.25);
  }

  .sub-bar & {
    background: rgba($primary-practical-blue, 0.1);
    color: inherit;
  }

  .btn-primary & {
    color: $primary-practical-blue;
  }
}

.badge-round {
  @include badge-round(1rem, 2px);

  &-xs {
    @include badge-round(0.75rem, 0, rgba($primary-practical-blue, 0.75));
    min-width: 2em;
    height: 2em;
    font-weight: $font-semibold;
    line-height: 2em;
  }

  &-sm {
    @include badge-round(0.85rem, 2px);
  }

  &-lg {
    @include badge-round(1.2rem, 3px);
  }

  .icon {
    vertical-align: text-bottom;
  }
}

.badge-initials {
  border-color: $primary-practical-blue;
  background-color: $primary-practical-blue;
  color: $white;
  text-transform: uppercase;

  &.current-user {
    background-color: $white;
    color: $primary-practical-blue;
  }
}

.corner-badge {
  position: relative;
  top: -0.5rem;
  left: -0.6rem;
  min-width: 1rem;
  height: 1rem;
  padding: 0 0.4em;
  display: inline-block;
  vertical-align: top;
  border: 2px solid $white;
  border-radius: 1rem;
  background: $primary-magic-green;
  color: $white;
  font-size: 0.4em;
  line-height: 1.9em;
  text-align: center;
  white-space: nowrap;
}

.invitationsCounter {
  &:not([data-unactioned-invitations-count=''], [data-unactioned-invitations-count='0']) {
    &:after {
      content: '\2022';
      position: relative;
      top: -34px;
      left: 10px;
      display: block;
      color: $primary-magic-green;
      font-size: 30px;
    }
  }
}

.teamCounter {
  display: none;

  &:not([data-unactioned-team-count=''], [data-unactioned-team-count='0']) {
    display: inline-block;

    &:after {
      content: '\2022';
      position: relative;
      top: -34px;
      left: 10px;
      display: block;
      color: $primary-magic-green;
      font-size: 30px;
    }
  }
}

i.audit-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: $iron-050;
  color: $iron-600;
  font-size: 90%;
  line-height: 2rem;
  text-align: center;

  /* Colours */
  &.icon-add,
  &.icon-builder-added {
    background-color: $magic-050;
    color: $primary-magic-green;
  }

  &.icon-check-mini {
    background-color: $primary-magic-green;
    color: $white;
  }

  &.icon-close {
    background-color: $crimson-050;
    color: $color-error;
  }

  &.icon-merge,
  &.icon-update,
  &.icon-link-mini {
    background-color: $practical-050;
    color: $primary-link-blue;
  }

  &.icon-undo {
    background-color: $primary-link-blue;
    color: $white;
  }

  /* Sizes */
  &.icon-builder-added {
    font-size: 105%;
  }

  &.icon-close {
    font-size: 75%;
  }

  &.icon-merge,
  &.icon-update {
    font-size: 125%;
  }
}

.unread-indicator {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 1em;
  background-color: $primary-magic-green;

  &[data-read='1'] {
    visibility: hidden;
  }
}
