@import '~@estimateone/frontend-components/src/css/variables';

.contractFieldsWrapper {
  margin-bottom: $space-32;
}

.adjacentFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $space-24;
}
