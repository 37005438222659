@import '~@estimateone/frontend-components/src/css/variables';

.profileBadgeCardWrapper {
  display: flex;
  column-gap: $space-12;
}

.heading {
  color: $primary-practical-blue;
  font: $font-ps-heavy;
}

.subHeading {
  color: $primary-practical-blue;
  font: $font-tiny-reg;
}

.profileContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
