@import '~@estimateone/frontend-components/src/css/variables';

.addendumDescriptionColumn {
  max-width: 200px;
}

.addendumActionsButtonsContainer {
  display: flex;
  gap: $space-8;
}

.addendumTableContainer {
  padding-bottom: $space-16;
}

.deleteAddendumModalHeading {
  margin-bottom: $space-32 !important;
  font: $font-heading-3;
}

.deleteAddendumModalActionsContainer {
  margin-top: $space-48;
  display: flex;
  gap: $space-16;
}
