.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

/*** Excerpts from Bootstrap 4.0.0 bootstrap-grid.css ***/

/* Display */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

/* Flex direction */
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: horizontal !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
  -webkit-box-direction: reverse !important;
  -webkit-box-orient: horizontal !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
  -webkit-box-direction: reverse !important;
  -webkit-box-orient: vertical !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

/* Justify content */
.justify-content-start {
  justify-content: flex-start !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
}

.justify-content-center {
  justify-content: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
}

.justify-content-around {
  justify-content: space-around !important;
  -ms-flex-pack: distribute !important;
}

/* Align items */
.align-items-start {
  align-items: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
}

.align-items-end {
  align-items: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
}

.align-items-center {
  align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
  -ms-flex-line-pack: start !important;
}

.align-content-end {
  align-content: flex-end !important;
  -ms-flex-line-pack: end !important;
}

.align-content-center {
  align-content: center !important;
  -ms-flex-line-pack: center !important;
}

.align-content-between {
  align-content: space-between !important;
  -ms-flex-line-pack: justify !important;
}

.align-content-around {
  align-content: space-around !important;
  -ms-flex-line-pack: distribute !important;
}

.align-content-stretch {
  align-content: stretch !important;
  -ms-flex-line-pack: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
  -ms-flex-item-align: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
  -ms-flex-item-align: start !important;
}

.align-self-end {
  align-self: flex-end !important;
  -ms-flex-item-align: end !important;
}

.align-self-center {
  align-self: center !important;
  -ms-flex-item-align: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
  -ms-flex-item-align: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
  -ms-flex-item-align: stretch !important;
}
