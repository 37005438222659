@import '~@estimateone/frontend-components/src/css/variables';

.header {
  margin-top: 0;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.content {
  margin-bottom: $spacer-5;
  font: $font-ps-reg;
}
