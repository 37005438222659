.addressbook-trades {
  min-height: 800px;

  .loading-container {
    &.is-loading {
      .loading {
        display: block;
      }

      .loaded {
        display: none;
      }
    }

    &.has-loaded {
      .loading {
        display: none;
      }

      .loaded {
        display: block;
      }
    }
  }

  .discipline-well {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-semibold;

    &:first-of-type .move-up,
    &:last-of-type .move-down {
      color: $iron-300;
      pointer-events: none;
    }

    .edit-discipline-controls {
      a {
        margin-right: $spacer-5;
        color: $primary-practical-blue;
        font: $font-p-reg;
        text-decoration: none;

        &:disabled {
          color: $iron-300;
          pointer-events: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .delete-discipline {
        color: $crimson-600;
      }

      .icon {
        margin-left: $spacer-2;
      }
    }
  }
}

.note {
  margin: $spacer-2 0;
  padding: $spacer-3;
  display: block;
  border-radius: 8px;
  background-color: $practical-025;

  &.green {
    background-color: $magic-050;
  }

  .note-text {
    word-break: break-all;
    white-space: break-spaces;
  }
}

.btn.btn-default.addNote {
  /* Approximate the "grey" button varient from the design system */
  color: $primary-practical-blue;
}

.update-requests-container {
  &.container-empty {
    .empty {
      display: block;
    }

    .non-empty {
      display: none;
    }
  }

  .empty {
    display: none;
  }

  .empty p {
    margin-bottom: 25px;
    color: $emperor;
    font-size: 13px;
    font-style: italic;
  }
}

.addressbook-request {
  #requestDataTable {
    .checkbox {
      width: 43px;
      padding-left: 10px;
    }

    .trade-name {
      width: 200px;
    }
  }

  .request-action,
  .action {
    min-width: 150px;
  }

  .request-type-text {
    color: $tundora;
    font-style: italic;
    text-transform: lowercase;
  }
}

.addressbook-index {
  min-height: 800px;

  .search-info {
    color: $iron-600;
    font: $font-ps-reg;

    &:first-child {
      margin-left: 0;
    }

    .fd-filter-display-cover {
      margin-top: $spacer-3;
    }

    .fd-filter-display-title {
      margin-right: 10px;
      margin-bottom: 5px;
      display: block;
      color: $boulder;
    }

    .fd-filter-display {
      padding: 10px 0 0 10px;
      display: block;
      border-radius: 4px;
      background: $steel;

      li {
        display: inline-block;
      }
    }

    .fd-cover {
      margin-right: 10px;
      margin-bottom: 8px;
      padding: 5px 0 0 8px;
      display: inline-block;
      border: 1px solid $iron-100;
      border-radius: 3px;
      background: $white;

      ul {
        margin-left: 5px;
        display: inline;
      }

      li {
        margin-bottom: 5px;
      }
    }

    .fd-filter {
      margin: $spacer-1 0 $spacer-1 $spacer-2;
      padding: $spacer-1 $spacer-3;
      border: 2px solid $primary-magic-green;
      border-radius: 24px;
      color: $primary-practical-blue;

      &:last-child {
        margin-right: $spacer-2;
      }
    }

    .fd-cancel {
      float: right;
      margin: 0 0 0 $spacer-1;
      cursor: pointer;
      color: $primary-practical-blue;
      font-weight: bold;
    }

    .fd-remove {
      margin-right: 3px;
      cursor: pointer;
      opacity: 0.7;
      color: $white;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .searchbox {
    padding: $spacer-3;
    border-radius: 4px;
    background: $steel;
  }

  .searchResultTable {
    min-height: 500px;
  }

  .input-distance {
    width: 80px;
    display: inline-block;
  }

  .disabled {
    color: $jelly-bean;
  }

  .no-sort {
    padding-left: 10px !important;
    background: none !important;
    background-position: 0 !important;
  }

  input[type='checkbox'] {
    width: 12px;
    height: 12px;
  }

  .of {
    padding: 0 10px;
  }
}

.addressbook-add {
  min-height: 800px;

  #address_book_company_trades {
    height: 34px;
  }
}

.addressbook-list {
  min-height: 800px;

  .list-actions {
    width: 120px;
  }

  div.loading-container {
    .empty-text-placeholder {
      padding-top: 1em;
    }
  }
}

.addressbook-list-edit {
  min-height: 800px;

  .abc-company-name {
    min-height: 500px;
    padding: 10px;
    background: $seashell;
    color: $tundora;
    font-size: 16px;
    font-weight: bold !important;
  }

  #searchResultTable {
    min-height: 500px;
    padding: 0 10px 10px;
  }

  .abc-card {
    height: 50px;
  }

  .dataTables_info {
    padding-top: 0 !important;
    padding-bottom: 10px;
    display: block;
  }

  #results-dropdown {
    width: 65px;
    padding: 0;
    display: inline-block;
    direction: rtl;
  }

  .input-distance {
    width: 80px;
    display: inline-block;
  }

  #page-results {
    display: none;
  }

  .abc-company-location {
    color: $rolling-stone;
    font-size: 10px;
  }

  .abc-link {
    cursor: pointer;
    border-bottom: none;
    color: $shakespeare;
    text-decoration: none !important;
  }

  .abc-link:hover {
    border-bottom: none;
    text-decoration: none !important;
  }

  .saveList {
    margin-right: 20px;
    margin-left: 20px;
    display: block;
  }

  .disabled {
    color: $jelly-bean;
  }

  .no-sort {
    padding-left: 10px !important;
    background: none !important;
    background-position: 0 !important;
  }

  input[type='checkbox'] {
    width: 12px;
    height: 12px;
  }

  #searchDataTable .phone {
    width: 100px;
  }

  #searchDataTable .dropdown-tableheader {
    width: 100px;
  }
}

.contact-list-module {
  .lead {
    padding: 15px 0;
    font-size: 12px;
  }
}

.company-select2-form {
  padding-top: 10px;
}

a.approve {
  color: $goblin;
  text-decoration: underline;
}

a.decline {
  color: $stiletto;
  text-decoration: underline;
}

#tenderSpecificNotes label {
  font-size: 11px;
  font-weight: normal;
}

.company-form .modal-body {
  height: 350px !important;
}

#global_search {
  padding-bottom: 20px;
  border-bottom: 1px dotted $alto;

  input[type='text'] {
    width: 78%;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
  }

  button {
    margin-top: -14px;
    margin-left: -15px;
    padding: 10px 12px 9px;
    border-radius: 0 5px 5px 0;
    font-size: 16px;
    font-weight: normal;
  }
}

a.btn.invite-single-addressbook {
  font-weight: 700;
}

#addressResults {
  display: none;
}

.vcard-account-details {
  display: flex;
  font: $font-tiny-reg;

  label {
    font: $font-tiny-reg;
  }
}

.subbie-profile-company-logo-container {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background: white;
}

.subbie-profile-company-logo {
  max-width: 100px;
  max-height: 100px;
}

.vcard-container {
  .vcard-details {
    .vcard-company {
      display: block;

      label {
        color: $iron-600;
        font: $font-ps-reg;
      }
    }

    .vcard-company-form {
      display: none;

      .select2-container {
        width: 100% !important;
      }
    }
  }

  .vcard-company-contact {
    clear: both;
    padding-top: 15px;
    display: block;

    .saveUser {
      display: none;
    }

    .edit-contact {
      cursor: pointer;
    }
  }

  .vcard-e1-contacts,
  .vcard-e1-trades {
    display: none;
  }

  .note-add-container {
    .title-container {
      padding-bottom: 15px;

      .add-tender-specific-note {
        text-decoration: underline;
      }
    }
  }

  .add-from-e1-form {
    th.options {
      width: 40px;
    }
  }
}

.contact-type-indicator {
  width: 22px;
  height: 14px;
  margin: 0;
  padding: 0 !important;
  display: inline-block !important;
  background-image: url('#{$image-path}contact_types.png');

  &:hover {
    cursor: pointer;
  }

  &.estimating {
    background-position: 0 -1px;
  }

  &.construction {
    background-position: 0 -19px;

    &.estimating {
      background-position: 0 -37px;
    }
  }

  &.source-profile {
    &:before {
      content: 'E1';
      position: relative;
      top: -5px;
      left: -12px;
      padding: 2px;
      border-radius: 5px;
      background-color: $primary-practical-blue;
      color: $white;
      font-size: 0.5em;
    }
  }
}

div.super-slider-content {
  form.add-company-form {
    a.manualaddress-trigger {
      margin-bottom: 0;
    }

    div:last-child.top-buffer button {
      margin-top: 8px;
    }
  }
}

.favourite-toggle {
  cursor: pointer;
  color: $iron-100;

  &:hover {
    color: $energy-300;
  }

  &.active {
    transition: color;
    color: $energy-500;
  }
}

.builder-directory-filters {
  .btn {
    width: 100%;

    &[data-active='0'] {
      @extend .btn-secondary;
      border-color: $iron-100;
      color: $iron-500;
    }

    &[data-active='1'] {
      @extend .btn-primary;
    }
  }
}

#ab-history {
  .audit-history {
    list-style-type: none;

    li {
      display: flex;

      > * {
        padding-bottom: 1.5rem;
      }
    }

    .timeline {
      $icon-height: 2rem;
      position: relative;
      margin-right: $spacer-3;

      .timeline-line {
        position: absolute;
        top: calc(#{$icon-height} + #{$spacer-1});
        left: calc((#{$icon-height} / 2) - 1px);
        height: calc(100% - #{$icon-height} - (#{$spacer-1} * 2));
        border: 1px solid $iron-100;
      }
    }

    .history-details {
      display: flex;
      flex-direction: column;

      .timestamp,
      .description {
        padding: 0;
      }

      .description {
        font: $font-ps-reg;

        .user_name,
        .field_name,
        .target_name {
          font-weight: $font-semibold;
        }

        .field_val {
          padding: $spacer-1;
          border-radius: 4px;
          background-color: $iron-050;
          font: $font-tiny-med;
        }
      }
    }
  }
}

.addressbook-project-status {
  min-width: 120px;
}

.abc-company-address {
  padding-left: $space-12;
}

.abc-company-qualifications {
  align-self: anchor-center;

  div {
    padding-left: $space-12;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
