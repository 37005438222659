@import '~@estimateone/frontend-components/src/css/variables';

.notesHistoryItems {
  margin: 0;
  display: flex;
  flex-direction: column;
  transition: height 0.3s;
  transition-delay: 0ms;
  gap: $space-8;
}

.noNotesText {
  margin: 0;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
}
