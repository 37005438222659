@import '~@estimateone/frontend-components/src/css/variables';

.selectorContainer {
  width: 320px;
}

.menuCustom {
  width: 170% !important;
}

.contactListOption {
  width: 100%;
  cursor: pointer;
  display: flex;
  column-gap: $space-12;
  align-items: center;

  .optionLabel {
    margin: 0
  }

  .checkIcon {
    flex-shrink: 0;
  }

  .hideCheckIcon {
    opacity: 0;
  }
}

.divider {
  margin: 0;
}

.applyList {
  padding: $space-16;

  .applyListButton {
    width: 100%;
  }
}
