@import '../../../../../../css/_shared/variables';

.roadblockSlider {
  > hr {
    background-color: $iron-100;
  }

  .projectSliderHeader,
  .projectDetails {
    background-color: $iron-025;
    color: $iron-600;
  }

  .redactedProjectDetails {
    width: unset;
    height: 2000vh;
    margin: 2rem;
    background-image: url('../../../../../../images/roadblock-slider-redacted-text.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .roadblockContainer {
    position: fixed;
    z-index: 1050;
    display: flex;
    overflow-x: hidden;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    overflow-y: auto;
    outline: 0;
    inset: 0;
  }

  .roadblockModal {
    box-sizing: border-box;
    width: 600px;
    margin: auto;
    padding: 24px 20px;
    border: 1px solid $iron-050;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    background-color: $white;
    text-align: center;

    /* stylelint-disable selector-pseudo-class-disallowed-list */
    :global(.modal-content) {
      padding-top: 0;
    }

    .titleReasonContainer {
      // margin-top: 24px;
      display: flex;
      justify-content: center;

      .titleContent {
        width: fit-content;
        padding: 2px 4px 2px 2px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-radius: 4px;
        background-color: $iron-050;
        color: $iron-600;
        font: $font-tiny-med;
      }
    }

    .roadblockCopyContainer {
      padding: 0 24px;
    }

    h1 {
      margin: 0 20px $spacer-3;
    }

    .buttonContainer {
      padding: 20px 30px;
    }
  }
}

.roadblockSliderOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.4;
  background: $steel;
}
