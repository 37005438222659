@import '~@estimateone/frontend-components/src/css/variables';

.name {
  margin: $space-4 0 0;
  font: $font-p-heavy;
}

.email,
.phone {
  margin: 0;
  font: $font-ps-reg;
  justify-self: start;
}

.primaryContactHeadingContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.primaryContactWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto min-content;
  grid-template-rows: min-content;
  word-break: break-all;
  gap: $space-4;

  .primaryContactHeadingContainer,
  .avatar,
  .name,
  .email,
  .phone {
    grid-column: 1 / span 2;
  }
}

.heading {
  margin: 0 0 $space-4;
  font: $font-tiny-heavy;
}
