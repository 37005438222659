@import '~@estimateone/frontend-components/src/css/variables';

.logoSizeRegular {
  min-width: $space-64;
  max-width: $space-64;
  min-height: $space-64;
  max-height: $space-64;
}

.logoSizeSmall {
  min-width: $space-48;
  max-width: $space-48;
  min-height: $space-48;
  max-height: $space-48;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid $iron-100;
  border-radius: $space-2;
}

.logoRegular {
  max-width: $space-64;
  max-height: $space-64;
}

.logoSmall {
  max-width: $space-48;
  max-height: $space-48;
}
