@import '~@estimateone/frontend-components/src/css/variables';

.sliderFieldContainer {
  display: flex;
  align-items: center;
}

.sliderTextInput {
  max-width: 100px;
  margin-right: $space-40;
  flex-basis: 100px;
  align-self: end;
}

.sliderRangeInput {
  margin-bottom: 12px;
  flex-basis: 50%;

  > div {
    position: relative;
    padding: 0 13px 25px 14px;
  }

  span {
    position: relative;
    left: var(--range-progress);
    padding: $space-8;
    display: inline-block;
    transform: translateX(-50%);
    border-radius: 10px;
    background-color: #141b4d;
    color: #ffffff;
    font-size: small;
    font-weight: 600;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      right: 50%;
      width: 0;
      height: 0;
      transform: translateX(50%);
      border-top: 10px solid #141b4d;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }

  input {
    width: 100%;
    height: 5px;
    cursor: pointer;
    border-radius: 5px;
    background: linear-gradient(to right, #141b4d var(--range-progress), #e5e5e5 var(--range-progress));
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 100%;
      box-shadow: #666666 1px 3px 5px;
      background: #141b4d;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 100%;
      box-shadow: #666666 1px 3px 5px;
      background: #141b4d;
    }

    &:hover {
      &::-webkit-slider-thumb {
        outline: 10px solid rgb(0 0 0 / 10%);
      }

      &::-moz-range-thumb  {
        outline: 10px solid rgb(0 0 0 / 10%);
      }
    }
  }
}
