@import '~@estimateone/frontend-components/src/css/variables';

.helpBanner {
  ul {
    list-style-position: inside;
  }
}

.helpBannerFooter {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  gap: $space-16;
}

.helpBannerTitle {
  font: $font-heading-3;
  line-height: 125%;
}

.highlightedText {
  font: $font-p-heavy;
}
