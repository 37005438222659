@import '~@estimateone/frontend-components/src/css/variables';

.documentItem {
  margin-top: $space-12;
  margin-left: $space-32;
}

.documentLabel {
  display: flex;
  color: $primary-practical-blue;
  font: $font-p-reg;
  gap: $space-4;
}

.documentLabelLocked {
  color: $iron-600;
}

