@import '~@estimateone/frontend-components/src/css/variables';

.connectedBannerContainer {
  display: flex;
  align-items: center;
  gap: $space-12;
}

.infoIcon {
  width: 16px;
  height: 16px;
}

.connectedLabel {
  font: $font-p-reg;
}

.connectedLearnMoreLink {
  color: $primary-practical-blue;
  font: $font-ps-reg;
  text-decoration: underline;
}
