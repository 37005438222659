@import '~@estimateone/frontend-components/src/css/variables';

.resultListItem {
  padding: $space-12;
  cursor: pointer;
  border: 2px solid $iron-100;
  border-radius: $space-8;

  &:hover {
    background-color: $iron-025;
  }

  &:focus {
    outline: $focus-outline;
  }

  .companyName {
    margin: 0;
    font: $font-intro;
  }

  .location {
    font: $font-ps-reg;
  }
}

.statusIconColumn {
  padding-right: $space-4;
  padding-left: $space-4;
}
