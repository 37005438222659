@import '~@estimateone/frontend-components/src/css/variables';

.contactsSection {
  margin-bottom: $space-24;
  display: flex;
  flex-direction: column;
  gap: $space-16;
}

.contactsHeadingWrapper {
  display: flex;
  align-items: center;
}

.contactsHeader {
  font: $font-p-heavy;
}

.contactsText {
  font: $font-ps-reg;
}
