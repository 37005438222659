@import '~@estimateone/frontend-components/src/css/variables';

.helpBanner {
  max-width: 720px;
}

.helpBannerTitle {
  font: $font-p-heavy;
}

.helpBannerFooter {
  display: flex;
  gap: $space-8;
}

.learnMoreButton {
  padding-right: $space-8;
  padding-left: $space-8;
}
