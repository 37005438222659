@import '~@estimateone/frontend-components/src/css/variables';

.headingText {
  font: $font-p-heavy;
}

.card {
  height: 100%;
  padding: $space-16;
  display: flex;
  flex-direction: column;
  border: $space-2 solid $iron-100;
  border-radius: $space-8;
}

.cardText {
  overflow: hidden;
  font: $font-p-reg;
  text-overflow: ellipsis;
}

.cardEmailText {
  overflow: hidden;
  font: $font-p-heavy;
  text-overflow: ellipsis;
}

.cardTitleContainer {
  margin-bottom: $space-8;
  display: flex;
  font: $font-intro;
  gap: $space-12;

  svg {
    align-self: center;
  }
}

.cardClickableWrapper {
  all: unset;
  cursor: pointer;
  flex-grow: 1;
  border-radius: $space-8;

  &:hover {
    background-color: $iron-025;
  }
}
