@import '../../../../../../../node_modules/@estimateone/frontend-components/src/css/variables';

.accountDetailsForm {
  display: flex;
  flex-direction: column;

  .formElement {
    display: flex;
    flex-direction: column;
    font: $font-p-heavy;

    > input {
      margin-top: $spacer-2;

      &:last-of-type {
        margin-bottom: $spacer-3;
      }
    }
  }

  .selectContainer {
    display: flex;
    flex-direction: column;

    label {
      font: $font-ps-reg;
    }
  }
}

.paymentForm {
  display: flex;
  flex-direction: column;

  .paymentSelector {
    padding-bottom: $spacer-3;
  }

  .terms {
    margin-top: $spacer-3;

    > input {
      margin-right: $spacer-3;
    }

    > label {
      font-weight: $font-regular;
    }

    .termError {
      color: $color-error;
    }
  }
}

.eftposForm {
  text-align: left;

  > p {
    padding: $spacer-1 0;
  }
}
