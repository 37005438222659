@import '~@estimateone/frontend-components/src/css/variables';

.supplierItemContainer {
  padding: $space-16;
  display: flex;
  flex-direction: column;
  border: 1px solid $iron-200;
  border-radius: $space-8;

  .infoContainer {
    display: flex;
    flex-direction: column;

    .infoContainerHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .supplierName {
      margin: 0;
      margin-right: $space-4;
      overflow: hidden;
      font: $font-p-heavy;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .addressInfo {
      color: $iron-600;
      font: $font-ps-reg;
    }
  }

  .showContactButton {
    margin-top: 18px;
    align-self: flex-end;
  }
}
