@import '~@estimateone/frontend-components/src/css/variables';

.main {
  width: 100%;
  margin: 0 auto;
  padding: 0 $space-72;

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }
}

.contentContainer {
  display: grid;
  align-content: space-between;
  grid-auto-flow: row;

  h3 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  ul {
    list-style-position: inside;
  }
}

.buttonContainer {
  display: flex;
  flex-flow: row;
  gap: $space-8;
}

.learnMoreButton {
  padding-right: $space-8;
  padding-left: $space-8;
}
