@import '~@estimateone/frontend-components/src/css/variables';

.errorDrawerBodyWrapper {
  position: fixed;
  z-index: 998;
  background-color: #ffffff;
  inset: 0;
}

.errorAlert {
  margin: $space-24;
}

.errorAlertContent {
  display: flex;
  align-items: center;
  font: $font-p-reg;
}

.errorTitle {
  margin-right: $space-4;
  margin-left: $space-12;
  font: $font-p-heavy;
}

.errorIcon {
  width: 16px;
  height: 16px;
}
