@import '~@estimateone/frontend-components/src/css/variables';

.headerContainer {
  padding: $space-32;
  background-color: $practical-900;
  color: $white;
}

.oldHeaderContainer {
  padding-top: $spacer-4;
  background-color: $steel;
}

.profileContentStyle {
  padding: 0 $spacer-5 $spacer-5;
}
