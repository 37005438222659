@mixin card-color($color) {
  border-color: darken($color, 20%);
  background-color: mix($color, $white, 40%);

  .card-header {
    border-color: darken($color, 20%);
    background-color: $color;
  }
}

.card {
  &.card-body-white {
    .card-body {
      border-radius: 4px;
      background-color: white;
    }
  }

  &.card-secondary {
    background-color: $steel;
  }

  &.card-success {
    @include card-color($magic-050);
  }

  &.card-info {
    @include card-color($practical-050);
  }

  &.card-danger {
    @include card-color($crimson-050);
  }

  &.card-warning {
    @include card-color($energy-050);
  }

  &.card-merge {
    &,
    &-preview {
      label {
        margin-bottom: 0;
        display: block;
        color: $iron-600;
        font: $font-tiny-reg;
      }

      .card-header {
        padding: $spacer-2 $spacer-3;
      }

      .row {
        margin-bottom: $spacer-3;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .id {
        font: $font-tiny-reg;
      }
    }

    &-preview {
      &,
      .card-header {
        border-color: $primary-practical-blue;
      }

      .card-header {
        background: $primary-practical-blue;
      }
    }
  }

  &-sm {
    &,
    p {
      font: $font-ps-reg;
    }

    label {
      font-weight: $font-regular;
      white-space: nowrap;
    }

    .card-header,
    .card-body {
      padding: $spacer-3;
    }
  }
}

/** Excerpts from Bootstrap 4.0.0 _card.scss **/
$card-bg: $white !default;
$card-border-width: 2px;
$card-border-color: rgba($black, 0.125) !default;
$card-border-radius: 4px;
$card-spacer-x: $spacer-5;
$card-spacer-y: $spacer-5;
$card-cap-bg: rgba($black, 0.03) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;

// Base styles
.card {
  position: relative;
  min-width: 0;
  display: flex;
  flex-direction: column;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  background-color: $card-bg;
  word-wrap: break-word;
  background-clip: border-box;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
  }
}

.card-body {
  padding: $card-spacer-x;

  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;

  p {
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

// Optional textual caps
.card-header {
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  padding: $card-spacer-y $card-spacer-x;
  border-bottom: $card-border-width solid $card-border-color;
  background-color: $card-cap-bg;

  &:first-child {
    border-radius: $card-inner-border-radius $card-inner-border-radius 0 0;
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  border-top: $card-border-width solid $card-border-color;
  background-color: $card-cap-bg;

  &:last-child {
    border-radius: 0 0 $card-inner-border-radius $card-inner-border-radius;
  }
}

.card-deck {
  display: flex;
  flex-direction: column;
}
