@import '~@estimateone/frontend-components/src/css/variables';

.main {
  display: grid;
  grid-template-columns: 1fr;
  gap: $space-32;
}

@mixin gridSection {
  width: 100%;
  margin: 0 auto;
  padding: 0 $space-72;

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }
}

.filterWrapper {
  @include gridSection;
  display: flex;
  flex-flow: column nowrap;
  gap: $space-32;
}

.filterSection {
  display: grid;
  align-items: self-end;
  grid-template-columns: minmax(auto, 640px) 1fr;
  gap: $space-24 $space-16;

  & > button {
    height: $space-48;
  }
}

.filterButtons {
  display: flex;
  flex-flow: row nowrap;
  gap: $space-12;

  & > button {
    padding: $space-12 $space-16;
    line-height: 1.25;
  }
}

.filterIcon {
  width: 18px;
  height: 18px;
}

.chips {
  grid-column: span 2;
}

.resultsSection {
  @include gridSection;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $iron-100;
}

.buttonWithIcon {
  height: min-content;
  white-space: nowrap;
  gap: $space-8;
}

/* stylelint-disable selector-pseudo-class-disallowed-list */
:global {
  @supports selector(:has(*)) {
    #root:has(#subbie-network-v2-container),
    #subbie-network-v2-container ~ .footer {
      min-width: unset;
    }
  }
}
