.pagination {
  margin: $spacer-3 0;
  font-weight: $font-semibold;

  li {
    a {
      margin-left: $spacer-1;
      padding: 1em 1.4em;
      border: none;
      border-bottom: 4px solid transparent;
      color: $iron-600;

      &.active,
      &:focus,
      &:hover {
        border: none;
        border-radius: 2em;
        background-color: $magic-050;
        color: $primary-practical-blue;
      }
    }
  }

  .active {
    a {
      &,
      &.active,
      &:focus,
      &:hover {
        border-bottom: 4px solid $primary-magic-green;
        border-radius: 0;
        background-color: transparent;
        color: $primary-practical-blue;
      }
    }
  }

  .disabled {
    a {
      &,
      &.active,
      &:focus,
      &:hover {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
}

.activity-pagination,
.dataTables_wrapper .dataTables_paginate {
  text-align: center;
}

.dataTables_wrapper .dataTables_paginate {
  float: none;
}
