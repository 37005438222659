.tender-document-register {
  .addendum-block {
    width: 100%;
    display: block;
  }

  .addendum-heading {
    cursor: pointer;
    display: block;

    &.collapsed {
      border-bottom: 1px solid $heather;

      .addendum-indicator {
        &:before {
          content: '\e114';
        }
      }
    }

    h4 {
      float: left;
      margin: 12px 0;
      padding: 0;
      line-height: 24px;
      text-transform: none;
    }

    .addendum-indicator {
      margin-right: 2px;
      font-size: 12px;

      &:before {
        content: '\e113';
      }
    }

    h5 {
      float: right;
      margin: 12px 0;
      padding: 0;
      color: $astral;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0;
      text-transform: uppercase;

      span.date {
        text-transform: none;
      }
    }
  }

  .loading-container {
    margin-bottom: 12px;
  }
}
