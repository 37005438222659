@import '~@estimateone/frontend-components/src/css/variables';

.filterSection {
  padding: $space-12;
  display: grid;
  align-items: self-end;
  grid-template-columns: minmax(auto, 640px) 1fr;
  border-radius: 0 0 $space-4 $space-4;
  background-color: $iron-025;
  gap: $space-24 $space-16;

  & > button {
    height: $space-48;
  }
}

.filterButtons {
  display: flex;
  flex-flow: row nowrap;
  gap: $space-12;

  & > button {
    padding: $space-12 $space-16;
    line-height: 1.25;
  }
}

.filterIcon {
  width: 18px;
  height: 18px;
}

.chips {
  grid-column: span 2;
}

.buttonWithIcon {
  height: min-content;
  white-space: nowrap;
  gap: $space-8;
}
