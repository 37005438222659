/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

$bootstrap-sass-asset-helper: false;
$icon-font-path: '../../../assets/fonts/bootstrap/';

// Core variables and mixins
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Reset and dependencies
@import '~bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/print';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/glyphicons';

// Core CSS
@import '~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/code';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';

// Components
@import '~bootstrap-sass/assets/stylesheets/bootstrap/component-animations';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/navbar';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/pagination';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/pager';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/badges';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/jumbotron';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/thumbnails';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/progress-bars';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/media';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/list-group';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/wells';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/close';

// Components w/ JavaScript
@import '~bootstrap-sass/assets/stylesheets/bootstrap/modals';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tooltip';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/popovers';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/carousel';

// Utility classes
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

/*
* Bootstrap 3 does not have negative margin utils (4 has mt-n1).
* For now, we create our own util class.
* Todo: remove this when we upgrade to bootstrap 4.
*/

.mt-n3 {
  margin-top: -1rem;
}
