@import '~@estimateone/frontend-components/src/css/variables';

.actionContainer {
  display: flex;
  justify-content: space-between;
}

.allocateContent {
  height: 100%;
  overflow-y: auto;
}

.submitButtonContainer {
  display: flex;
  gap: $space-16;
}
