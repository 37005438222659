@import '~@estimateone/frontend-components/src/css/variables';

.modalHeading {
  margin: 0 0 32px;
  font: $font-heading-3;
}

.modalText {
  margin: 0 0 48px;
  font: $font-ps-reg;
}

.modalButtonDiv {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.modalButton {
  width: 50%;
}

.iconColumnHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.packageDeleteWithTooltip {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: $space-4;
}

.tooltip > div {
  z-index: 999999;
  margin-top: 20px !important;
}
