.btn {
  padding: $spacer-2 $spacer-3;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: $font-size-md;
  font-weight: $font-semibold;
  text-decoration: none;

  &:active {
    box-shadow: none;
  }

  &:focus,
  &:active:focus {
    outline: none;
  }

  // Display & sizes
  &.btn-inline-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-lg,
  &.btn-large {
    padding: $spacer-3;
  }

  &.btn-sm,
  &.btn-small,
  &.btn-xs {
    font-size: $font-size-xs;
  }

  &.btn-sm,
  &.btn-small {
    padding: $spacer-2 $spacer-3;
  }

  &.btn-xs {
    padding: $spacer-1 $spacer-3;
  }

  // Colours
  &.btn-default {
    &,
    &:active {
      border-color: $iron-100;
      background-color: $white;
      color: $iron-600;
    }

    &:focus,
    &:hover {
      background-color: $iron-025;
      color: $primary-practical-blue;
    }

    &:focus {
      border-color: $primary-practical-blue;
    }
  }

  &.btn-primary {
    @extend %btn-primary;
  }

  &.btn-secondary {
    border-color: $primary-magic-green;
    background-color: $white;
    color: $primary-practical-blue;

    &:active {
      background-color: $magic-200;
    }

    &:focus {
      border-color: $primary-practical-blue;
    }

    &:hover {
      background-color: $magic-050;
    }
  }

  &.btn-beta-feature {
    background-color: $primary-practical-blue;
    color: $white;

    &:active {
      background-color: $practical-200;
    }

    &:focus {
      border-color: $primary-practical-blue;
    }

    &:hover {
      background-color: $practical-200;
      color: $primary-practical-blue;
    }

    &::after {
      @extend %beta-badge;
      content: 'BETA';
      position: relative;
      top: 0;
      right: 0;
    }
  }

  &.btn-link {
    @extend %a-link;
    padding: 0;
    vertical-align: baseline;
    border: none;
    font-size: unset;
    font-weight: unset;

    &.secondary {
      @extend %a-secondary;
    }
  }

  &.btn-danger {
    &,
    &:active {
      border-color: transparent;
      background-color: $crimson-600;
      color: $white;
    }

    &:focus,
    &:hover {
      background-color: $crimson-500;
    }

    &:focus {
      border-color: $primary-practical-blue;
    }
  }

  &.btn-outline-danger {
    border-color: $primary-spark-red;
    background-color: $white;
    color: $primary-practical-blue;

    &:hover {
      background-color: $crimson-050;
    }
  }

  &.btn-select {
    border-color: $iron-100;
    box-shadow: none;

    .open & {
      box-shadow: none;
      background-color: $iron-025;
    }
  }

  &.disabled {
    &,
    &:active,
    &:focus,
    &:hover {
      opacity: 1;
      border-color: $iron-100;
      background-color: $iron-050;
      color: $iron-300;
    }
  }

  &.btn-transparent {
    color: $primary-practical-blue;

    &,
    &:active,
    &:focus,
    &:hover {
      border-color: transparent;
      background: transparent;
    }

    .open & {
      box-shadow: none;
      color: $primary-practical-blue;

      &,
      &:active,
      &:focus,
      &:hover {
        border-color: transparent;
        background: transparent;
      }
    }
  }

  &.btn-icon {
    padding: $spacer-1;
    display: inline-flex;
    justify-content: center;
    color: $primary-practical-blue;

    &.btn-transparent:hover {
      color: $primary-link-blue;
    }

    &.project-edit-link {
      color: $primary-magic-green;
    }

    .icon-more {
      font-size: 0.3rem;
    }
  }

  &.btn-close {
    position: absolute;
    top: $spacer-2;
    right: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $primary-practical-blue;
    font-size: $font-size-sm;
    font-weight: $font-semibold;

    .icon {
      margin-left: $spacer-2;
      font-size: 0.9em;
    }
  }

  &.btn-sm,
  &.btn-small,
  &.btn-xs {
    .icon-left {
      margin-right: $spacer-1;
    }

    .icon-right {
      margin-left: $spacer-1;
    }
  }

  .loading-indicator {
    margin-right: $spacer-2;
  }

  // Icons
  .icon {
    vertical-align: middle;
  }
}

.btn-device-setup {
  width: 100%;
  padding: $spacer-3;
  text-align: left;

  small {
    opacity: 0.6;
  }
}

.btn-container-inline {
  display: flex;

  .btn + .btn {
    margin-left: $spacer-1;
  }
}

.btn-group {
  &.dropdown {
    border: 2px solid $primary-magic-green;
    border-radius: 4px;

    .btn {
      border: none;
      border-radius: 2px;
      font-weight: $font-medium;

      &.dropdown-toggle {
        $transparent: rgba($white, 0);
        background-image: linear-gradient($iron-100, $iron-100),
          linear-gradient($transparent, $transparent);
        background-repeat: no-repeat;
        background-position: left center, 1px 0;
        background-size: 1px 70%, calc(100% - 1px) 100%;
      }

      .icon {
        padding: 0 $spacer-1;
      }
    }
  }

  .btn {
    border-width: 1px;
    border-radius: 4px;
    font-weight: $font-regular;

    &.btn-default {
      &.active {
        border-color: $primary-magic-green;
        box-shadow: none;
        background-color: $primary-magic-green;
        color: $primary-practical-blue;
      }
    }
  }
}

.toggle {
  &.btn {
    border: 1px solid $iron-100;
  }

  .toggle-group {
    .btn {
      border: none;
      text-transform: uppercase;

      &.btn-default {
        background-color: $iron-050;
      }

      &.active {
        box-shadow: none;
      }

      &.toggle-on {
        padding-left: 0;
      }

      &.toggle-off {
        padding-right: 0;
      }

      &.toggle-handle {
        background-color: $white !important;
      }
    }
  }
}

.dash-buttons {
  .btn {
    width: 100%;
    min-width: 10rem;
    margin-left: $spacer-5;
    padding: $spacer-3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: normal;

    &:first-child {
      margin-left: 0;
    }

    &.new-feature {
      &:before {
        content: 'New';
        position: absolute;
        top: 24px;
        right: 15px;
        padding: 2px 7px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background-color: $practical-500;
        color: $white;
      }
    }
  }

  .icon {
    margin-bottom: $spacer-3;
    display: block;
    font-size: 4em;
  }
}

// Override dataTables buttons - It looks messy because the dataTables css is imported after ours, so we need to be even more specific to override.
.dt-buttons,
div.colvis-button-container {
  div.dt-button-collection {
    width: unset;
    margin: 0;
    padding: 0;

    button.dt-button.buttons-columnVisibility {
      width: 100%;
      margin: 0;
      padding: $spacer-2 calc(#{$spacer-3} + #{$spacer-2} + 0.6rem);
      border: none;
      box-shadow: none;
      background: none;
      color: $iron-600;
      text-align: left;

      &:active,
      &:focus,
      &:hover {
        box-shadow: none;
        color: $primary-practical-blue;
        text-shadow: none;
      }

      &:active,
      &:focus {
        background: $iron-025;
      }

      &:hover {
        background: $magic-050;
      }

      &.active,
      &.active:not(.disabled) {
        padding-left: $spacer-3;
        box-shadow: none;
        background: $white;
        color: $primary-practical-blue;
        font: $font-ps-med;

        &::before {
          @extend .icon;
          @extend %icon-check-mini-before;
          margin-right: $spacer-2;
          vertical-align: top;
          font-size: 0.6rem;
        }

        &:hover {
          background-color: $magic-050;
        }
      }
    }
  }
}

div.colvis-button-container {
  position: absolute;
  top: 4px; // overridden in BasePersistentStateList.js
  left: calc(100% - 30px);
  cursor: pointer;

  button.dt-button.buttons-colvis {
    position: absolute;
    visibility: hidden;
  }
}

button.dt-button.buttons-colvis {
  @extend .btn;
  @extend .btn-sm;
  @extend .btn-secondary;

  &:active:not(.disabled):hover:not(.disabled),
  &:hover:not(.disabled) {
    border: 2px solid $primary-magic-green;
    box-shadow: none;
    background: $magic-050;
  }

  &:active:not(.disabled) {
    box-shadow: none;
    background: $magic-200;
  }

  &:focus:not(.disabled) {
    border: 2px solid $primary-practical-blue;
    background: $white;
    text-shadow: none;
  }
}
