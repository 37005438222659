@import '~@estimateone/frontend-components/src/css/variables';

.tooltip {
  padding: 24px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px $iron-100;
  background-color: white;
}

.period {
  margin-bottom: 8px;
  color: $primary-practical-blue;
  font: $font-p-heavy;
}

.dueDate {
  margin-bottom: 16px;
  color: $primary-practical-blue;
  font: $font-tiny-reg;
}

.indicator {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;

    span {
      padding-left: 6px;
    }
  }
}

.indicatorTextHighlight {
  font: $font-tiny-heavy;
  line-height: 16px;
}

.indicatorText {
  font: $font-tiny-reg;
  line-height: 16px;
}

.indicatorCount {
  font-variant-numeric: tabular-nums;
}

.subTotal {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $iron-100;

  span {
    padding-left: 16px;
  }
}

.coveragePopover {
  display: inline-block;
  vertical-align: middle;
}
