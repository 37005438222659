@import '~@estimateone/frontend-components/src/css/variables';

.heading {
  font: $font-heading-3;
}

.contents {
  margin-top: $spacer-4;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  p {
    margin-bottom: 0;
    flex-basis: 50%;
    color: $iron-700;
    font-size: $font-size-lg;
  }
}
