@import '~@estimateone/frontend-components/src/css/variables';

.inviteSummaryCard {
  margin-bottom: $space-32;
}

.description {
  margin: 0;
}

.preferredIndicator {
  margin-right: $space-8;
  vertical-align: top;
  font-size: $font-size-md;
}
