@import '~@estimateone/frontend-components/src/css/variables';

.yourTeamOnly {
  opacity: 0.6;
  color: $practical-900;
  font-size: 1rem;
  font-weight: normal;
}

.notesForm {
  margin-bottom: 2rem;

  textarea {
    margin-bottom: 1rem;
  }
}
