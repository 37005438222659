@import '../../../../../css/_shared/variables';
@import '../../../../../css/app/mixins';
@import '../../../../../css/_shared/legacy/colours';
@import '../../mixins';

$tenderer-e1-colour: #2172aa;
$tenderer-user-colour: #d927b2;
$autocomplete-item-hover: #fffce6;

.newTagContainer {
  position: relative;
  margin-left: $space-4;
  display: inline-block;
  vertical-align: middle;
}

.navContainer {
  padding: 0 $spacer-5;

  .badge {
    margin-top: -4px;
    margin-bottom: -4px;
    margin-left: $spacer-1;
  }
}

.compactNavTabs {
  li.tabItem {
    margin-right: $space-12;
  }
}

.projectBody {
  position: relative;
  padding: 0 $spacer-5;
  overflow-x: hidden;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  overflow-y: auto;
  scroll-behavior: smooth;

  .projectSection {
    margin-bottom: 2rem;
  }

  .projectDescription {
    margin-top: $spacer-4;
    font: $font-ps-med;
    white-space: pre-wrap;

    .descriptionsList {
      margin-top: $spacer-2;
      margin-bottom: $spacer-2;
      list-style: none;

      li {
        margin-top: $spacer-2;
      }

      ul > li,
      ol > li {
        margin: $spacer-2 0;
        font: $font-ps-med;
      }
    }
  }

  .hideShowWrapper {
    a.hideShow {
      margin-bottom: $spacer-5;
      display: inline-block;
      color: $practical-900;
      font-size: 0.8em;
      font-weight: bold;
      text-decoration: none;
    }

    .disableHideShowAnchor {
      pointer-events: none;
    }

    .faded-chevron {
      opacity: 0.2;
    }
  }

  .projectLogs {
    ul {
      margin: $spacer-3 0;
    }
  }

  .tendererPrivateLabel {
    margin: 1rem 0;
    padding: 4px 6px;
    cursor: default;
    display: block;
    border-radius: 3px;
    background: $practical-500;
    color: $white;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.dateIcon {
  @include date-display;
}

.nbcFlag {
  display: flex;
  align-items: center;
}

.stageDescription {
  .description {
    margin-left: $spacer-3;
    color: $iron-600;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.consultantsPaywall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: $font-ps-reg;

  a {
    white-space: nowrap;
  }
}

.tablePlaceholder {
  background-color: $iron-300;
}

.consultantsEmpty {
  padding: $spacer-4;
  border-top: 1px solid $iron-200;
  border-bottom: 1px solid $iron-200;
  text-align: center;

  p {
    margin: 0;
  }
}

.redactedText {
  @include redactedText($iron-500);
}

.consultantsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
