@import '~@estimateone/frontend-components/src/css/variables';

// @TODO temporary styling hack
.insightsContainer > div {
  margin-bottom: 2rem;
}

.settingsHeader {
  margin: 0 $space-8 0 0;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: $space-24;
}

.headerActionContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonRight {
  margin-left: auto;
}
