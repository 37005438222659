@import '~@estimateone/frontend-components/src/css/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: $space-4;

  .containerRow {
    display: flex;
    gap: $space-8;

    & svg {
      max-width: $space-16;
      max-height: $space-16;
      margin-top: $space-4;
    }

    .heading {
      font: $font-p-heavy;
    }
  }

  p {
    margin-bottom: 0;
    font: $font-ps-reg;
  }
}
