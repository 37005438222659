@import '../../../css/_shared/variables';

.pricing {
  font-weight: $font-semibold;

  .dollars {
    vertical-align: baseline;
    font-size: 1em;
  }

  .sign,
  .cents,
  .dollarSign {
    vertical-align: super;
    font-size: .5em;
  }
}
