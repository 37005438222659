@import '~@estimateone/frontend-components/src/css/variables';

.section {
  h3 {
    margin-bottom: $spacer-2;
  }
}

.contact {
  margin-top: $spacer-4;
}
