@import '~@estimateone/frontend-components/src/css/variables';

.descriptionSection {
  margin-top: $space-48;
  display: grid;
  grid: 1fr / 1fr;
  gap: $space-24 $space-32;
}

.headingSection {
  display: flex;
  align-items: center;
}

.tooltip {
  display: flex;
}

.helpIcon {
  margin-left: $space-8;
  color: $primary-practical-blue;
}
