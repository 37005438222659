@import '~@estimateone/frontend-components/src/css/variables';

.container {
  margin: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  color: $primary-link-blue;
}

.heading {
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-practical-blue;
  font: $font-p-reg;

  p {
    margin-bottom: $space-4;
  }
}

.button {
  margin-top: $space-32;
}
