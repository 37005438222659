@import '~@estimateone/frontend-components/src/css/variables';

.summaryRow {
  margin-top: $space-4;
  font: $font-tiny-reg;

  span {
    display: inline-block;
    vertical-align: top;
  }
}

.headerContainer {
  padding: $space-32;
  background-color: $practical-900;
  color: $white;

  h2 {
    margin: $space-16 0;
  }
}

.profileContentStyle {
  padding: 0 $spacer-5 $spacer-5;
}
