@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../../css/_shared/legacy/colours';

.selected-company {
  padding: 6px 12px;
  display: flex;
  border: 1px solid $silver;
  border-radius: 3px;
  background-color: $iron-050;
}

.selected-company__company {
  flex-grow: 1;
}

.company {
  display: flex;
  font-size: 13px;
}

.company__name {
  flex-grow: 1;
  align-self: center;
  color: $astronaut;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;

  .selected-company & {
    color: $mine-shaft;
    font-weight: normal;
  }
}

.company__phone,
.company__address {
  margin-left: 12px;
  align-self: center;
  color: $iron-500;
}

.company__icon {
  margin-right: 5px;
  color: $dusty-gray;
}

.selected-company__dismiss {
  margin-left: 12px;
  padding: 0;
  align-self: center;
  border: 0;
  background: none;
}

.potential-company {
  margin-top: -15px;
  margin-bottom: 20px;
  border: 1px solid $silver;
  border-top: none;
  border-radius: 0 0 3px 3px;
  background: $white;
}

.potential-company__header,
.potential-company__item,
.potential-company__new-company {
  width: 100%;
  padding: 6px 12px;
  border: none;
  background: none;
  text-align: left;
}

.potential-company__loader {
  display: flex;
}

.potential-company__loader-text {
  padding-right: 10px;
}

.potential-company__item,
.potential-company__new-company {
  &:hover {
    background-color: $iron-050;
  }
}

.potential-company__header {
  color: $dove-gray;
}

.potential-company__new-company {
  color: $astronaut;
}

/* stylelint-disable selector-pseudo-class-disallowed-list */
:global {
  .squared-off-bottom-border {
    input.form-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
