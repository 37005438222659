@import '../../../../css/_shared/variables';

.companyList {
  margin-top: $spacer-5;
}

.companyListItem {
  padding: $spacer-5 0;
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid $iron-100;

  &:first-child {
    border-top: 1px solid $iron-100;
  }

  &.processing {
    opacity: 0.3;
  }

  div {
    div {
      justify-content: center;
    }
  }
}

.companyActions {
  margin: 0 $spacer-5;
  flex-basis: 15%;
  align-self: center;
  white-space: nowrap;

  div + div {
    margin-top: 5px;
  }
}

.companyCard {
  max-width: 42%;
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;

  &:hover {
    background-color: $magic-050;
  }
}

.companyCardHeader {
  justify-content: space-between;

  .companyDetails {
    padding: $spacer-1 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $iron-600;
    font: $font-tiny-reg;
  }

  h5 {
    display: flex;
    justify-content: space-between;
  }

  .companyCardHeaderId {
    color: $iron-600;
  }

  .companyCardHeaderName {
    display: flex;
    align-items: center;
  }

  .companyCardHeaderConnectedIcon {
    margin-left: $space-8;
  }
}

.companyCardContacts {
  .contact {
    padding: $spacer-1 0;
    display: flex;

    .contactName,
    .contactEmail,
    .contactPhone {
      overflow: hidden;
      font: $font-tiny-reg;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .contactName,
    .contactPhone {
      flex-basis: 30%;
    }

    .contactEmail {
      flex-basis: 40%;
      color: $iron-600;
    }

    .contactPhone {
      color: $iron-600;
      text-align: right;
    }
  }
}

.duplicatesHeader {
  margin-top: $spacer-3;
}

.disabledMergeTooltip {
  text-wrap: auto;
}
