@import '../../../../../../css/_shared/variables';
@import '../../../../../../css/app/mixins';
@import '../../../../../../css/_shared/legacy/colours';
@import '../../../mixins';

.projectSection {
  margin-bottom: 2rem;
}

.projectTrades {
  .userTrades {
    margin: 15px 0;
  }

  .allTrades {
    margin: 15px 0;

    .tradeDiscipline {
      margin: 1em 0;
    }
  }

  ul {
    li {
      float: left;
      width: 32%;
      padding: 3px;
      display: block;
      list-style-type: none;
      color: $practical-900;
      font-size: 0.9rem;
    }

    li.userTrade {
      color: $magic-600;
      font-weight: bold;
    }
  }
}

.redactedText {
  @include redactedText($iron-500);
}

.redactedTradeInfoSection {
  span {
    @include redactedText($iron-500);
  }
}
