@import '~@estimateone/frontend-components/src/css/variables';

.tenderResponsesTable {
  display: flex;
  flex-direction: column;
}

.filter {
  margin-bottom: $space-16;
  justify-content: flex-end;
}

.filterValue {
  font-weight: $font-semibold;
}

.email {
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  :global(.__react_component_tooltip) {
    max-width: unset;
  }
}

.phone {
  white-space: nowrap;
}
