%nav {
  display: inline-flex;
  list-style: none;
}

%nav-item {
  margin-right: $spacer-5;

  &:last-child {
    margin-right: 0;
  }
}

%nav-link-inactive {
  // Remove 4px for the bottom border
  padding: $spacer-3 0 calc(#{$spacer-3} - 4px) 0;
  cursor: pointer;
  display: inline-block;
  border: none;
  border-bottom: 4px solid transparent;
  background: none;
  color: $iron-600;
  font: $font-p-heavy;
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    background: none;
    color: $primary-practical-blue;
  }
}

%nav-link-active {
  border-bottom-color: $primary-magic-green;
  color: $primary-practical-blue;
}

%nav-badge {
  margin-top: -4px;
  margin-bottom: -4px;
}
