@import '~@estimateone/frontend-components/src/css/variables';

.resultListItem {
  border: 2px solid $iron-100;
  border-radius: 8px;
}

.companyHeader {
  padding: $space-12 $space-12 $space-16 $space-12;
  cursor: pointer;
  border-bottom: 1px solid $iron-050;
  border-radius: 8px 8px 0 0;

  &:hover {
    background-color: $iron-050;
  }

  &:focus {
    border-radius: 8px;
    outline: $focus-outline;
    background-color: $iron-050;
  }
}

.companyInfoColumn {
  padding-left: $space-8;
}

.companyName {
  margin: 0;
  font: $font-intro;
}

.statusIconColumn {
  padding-right: $space-4;
  padding-left: $space-4;
}

.locationAndTags {
  justify-content: space-between;
}

.location {
  font: $font-ps-reg;
}

.contactsRow {
  margin: $space-12;
}

.contacts {
  padding: 0;
}
