@import '~@estimateone/frontend-components/src/css/variables';

.backButton {
  padding-top: $space-24;
  padding-left: $space-32;
  background: $primary-practical-blue;
  color: $white;

  .leftIcon {
    font: $font-ps-heavy;
  }

  .sliderBackButton {
    color: $white;
  }
}
