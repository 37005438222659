@keyframes atebits {
  0% {
    transform: rotate(0deg);
  }

  3% {
    transform: rotate(90deg);
  }

  10% {
    transform: rotate(90deg);
  }

  13% {
    transform: rotate(180deg);
  }

  20% {
    transform: rotate(180deg);
  }

  23% {
    transform: rotate(90deg);
  }

  30% {
    transform: rotate(90deg);
  }

  33% {
    transform: rotate(180deg);
  }

  40% {
    transform: rotate(180deg);
  }

  43% {
    transform: rotate(270deg);
  }

  50% {
    transform: rotate(270deg);
  }

  53% {
    transform: rotate(180deg);
  }

  60% {
    transform: rotate(180deg);
  }

  63% {
    transform: rotate(90deg);
  }

  70% {
    transform: rotate(90deg);
  }

  73% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  83% {
    transform: rotate(-90deg);
  }

  90% {
    transform: rotate(-90deg);
  }

  93% {
    transform: rotate(0deg);
  }
}

.atebits {
  position: relative;
  width: 9px;
  height: 9px;
  display: inline-block;
  overflow: hidden;
  transform-origin: 50% 50%;
  animation: atebits 8s infinite ease-in-out;
  box-shadow: rgb(0 0 0 / 50%) -12px -12px 0 0,
    rgb(0 0 0 / 50%) 12px -12px 0 0,
    rgb(0 0 0 / 50%) -12px 0 0 0,
    rgb(0 0 0 / 50%) 12px 0 0 0,
    rgb(0 0 0 / 50%) -12px 12px 0 0,
    rgb(0 0 0 / 50%) 0 12px 0 0,
    rgb(0 0 0 / 50%) 12px 12px 0 0;
  background: rgb(0 0 0 / 50%);
  text-indent: -9999px;
}
