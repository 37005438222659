@import '~@estimateone/frontend-components/src/css/variables';

.projectInvolvementSection h3 {
  margin-top: 0;
  margin-bottom: $space-24;
}

.projectInvolvementSection textarea {
  height: 96px;
  padding: $space-12 $space-16;
}

.projectInvolvementType {
  margin-bottom: $space-16;

  & .projectInvolvementTypeOption {
    margin-bottom: $space-8;
  }

  & label {
    font: $font-p-reg;
    font-weight: unset;
  }
}
