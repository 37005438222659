@import '~@estimateone/frontend-components/src/css/variables';

.speciFinderIcon {
  margin-right: $space-4;
  stroke: currentColor;
}

.speciFinderButton {
  cursor: default;
  display: flex;
  flex-direction: row;

  ul {
    margin: 0 $spacer-4;
  }
}

.clickable {
  cursor: pointer;
}

.tooltip {
  padding: $space-8 $space-12 !important;
  border-radius: 4px !important;
  font: $font-tooltip;
  text-align: left;
  word-wrap: break-word;
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  &:global(.show) {
    opacity: 1 !important;
  }
}
