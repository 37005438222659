@import '~@estimateone/frontend-components/src/css/variables';

.pending_import_notice {
  .progress-percentage {
    position: absolute;
    z-index: 1;
    min-width: 504px;
  }
}

.progress-bar {
  color: $primary-practical-blue;
}

.progress {
  .progress-bar,
  .progress-bar-success {
    background-color: $primary-magic-green;
  }
}

.progress-info {
  color: $primary-magic-green;
}

.progress-text {
  color: $iron-600;
}

.existingRadioOptions {
  .option {
    &.active {
      .existing-option {
        display: block;
      }
    }

    .existing-option {
      display: none;
    }
  }
}

span.draft-changes-folder {
  padding-left: 3px;
  color: $goblin;
  font-weight: bold;
  line-height: 1.5em;
  text-transform: uppercase;
}

.ext-icon {
  width: 16px;
  height: 16px;

  &._blank {
    display: block;
    background: url('#{$image-path}_blank.png') right top no-repeat;
  }

  &.bmp {
    display: block;
    background: url('#{$image-path}bmp.png') right top no-repeat;
  }

  &.doc {
    display: block;
    background: url('#{$image-path}doc.png') right top no-repeat;
  }

  &.docx {
    display: block;
    background: url('#{$image-path}docx.png') right top no-repeat;
  }

  &.dwg {
    display: block;
    background: url('#{$image-path}dwg.png') right top no-repeat;
  }

  &.jpg {
    display: block;
    background: url('#{$image-path}jpg.png') right top no-repeat;
  }

  &.pdf {
    display: block;
    background: url('#{$image-path}pdf.png') right top no-repeat;
  }

  &.png {
    display: block;
    background: url('#{$image-path}png.png') right top no-repeat;
  }

  &.rtf {
    display: block;
    background: url('#{$image-path}rtf.png') right top no-repeat;
  }

  &.xls {
    display: block;
    background: url('#{$image-path}xls.png') right top no-repeat;
  }

  &.xlsx {
    display: block;
    background: url('#{$image-path}xlsx.png') right top no-repeat;
  }

  &.zip {
    display: block;
    background: url('#{$image-path}zip.png') right top no-repeat;
  }
}

div.doc-download-status {
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }
}

div.diff-image-container {
  .diff-loading {
    margin-top: $spacer-5;
    margin-bottom: $spacer-5;
  }

  img {
    max-width: 100%;
    margin-bottom: $spacer-2;
    border: 2px solid $iron-300;
  }

  .diff-image-text {
    margin-bottom: $spacer-2;
  }
}
