@import '~@estimateone/frontend-components/src/css/variables';

// Styles for Step 1
.row {
  font: $font-ps-reg;
}

.selectedRow {
  font: $font-ps-reg;
  font-weight: bold;
}

.disabledRow {
  background-color: $white;
  color: $iron-500;
  font: $font-ps-reg;

  &:hover {
    background-color: $white;
  }
}

 .selectSite {
   margin: $space-8 0 $space-16;
 }

.noDrivesText {
  margin: $space-16;
  color: $iron-600;
  font: $font-ps-reg;
  font-style: italic;
  text-align: center;
}

// Styles for Step 2
 .driveItemsDescription {
  margin-bottom: $space-24;
  color: $primary-practical-blue;
  font: $font-ps-reg;

  p:nth-child(1) {
    margin-bottom: $space-16;
  }
}

.labelHeavy {
  font: $font-p-heavy;
}

.driveItemList {
  list-style-type: none;
}

// Styles for Step 3
.detailsText {
  margin-bottom: $space-24;
  color: $primary-practical-blue;
  font: $font-ps-reg;
}

.documentsList {
  list-style-type: none;
}

.projectLabel {
  font: $font-ps-med;
}

.projectDescription {
  font: $font-ps-reg;
}

// PrimeReact Tree Styles
// See https://primereact.org/tree/#pt.wireframe for more information
.root {
  padding: $space-16;
  color: $primary-practical-blue;
}

.subgroup {
  // Remove global div padding
  padding: revert;
}

.node {
  width: fit-content;
  list-style-type: none;
}

.loadingSpinnerContainer {
  margin-left: $space-8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.togglerButton {
  margin-left: $space-8;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: none;
}

.content {
  margin: $space-12 0;
  display: flex;
  flex-direction: row;
  border-radius: 5px;

  &:hover span {
    cursor: pointer;
    border-radius: 5px;
    background-color: $magic-050;
  }

  // node is selected
  &[data-p-highlight="true"] span {
    border-radius: 5px;
    background-color: $magic-500;
  }

  span {
    margin-left: $space-4;
    padding: 0 $space-4;
  }

  // Styles for dummy node where there is no nested folders
  button[data-dummy="true"] {
    display: none;
  }

  span[data-dummy="true"]:hover {
      cursor: not-allowed;
      background-color: $iron-050!important;
  }
}

