.cp-body {
  table {
    @extend %table-no-bg;
  }
}

td {
  &.filesize {
    font: $font-ps-reg;
    text-align: right;
    white-space: nowrap;
  }
}

td.inline-stages {
  ul {
    li {
      width: 100%;
      margin-bottom: $spacer-2;
      display: flex;
      justify-content: space-between;
      list-style: none;

      &:last-child {
        margin-bottom: 0;
      }

      .inline-tenderer {
        font-weight: $font-medium;
        white-space: nowrap;
      }

      .dateDue {
        margin-left: $spacer-3;
        white-space: nowrap;
      }
    }
  }

  div.stages-vertical-centre {
    display: flex;
    align-items: center;

    ul {
      max-width: 100px;
      margin-left: auto;
    }
  }

  .subbies-count {
    margin-top: $space-8;
    padding: $space-4 0;
    border-top: 1px solid $iron-100;
    font-size: 85%;
  }
}

td.subcontractors {
  .subbies-count-column {
    display: flex;
    align-items: center;
    font-weight: 600;

    .count {
      width: $space-32;
    }
  }
}

table.current-tender-table,
table.current-construction-table,
table.subbie-current-construction-table {
  tr {
    td {
      &.quotes_due {
        .date {
          display: block;
        }
      }

      .has-docs {
        display: flex;

        .activity-link {
          min-width: 80px;
          display: block;
          line-height: $font-lh-lg;

          .count {
            display: block;
            font-size: 1.4rem;
            font-weight: 700;
          }
        }
      }

      .project_options {
        margin-top: $spacer-1;
        color: $iron-600;
      }
    }
  }
}

table.addressBook-table,
table.addressbook-user-dataTable {
  .contact {
    &:first-child {
      margin-top: $spacer-1;
    }

    &:not(:last-child) {
      padding-bottom: $spacer-2;
    }

    .details {
      padding: 0;
    }

    .contact-types,
    .email,
    .direct-phone {
      white-space: nowrap;
    }

    .contact-types {
      display: inline-block;
    }

    .name {
      font-weight: $font-medium;
    }
  }
}

table.addressBook-table {
  &.dataTable thead {
    border-bottom: 1px solid $iron-050;
  }

  th {
    display: none;
  }

  tr {
    &:hover {
      cursor: pointer;
    }
  }

  .contact {
    display: flex;

    .details {
      &.email,
      &.direct-phone {
        padding-left: $spacer-3;
        text-align: right;
      }

      &.email {
        flex-basis: 20rem;
      }

      &.direct-phone {
        flex-basis: 10rem;
      }
    }
  }

  .abc-card {
    margin: $spacer-2 0 $spacer-3 0;

    .company {
      margin-bottom: $spacer-2;
      align-items: baseline;

      .abc-email-errors {
        color: $crimson-600;
        font: $font-heading-3;
      }

      .abc-company-name,
      .abc-company-phone {
        font: $font-heading-3;
      }

      .abc-company-phone {
        font-weight: $font-medium;
      }

      .abc-company-address {
        color: $iron-600;
      }
    }

    .extra-contacts {
      margin-left: $space-48;
      color: $iron-600;
    }

    ul.contacts {
      width: 100%;
      display: table;
      list-style: none;
    }
  }
}

table.docDataTable {
  td {
    &.addendum {
      font: $font-ps-reg;
    }
  }

  .qq-upload-spinner {
    height: 15px;
    display: inline-block !important;
  }

  .qq-upload-success {
    .qq-upload-spinner {
      display: none !important;
    }
  }
}

table.auto-supersede-table {
  label {
    margin: 0;
    font-weight: normal;
  }

  .drawing-id {
    padding-right: $spacer-1;
    font-weight: $font-semibold;
    text-decoration: underline;
  }

  .revision {
    padding-left: $spacer-1;
    font-weight: $font-semibold;

    &:before {
      content: 'Rev ';
      opacity: 0.6;
      font-weight: normal;
    }
  }
}

table.invitation-table tbody {
  tr.duplicate td {
    &.show-on-duplicate .hide-duplicate {
      display: initial;
    }

    .hide-duplicate {
      display: none;
    }
  }

  tr td {
    border-bottom: none;
  }

  tr:last-child td {
    border-bottom: 1px solid $iron-050;
  }
}

ul.archive-status-list {
  margin-top: $spacer-2;
  font-size: 0.9rem;

  li {
    list-style: none;

    &.archive-failed {
      color: $crimson-600;
    }

    .name {
      font-weight: bold;
    }

    .message {
      float: right;
    }
  }
}

table {
  &.error-table {
    td {
      .message {
        text-align: right;
      }
    }
  }
}

table.table.accountSharedLicensesTable tbody td,
table.table.accountLicensesTable tbody td {
  vertical-align: top;

  .discounted {
    margin-left: $spacer-3;
  }

  .item-description {
    margin-top: $spacer-2;
    display: block;
  }
}

table {
  p.procurement-message {
    font: $font-tiny-reg;
  }
}

.disabled-link {
  cursor: default;
  color: $iron-600;
  text-decoration: none;
  pointer-events: none;
}
