@import '~@estimateone/frontend-components/src/css/variables';

.overlay {
  position: fixed;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 1.15;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
}

.notifyStepContainer {
  display: flex;
  color: $primary-practical-blue;
  gap: 4.5rem;
}

.notifyStepTableContainer {
  min-width: 720px;

  table th:first-child {
    z-index: 2;
  }

  tbody tr td:last-child {
    text-align: center;
  }
}

.notifyStepTableHeaderContainer {
  margin-bottom: $space-24;
  display: flex;
  justify-content: space-between;
}

.notifyStepTableHeading {
  margin: 0;
  font: $font-heading-3;
}

.notifyStepTableHeadingHelp {
  display: flex;
  align-items: center;
  gap: 6px;
}

.notifyStepPreviewHeading {
  margin: 0;
  font: $font-heading-3;
}

.notifyTableHeaderCheckbox svg {
  margin-top: -6px;
  margin-left: 0;
}

.notifyStepMessageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $space-24;
}

.notifyStepEmailSubject {
  width: 100%;
}

.notifyStepEmailMessageContainer {
  display: flex;
  flex-direction: column;
  gap: $space-8;
}

.notifyStepEmailMessage {
  width: 100%;
  border: 2px solid $iron-100;
  border-radius: 4px;
}

.notifyStepEmailMessage:focus-within {
  border-color: $primary-practical-blue;
}

.fullHeightWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: none;
}
