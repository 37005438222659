.twitter-typeahead {
  .tt-dataset {
    overflow: hidden;
    background: $white;

    .no-results {
      padding: 10px;
    }

    p.tt-suggestion {
      padding: 4px 13px;
      white-space: nowrap;
    }

    /* Added to suggestion element when menu cursor moves to said suggestion */
    .tt-suggestion:hover,
    .tt-suggestion:focus,
    .tt-cursor {
      cursor: pointer;
      background-color: $astral;
      color: white;
    }
  }

  .tt-menu {
    box-shadow: 0 7px 30px -7px rgb(0 0 0 / 60%);
  }
}
