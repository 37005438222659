@import '~@estimateone/frontend-components/src/css/variables';

.container {
  padding: $space-24 $space-16;
  display: flex;
  flex: auto;
  align-items: center;
}

.crane {
  svg {
    stroke: $practical-600;
  }
}

.errorIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingState {
  padding: $space-24 $space-16;
  flex: auto;
}
