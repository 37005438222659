@import '~@estimateone/frontend-components/src/css/variables';

.avatarContainer {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $white;
  background-color: $iron-100;
}

.companyInitial {
  margin-bottom: 0;
  font-size: 2.875rem; // 46px
  font-weight: $font-semibold;
  line-height: 3.25rem; // 52px
}
