@import '~@estimateone/frontend-components/src/css/variables';

.projectDetailsFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-24 $space-32;

  :first-child {
    grid-column: span 2;
  }
}
