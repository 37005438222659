@import '~@estimateone/frontend-components/src/css/variables';

.builderNameContainer {
  display: flex;
  justify-content: space-between;
}

.builderName {
  margin-bottom: $spacer-2;

  a {
    font-size: $default-font-size;
    font-weight: $font-semibold;
  }
}

.stageSubHeaderWrapper > div {
  margin-bottom: 0;
  padding: 20px 10px;
}
