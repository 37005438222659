@import '~@estimateone/frontend-components/src/css/variables';

.quoteFile {
  display: flex;
  justify-content: flex-start;

  .fileIcon {
    margin: -$spacer-4 0;
    padding: $spacer-4 $spacer-6 $spacer-4 $spacer-4;
    border-right: 2px solid $iron-100;
    color: $iron-200;
  }

  .fileDetails {
    width: 100%;
    margin-left: $spacer-4;
    display: flex;
    justify-content: space-between;
  }

  .fileName {
    font-weight: $font-semibold;
  }

  .fileAction {
    margin-left: $spacer-4;
    white-space: nowrap;
  }
}

.quoteExtraFilesList {
  list-style: none;
  border-top: 2px solid $iron-100;

  li {
    padding: $spacer-3;
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid $iron-100;
  }

  .extraFileIcon {
    color: $iron-200;

    svg {
      vertical-align: middle;
    }
  }

  .extraFileName {
    padding: 0 $spacer-3;
    flex-basis: 50%;
    font-weight: $font-semibold;
  }

  .extraFileType,
  .extraFileSize {
    padding: 0 $spacer-3;
    flex-basis: 20%;
  }

  .extraFileAction {
    margin-left: auto;

    a {
      color: $primary-practical-blue;

      &:hover {
        color: $primary-link-blue;
      }
    }

    svg {
      vertical-align: middle;
    }
  }
}
