@import '~@estimateone/frontend-components/src/css/variables';
@import '~@estimateone/frontend-components/src/components/HintCard/styles.module.scss';

.connectionPreferenceFooter {
  padding: $space-16 0;
  display: flex;
  justify-content: space-between;

  .adjacentButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-16;
  }

  button {
    min-width: 192px;
  }
}
