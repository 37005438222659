@import '~@estimateone/frontend-components/src/css/variables';

.uploadIcon {
  margin-right: $space-4;
  stroke: currentColor;
}

.requestDocsTooltip {
  width: 100% !important;
}
