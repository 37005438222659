@import '~@estimateone/frontend-components/src/css/variables';

.sortIcon {
  margin-left: $spacer-2;
  color: $iron-300;
  font-size: .5em;

  &.sorted {
    color: $primary-magic-green;
  }
}

table.table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid $iron-050;

  &,
  p {
    font: $font-ps-reg;
  }

  &.resultsLoading {
    opacity: 0.6;
    transition: opacity 200ms ease-out;
  }

  &.resultsLoaded {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  p {
    padding-bottom: 0;
  }

  thead,
  tbody {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
      line-height: unset;
    }
  }

  thead {
    background-color: $iron-050;

    tr {
      th {
        border: none;
        font: $font-tiny-heavy;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        > td {
          border-top: none;
        }
      }

      &:hover {
        background-color: $magic-050;
      }

      td {
        border-top: 1px solid $iron-050;
      }
    }
  }
}

.fitToContent {
  width: 1px;
  white-space: nowrap;
}
