@import '~@estimateone/frontend-components/src/css/variables';

.alertContainer {
  margin-bottom: $space-24;

  .wrapper {
    font: $font-p-reg;

    .alertTitle {
      font: $font-p-med;
    }

    .alertLink {
      margin-left: $space-4;
    }
  }
}
