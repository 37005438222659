.doc-viewer-wrapper {
  min-width: 1024px;
  height: 100%;
  overflow: hidden;

  &.is-mobile,
  &.is-tablet {
    min-width: 100%;

    .main-content {
      width: 100%;

      .search-container {
        .input-group {
          width: 100%;
        }
      }

      .doc-tree-col {
        width: 100%;
      }
    }

    .jstree-checkbox {
      display: none;
    }
  }

  &.is-mobile {
    font-size: 16px;

    .header-menu {
      h1 {
        font-size: 14px;
      }

      .close-doc-viewer {
        display: none;
      }
    }

    .main-content {
      .search-container {
        input {
          height: 40px;
          padding: 8px 18px;
        }

        .input-group-btn {
          width: 42px;

          button {
            height: 40px;

            i {
              font-size: 20px;
            }
          }
        }
      }

      .doc-viewer-tree-view {
        ul.jstree-container-ul {
          li.jstree-node {
            margin-top: 10px;

            &.jstree-leaf {
              margin-top: 0;
              margin-bottom: 5px;

              &:first-child {
                margin-top: 5px;
              }
            }

            .doc-folder-toggle {
              font-size: 15px;

              &::before {
                margin-right: 10px;
                font-size: 24px;
              }
            }

            .doc-viewer-toggle {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  &.is-tablet {
    font-size: 18px;

    .header-menu {
      height: 56px;
      padding-top: 5px;

      h1 {
        font-size: 20px;
      }

      .close-doc-viewer {
        margin-top: -19px;
        padding: 0 -15px;
        font-size: 60px;
      }
    }

    .main-content {
      top: 56px;

      .search-container {
        input {
          height: 45px;
          padding: 8px 24px;
        }

        .input-group-btn {
          width: 120px;

          button {
            height: 45px;

            i {
              font-size: 24px;
            }
          }
        }
      }

      .doc-viewer-tree-view {
        ul.jstree-container-ul {
          li.jstree-node {
            margin-top: 15px;

            &.jstree-leaf {
              margin-top: 0;
              margin-bottom: 7px;

              &:first-child {
                margin-top: 10px;
              }
            }

            .doc-folder-toggle {
              font-size: 18px;

              &::before {
                margin-right: 14px;
                font-size: 34px;
              }
            }

            .doc-viewer-toggle {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .header-menu {
    padding: $spacer-3;
    display: block;
    background: $primary-practical-blue;

    button {
      &.hide-side-col {
        background-color: transparent;
        color: $primary-magic-green;

        &,
        &:active {
          border-color: $primary-magic-green;
        }

        &.closed {
          background-color: $primary-magic-green;
          color: $primary-practical-blue;
        }
      }
    }
  }

  .main-content {
    .doc-tree-col {
      position: relative;
      float: left;
      width: 16rem;
      height: 100%;
      background: $white;

      .scrollable-area {
        position: absolute;
        top: 0;
        bottom: 85px;
        left: 0;
        width: inherit;
        height: auto;
      }

      .options-container,
      .options-helper-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 85px;
        padding: $spacer-3;
        background: $primary-practical-blue;
        text-align: center;
      }

      .options-container {
        display: none;
      }

      .options-helper-container {
        p {
          color: $practical-050;
          font: $font-tiny-reg;
        }
      }
    }

    .doc-tree-col-resize-handle {
      z-index: 2000;
      float: left;
      width: 2px;
      height: 100%;
      background: $iron-100;

      &:hover {
        cursor: col-resize;
      }
    }

    .main-col {
      float: left;
      background: transparent;

      .doc-viewer {
        position: relative;
        display: block;
        overflow: hidden;

        .loadingContainer {
          width: 100%;
          height: 100%;
          display: none;
          background: url('#{$image-path}ajax-loader-wb.gif') center center no-repeat;
          background-size: 30px 30px;
        }

        #outerContainer {
          position: relative;
          width: 100%;
          height: 100%;

          &.is-loading {
            .loadingContainer {
              display: block;
            }

            .mainContainer {
              display: none;
            }
          }

          .mainContainer {
            position: relative;
            min-width: 675px;
            height: 100%;
            display: flex;
            justify-content: center;
          }
        }

        .imageContainer {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.is-loading {
            .loadingContainer {
              display: block;
            }

            .doc-previewer-image {
              display: none;
            }
          }

          .doc-previewer-image {
            max-width: 90%;
            max-height: 90%;
            display: initial;
          }
        }
      }

      .toolbarContainer {
        position: absolute;
        z-index: 300;
        padding: $spacer-2;
        display: flex;
        border-radius: 4px;
        box-shadow: 0 0 30px 0 rgba($primary-practical-blue, 0.2);
        background: $primary-practical-blue;
        color: rgba($white, 0.6);
        font: $font-ps-reg;

        .scaleSelectContainer {
          select {
            height: 2rem;
            margin: 0 $spacer-2;
            border-color: rgba($white, 0.2);
            background: transparent;
            color: $white;
            font: $font-tiny-med;

            #customScaleOption {
              display: none;
            }
          }
        }

        .toolbarField {
          margin: 0 $spacer-2;
          padding: $spacer-1;
          border-radius: 4px;
          background: rgba($white, 0.1);
          color: $white;
          font: $font-ps-med;
        }

        input[type='number'] {
          padding-right: 0;
          border: none;
          text-align: right;
        }
      }

      .top-bar {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
      }

      .findbar {
        width: 100%;
        padding: $spacer-2;
        display: flex;
        justify-content: center;
        box-shadow: 0 2px 13px 0 rgba($primary-practical-blue, 0.21);
        background: $steel;
        font: $font-ps-reg;

        input[type='text'] {
          height: 2rem;
        }

        .toggleFindBar {
          position: absolute;
          right: $spacer-2;
        }
      }

      .toolbar-section {
        padding: 0 $spacer-2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid rgba($white, 0.1);

        &.last {
          border-right: none;
        }

        .numPages {
          letter-spacing: $spacer-2;
        }
      }

      .embedded-button {
        padding: $spacer-2;
        border: none;
        background: transparent;

        &:hover {
          color: $primary-magic-green;
        }

        &.toggleHandTool {
          &.enabled {
            color: $primary-magic-green;
          }
        }

        &.closed {
          box-shadow: none;

          i {
            margin-top: 0;
            opacity: 0.3;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .superseded-bar {
        padding: $spacer-3;
        background: $crimson-400;
        color: $white;
        text-align: center;

        .icon {
          vertical-align: text-bottom;
        }

        .superseded-link {
          color: $white;
        }
      }

      .viewerContainer {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: $iron-100;

        &.grab-to-pan-grab {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      .pdfViewer {
        margin-top: 50px;
        padding-top: -5px;
        padding-bottom: 5px;

        .page {
          margin: 25px auto;
          border: 1px solid $alto;
          box-shadow: 0 0 15px 1px rgb(0 0 0 / 25%);

          .loadingIcon {
            position: absolute;
            display: block;
            background: none;
            inset: 0;
          }
        }
      }
    }
  }

  .search-container {
    margin-top: 20px;
    padding: 0 18px;
  }

  .doc-viewer-tree-view {
    ul.jstree-container-ul {
      min-width: 100%;
      font-size: 14px;

      li {
        margin-left: 0;
        padding-top: 6px;
        padding-bottom: 6px;

        &.active-select {
          background-color: $primary-magic-green !important;
        }

        .jstree-wholerow {
          background: none;
        }
      }

      .jstree-loading > .jstree-ocl {
        background: url('#{$image-path}ajax-loader-db.gif') center center no-repeat;
      }
    }

    .jstree-node,
    .jstree-icon {
      background-image: none;
    }

    .jstree-anchor {
      box-shadow: none;
      background: none;
      line-height: 24px;
    }

    .loadedContainer {
      &.jstree-default {
        .jstree-node {
          // margin-left: 18px;
        }

        .jstree-leaf {
          padding-left: 18px;
        }

        .jstree-container-ul > .jstree-node {
          margin-left: -5px;
        }

        .doc-folder-toggle {
          &:before {
            @extend .icon;
            padding-right: $spacer-2;
            color: $practical-500;
          }
        }

        .jstree-anchor {
          a {
            padding: 2px 3px;
            box-shadow: none;
            color: $primary-practical-blue;
            text-decoration: none;
          }
        }

        .jstree-closed {
          .jstree-ocl {
            background: none;
          }

          .doc-folder-toggle {
            @extend .icon-folder;
          }
        }

        .jstree-open {
          .jstree-ocl {
            background: none;
          }

          .doc-folder-toggle {
            @extend .icon-folder-open;
          }
        }

        .jstree-leaf {
          .jstree-ocl {
            width: 15px;
            background: none;

            &:before {
              content: none;
            }
          }
        }

        .jstree-checkbox {
          width: 14px;
          height: 18px;
          margin: 5px 4px 0 0;
          background-image: url('#{$image-path}jstree_custom.png');
          background-position: 0 0;

          &:hover {
            background-position: -14px 0;
          }

          &.jstree-undetermined {
            background-position: -42px 0;
          }
        }

        .jstree-checked {
          &.jstree-hovered {
            .jstree-checkbox {
              opacity: 0.9;
              background-position: -28px 0;
            }
          }

          .jstree-checkbox {
            background-position: -28px 0;
          }
        }

        .jstree-hovered {
          &.jstree-checkbox {
            background-position: -14px 0;
          }
        }
      }
    }
  }
}

.tree-hidden {
  .doc-tree-col-resize-handle {
    display: none;
  }
}

.doc-details-col {
  float: right;
  width: 20rem;
  height: 100%;
  overflow: hidden;

  .nav-tabs {
    text-align: center;

    &.packages-hidden {
      li {
        width: 50%;
      }
    }

    > li {
      width: 33.3%;
      margin-right: 0;
    }
  }

  .tab-content {
    height: calc(100% - 8rem);
    padding: 0 $spacer-3;
    display: block;
    overflow: scroll;
  }

  .form-group {
    label {
      color: $iron-600;
      font: $font-ps-reg;
    }
  }

  ul.audit-history {
    list-style-type: none;

    li {
      margin-bottom: $spacer-5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .packages {
    &.full-set {
      border-bottom: 1px solid $iron-100;

      .checkbox {
        &:hover {
          background: none;
        }

        label {
          cursor: default;
          display: flex;
          align-items: center;
          font-weight: $font-semibold;

          input[type='checkbox'] {
            margin-top: 0;
          }
        }
      }
    }

    .checkbox {
      margin: 0;
      padding: $spacer-2 $spacer-3;

      &:hover {
        background-color: $magic-050;
      }

      label {
        padding-left: 1.9rem;
        display: block;
        font: $font-p-reg;

        input {
          margin-left: -1.9rem;
          font-size: $font-size-md;
        }
      }
    }
  }

  .discipline {
    border-bottom: 1px solid $iron-100;

    &.expanded {
      .packages {
        display: block;
      }

      .discipline-view-toggle {
        @extend .icon-chevron-up;
      }
    }

    .discipline-title {
      padding: $spacer-2 $spacer-3;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $magic-050;
      }

      input[type='checkbox'] {
        margin-top: 0;
      }

      .discipline-name {
        margin-left: $spacer-3;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        font: $font-p-heavy;

        &:hover {
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
      }

      .discipline-view-toggle {
        color: $primary-magic-green;
      }
    }

    .packages {
      display: none;
    }
  }

  a.doc-history-trigger {
    text-decoration: none;
  }
}

.simplebar-track {
  .simplebar-scrollbar {
    opacity: 1 !important;
    background-color: $iron-600;
  }
}

.preview-search-modal {
  .result-list {
    list-style: none;

    .result {
      margin: $spacer-3 0;

      .doc-folder {
        color: $primary-link-blue;
        font: $font-tiny-heavy;
        text-transform: uppercase;
      }

      .doc-details {
        font: $font-tiny-med;
      }
    }
  }
}

body.doc-viewer-wrapper {
  &.is-mobile,
  &.is-tablet {
    .preview-search-modal .modal-content {
      .modal-body {
        max-height: 80vh !important;
      }
    }
  }

  .modal-body {
    overflow-x: hidden;
  }
}

#errorWrapper,
#notViewableWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 300px;
  margin-top: -200px;
  margin-left: -300px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-size: 30px;

    i {
      line-height: 3rem;
    }
  }

  p {
    margin-bottom: 30px;
    color: $nevada;
    font-size: 14px;
    line-height: 2rem;

    span {
      color: $mine-shaft;
    }
  }
}

.document-package-form {
  padding-bottom: 20px;
}
