@import '~@estimateone/frontend-components/src/css/variables';

.titleHero {
  margin: $spacer-6 0 $spacer-5 0;
}

.subtitle {
  margin-left: $spacer-3;
  color: $iron-700;
  font: $font-heading-3;
}
