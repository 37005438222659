@import '~@estimateone/frontend-components/src/css/variables';

.packageDatesAndQuotes {
  padding: $space-8 0 $space-24;
  display: flex;
}

.packageDates {
  padding: 0 $space-32 0 0;
  display: flex;
  gap: $space-16;
}

.packageQuotes {
  padding: 0 0 0 $space-32;
  display: flex;
  border-left: 1px solid $iron-200;
  gap: $space-16;
}

.statTitle {
  @extend %font-mini-caps;
  margin: 0 0 $space-8;
}

.statText {
  margin: 0 $space-4 0 0;
  display: inline;
  color: $primary-practical-blue;
  font: $font-intro;
}

.coverageIndicator div {
  margin-bottom: -1px;
}
