@import '../../../../../../css/_shared/variables';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - #{$space-32});
  width: 600px;
  max-height: calc(100% - #{$space-32});
  overflow: hidden auto;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: $white;

  &:focus {
    outline: none;
  }
}

.modalOverlay {
  position: fixed;
  z-index: 1050;
  inset: 0;
}

.roadblockModal {
  margin: 2rem;
  text-align: center;

  .roadblockImage {
    width: 75px;
    height: 200px;
    margin: 0 auto;
    background-image: url('../../../../../../images/roadblock.png');
    background-repeat: no-repeat;
    background-size: 75px, 225px;
  }

  .roadblockBody {
    margin: 15px;
  }

  .roadblockReason {
    color: $primary-spark-red;
    font: $font-intro;
  }

  .roadblockDescription {
    font: $font-ps-reg;
  }

  .roadblockCta {
    margin: 15px;
    display: block;
  }
}

.roadblockSlider {
  > hr {
    background-color: $iron-100;
  }

  .projectSliderHeader,
  .projectDetails {
    background-color: $iron-025;
    color: $iron-600;
  }

  .redactedProjectDetails {
    width: unset;

    // Fill slider height on all screens
    height: 2000vh;
    margin: 2rem;
    background-image: url('../../../../../../images/roadblock-slider-redacted-text.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.roadblockSliderOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.4;
  background: $steel;
}

.inSlider {
  left: calc(100vw - 410px) !important;

  /* stylelint-disable selector-pseudo-class-disallowed-list */
  :global(.modal-content) {
    border-color: $iron-100;
    box-shadow: 0 0 30px rgba($black, 0.1);
  }
}
