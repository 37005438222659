@import '~@estimateone/frontend-components/src/css/variables';

.editModalButtonsWrapper {
  margin-top: $space-48;
  display: flex;
  justify-content: space-between;

  .cancelButton {
    margin-right: $space-12;
  }
}
