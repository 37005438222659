@import '~@estimateone/frontend-components/src/css/variables';

.roadblockGraphicContainer {
  width: 504px;
  height: 160px;
  margin: 24px auto;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: $iron-025;
}

.graphicIconContainer {
  width: 184px;
  height: 122px;
  align-self: flex-end;
  border: 2px solid $electric-500;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: $white;
  text-align: center;
}

.graphicIcon {
  margin-top: 24px;

  svg {
    stroke: $electric-500;

    circle {
      fill: $electric-500;
    }
  }
}

.redactedBlock {
  width: 136px;
  height: 12px;
  margin: auto auto $spacer-1;
  background-color: $iron-050;
}

.redactedBlock:first-of-type {
  margin-top: $spacer-2;
}
