@import '~@estimateone/frontend-components/src/css/variables';

// For stacking
.productCard {
  + .productCard {
    margin-top: $spacer-3;
  }
}

.contents {
  font: $font-ps-reg;
}

.summaryContainer {
  margin-right: 10em;

  @media screen and (min-width: $breakpoint-lg) {
    margin-right: 18em;
  }
}

.actions {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .infoToggle {
    font: $font-p-heavy;
  }
}

/* ProductCardHeader */
.productCardHeader {
  display: flex;
  justify-content: space-between;

  .productName {
    font: $font-heading-3;
  }

  .pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font: $font-heading-2;
  }

  .pricingDesc {
    font: $font-tiny-reg;
    white-space: nowrap;
  }
}

/* CollapsibleProductInfo */
.divider {
  margin: $spacer-4 0 $spacer-3 0;
  border-style: dashed;
  border-color: $iron-200;
}

/* LicenseCard */
// We need a white link variant for E1Link
.longsellButton {
  button {
    &,
    &:hover {
      background: linear-gradient(to top, $white 1px, transparent 1px);
      color: $white;
    }
  }
}

// Need the `p` to override FEC styles (that was a mistake)
p.licenseDetails {
  color: $iron-700;
  font: $font-ps-reg;

  .creditCard {
    margin-right: $spacer-3;
  }
}
