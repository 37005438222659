@import '~@estimateone/frontend-components/src/css/variables';

.connectionSuggestionsPage {
  display: flex;
  flex-flow: column nowrap;
  gap: $space-32;
}

.pageTitle {
  margin: 0;
  font: $font-heading-3;
}

.emptyState {
  padding: $space-16 $space-32;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: $space-24;
}

.emptyStateTextSection {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: $iron-600;
  font: $font-p-reg;
  gap: $space-16;
}

.emptyStateTitle {
  font: $font-intro;
}

.emptyStateIllustration {
  fill: $iron-100;
}

.suggestionsContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: $space-32;
}

.secondaryTextContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: $space-4;
}

.matchingCompaniesWithButtonContainer {
  display: grid;
  gap: $space-16;
}

.matchingCompaniesContainer {
  display: flex;
  flex-direction: row nowrap;
  gap: $space-16;
}

.companyContainer {
  display: flex;
  flex-basis: 100%;
  flex-flow: column nowrap;
  gap: $space-16;
}

.buttonContainer {
  display: flex;
  justify-content: end;
  gap: $space-16;
}

.alertContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: $space-4;
}

.alertBoldText {
  font: $font-p-heavy;
}

.errorIcon {
  width: $space-16;
  height: $space-16;
  margin-right: $space-4;
  align-items: center;
}
