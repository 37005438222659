@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../css/app/variables';

// changes here should be reflected in css/app/banners.scss (loading state)
.bannerContainer {
  padding: $spacer-3 0;

  &.success {
    background-color: $magic-400;
  }

  &.info {
    background-color: $practical-400;
  }

  &.warning {
    background-color: $energy-400;
  }

  &.error {
    background-color: $west-400;
  }

  &.yellow {
    background-color: $energy-050;
  }
}

.bannerContent {
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 0 $container-y-padding;
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: $spacer-1;
  font: $font-p-heavy;
}

.details {
  margin: 0;
  font: $font-tiny-reg;
}

.buttonContainer {
  min-width: 10.5rem;
  margin-left: auto;
  display: flex;

  a {
    margin-left: $spacer-2;
  }
}

.closeButtonContainer {
  margin-left: $container-y-padding;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($black, 0.15);
    color: $practical-900;
  }
}
