@import '~@estimateone/frontend-components/src/css/variables';

.tradesContainer {
  margin-top: $spacer-2;
}

ul.tradeTagGroupList {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;

  li {
    margin-right: $spacer-2;
    font: $font-ps-reg;

    &:last-child {
      margin-right: 0;
    }
  }
}

.moreTrades {
  margin-top: $spacer-1;
  text-align: right;
}
