@import '~@estimateone/frontend-components/src/css/variables';

.setupBannerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.setupBannerText a {
  font: $font-p-heavy;
}
