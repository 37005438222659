/* Nav Bar and Pills */
/* Primary nav */
.header .tab-menu {
  background: none;

  .nav {
    margin-bottom: 0;

    > li {
      margin: 0 $spacer-5 0 0;
      display: inline-flex;

      &:last-child {
        margin-right: 0;
      }

      &.open {
        .icon-chevron-down {
          color: rgba($white, 0.8);
        }
      }

      &.active {
        &:hover > a {
          color: $magic-500;

          &:first-child {
            border-color: $magic-500;
          }
        }

        &:active > a {
          color: $magic-700;

          &:first-child {
            border-color: $magic-700;
          }
        }

        > a {
          color: $primary-magic-green;

          &:first-child {
            border-bottom-color: $primary-magic-green;
          }
        }
      }

      > a,
      button {
        background: none;
        color: $white;
        font: $font-p-heavy;

        &:active {
          color: $magic-700;
        }

        &:focus {
          color: $primary-magic-green;
        }

        &:hover {
          color: $magic-500;
        }
      }

      > a {
        // Remove 4px for the bottom border
        padding: $spacer-3 0 calc(#{$spacer-3} - 4px) 0;
        border-bottom: 4px solid transparent;
        border-radius: 0;

        &.dropdown-toggle {
          margin-left: $spacer-2;
          cursor: pointer;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}

/* Secondary nav */
.sub-bar {
  margin-bottom: $spacer-5;
  background: $steel;

  .title-container {
    padding: $spacer-5 0;

    &.has-nav {
      padding-bottom: $spacer-3;

      h2 {
        margin-top: $spacer-2;
      }
    }

    &.has-return {
      h2 {
        margin-top: $spacer-1;
      }
    }

    h2 {
      margin: 0;
    }
  }

  .stage-name-container {
    .tender_status {
      > label,
      > div {
        display: inline-block;
      }

      label {
        vertical-align: super;
        font: $font-ps-reg;
      }
    }
  }

  .menu-items {
    @extend %nav;

    > li {
      @extend %nav-item;

      > a {
        @extend %nav-link-inactive;

        &.active {
          @extend %nav-link-active;
        }

        &.has-badge {
          margin-right: 0;
        }

        &.unread-indicator {
          padding: 0;
          vertical-align: super;
          background-color: $primary-magic-green;
        }
      }
    }

    .dropdown-menu {
      margin-top: -$spacer-2;
    }
  }
}

/* Tertiary nav */
.third-level-nav {
  padding: 0;

  ul {
    margin-bottom: $spacer-3;
    border: 1px solid rgba($iron-600, 0.4);
    border-radius: 4px;

    &:after {
      clear: both;
    }

    li {
      margin: 0 $spacer-2;
      padding: $spacer-2;
      list-style: none;
      border-bottom: 1px solid rgba($iron-600, 0.4);

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $iron-600;
        font-size: $font-size-md;

        &:hover {
          opacity: 0.9;
          color: $primary-practical-blue;
          text-decoration: none;
        }

        &.active {
          color: $primary-practical-blue;
          font-weight: $font-medium;

          i {
            opacity: 1;
          }
        }

        i {
          margin-right: $spacer-1;
          opacity: 0.6;
        }
      }
    }
  }
}

/* Nav tabs */
.nav-tabs,
.nav-tabs-sm {
  border-color: $iron-200;

  > li {
    @extend %nav-item;

    &.active {
      > a,
      > button {
        &,
        &:focus,
        &:hover {
          border: none;
          border-bottom: 4px solid $primary-magic-green;
          background: none;
          color: $primary-practical-blue;
        }
      }
    }

    > a,
    > button {
      @extend %nav-link-inactive;

      &:focus,
      &:hover {
        border-color: transparent;
      }
    }
  }
}

.nav-tabs {
  margin-bottom: $spacer-5;
}

.nav-tabs-sm {
  border-bottom: 1px solid $iron-200;

  > li {
    float: left;

    &.active {
      > a {
        &,
        &:focus,
        &:hover {
          color: $primary-practical-blue;
        }
      }
    }

    > a {
      color: $iron-600;
      font: $font-tiny-med;
    }
  }
}

/* Components */
.nav,
.menu-items {
  .beta-badge {
    @extend %beta-badge;
  }

  .badge {
    @extend %nav-badge;
  }

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: $space-4;
    display: inline-block;
    border-radius: 50%;
    background: $primary-magic-green;
  }
}
