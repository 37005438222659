%form-control {
  border: 2px solid $iron-100;
  color: $iron-600;
  font-size: $font-size-md;
}

.form-control {
  @extend %form-control;
  height: $form-default-item-height;

  &,
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    border-color: $primary-practical-blue;
  }

  &:active,
  &:focus {
    border-color: $primary-magic-green;
    color: $primary-practical-blue;
  }

  .form-group-lg & {
    padding: $spacer-5 $spacer-3;
  }
}

textarea.rfq-note-mentions-input__input {
  @extend %form-control;
  padding: $spacer-3;

  &:hover {
    border-color: $primary-practical-blue;
  }

  &:active,
  &:focus {
    border-color: $primary-magic-green;
    color: $primary-practical-blue;
  }
}

div.rfq-note-mentions-input__suggestions {
  ul {
    border: 1px solid $iron-100;
    border-radius: 5px;

    li {
      &:hover {
        background-color: $magic-200;
      }
    }
  }
}

textarea.project-note-mentions-input__input {
  @extend %form-control;
  padding: $spacer-3;

  &:hover {
    border-color: $primary-practical-blue;
  }

  &:active,
  &:focus {
    border-color: $primary-magic-green;
    color: $primary-practical-blue;
  }
}

div.project-note-mentions-input__suggestions {
  ul {
    border: 1px solid $iron-100;
    border-radius: 5px;

    li {
      &:hover {
        background-color: $magic-200;
      }
    }
  }
}

textarea.form-control {
  padding: $spacer-3;
}

.form-group {
  .radio {
    display: inline;
  }

  .radio-list {
    .radio {
      display: block;

      .help-block {
        margin-top: 0;
        margin-bottom: 0;
        color: $iron-600;
      }
    }
  }
}

.help-block {
  color: $iron-600;

  .global-error & {
    margin-bottom: $spacer-4;
  }

  .has-error & {
    color: $color-error;
  }
}

.has-error {
  .control-label,
  .radio {
    color: $color-error;
  }

  .form-control {
    border-color: $color-error;
  }
}

.input-group-addon {
  @extend %form-control;
}

.form-group-expiry-date {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-align: center;

  .short-date-field {
    text-align: center;
  }

  .date-separator {
    margin: 0 $spacer-3;
  }
}

/* Bootstrap multiselect list */
.multiselect-filter-container {
  .btn-group {
    width: 100%;

    .dropdown-toggle {
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      &,
      &:active,
      &:focus,
      &:hover {
        @extend %form-control;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: $white;
      }

      .caret {
        display: contents;

        &::before {
          @extend .icon;
          @extend .icon-xxs;
          @extend .icon-chevron-down;
          margin-left: $spacer-2;
        }
      }
    }
  }

  .multiselect-container {
    width: 100%;

    li {
      &.active {
        a {
          padding-left: $spacer-3;
          background-color: $magic-050;

          .checkbox {
            margin-left: 0;
            color: $primary-practical-blue;
            font: $font-ps-med;

            &::before {
              @extend .icon;
              @extend .icon-check-mini;
              margin-right: $spacer-2;
              font-size: 0.6rem;
            }
          }
        }
      }

      a {
        padding: $spacer-2 calc(#{$spacer-3} + #{$spacer-2} + 0.6rem);

        &:hover {
          color: $primary-practical-blue;
        }

        .checkbox {
          margin-left: $spacer-2;
          padding: 0;
          color: $iron-600;
          font: $font-ps-reg;

          input {
            display: none;
          }
        }
      }
    }
  }
}

/* SELECT2 */
select.select2 {
  @extend .form-control;

  .select2-small & {
    // Same style as .select2-small .select2-selection__rendered;
    height: 2rem;
    color: $iron-600;
    font: $font-ps-reg;
  }
}

.select2-container,
.select2-container--bootstrap {
  .select2-selection,
  .select2-dropdown,
  &.select2-container--open .select2-selection,
  &.select2-container--focus .select2-selection {
    @extend %form-control;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .select2-selection {
    height: $form-default-item-height;

    &.select2-selection--multiple {
      height: auto;

      .form-group-lg & {
        padding: $spacer-2 $spacer-1;
      }
    }
  }

  .select2-selection--single,
  .select2-selection--multiple {
    .select2-selection__rendered {
      color: $iron-600;
      font: $font-p-reg;
    }
  }

  .select2-results__option {
    color: $iron-600;
    font: $font-ps-reg;

    &,
    .select2-results__option {
      padding: $spacer-2 calc(#{$spacer-3} + #{$spacer-2} + 0.6rem);
    }

    &--highlighted[aria-selected] {
      background-color: $magic-050;
      color: $primary-practical-blue;
    }

    &[aria-selected='true'] {
      padding-left: $spacer-3;
      background-color: $white;
      color: $primary-practical-blue;
      font: $font-ps-med;

      &::before {
        @extend .icon;
        @extend %icon-check-mini-before;
        margin-right: $spacer-2;
        font-size: 0.6rem;
      }
    }

    .select2-results__group {
      color: $iron-600;
      font: $font-ps-med;
      text-transform: uppercase;
    }

    a {
      color: $primary-practical-blue;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  .select2-selection--multiple {
    .select2-search--inline {
      .select2-search__field {
        width: 100% !important;
        height: $form-default-item-height;
      }
    }

    .select2-selection__choice {
      &,
      .form-group-lg & {
        margin: $spacer-1 0 $spacer-1 $spacer-2;
        padding: $spacer-1 $spacer-3;
        border: 2px solid $primary-magic-green;
        border-radius: 24px;
        color: $primary-practical-blue;

        .select2-selection__choice__remove {
          float: right;
          margin: 0 0 0 $spacer-1;
          color: $primary-practical-blue;
        }
      }
    }
  }
}

.select2-container {
  max-width: 100%;
}

form[name='tender_stage_form'],
form[name='construction_stage_form'] {
  .select2-container {
    min-width: 100%;
  }
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
  */
  box-sizing: border-box;
}

.select2-small {
  margin: 0 $spacer-2;

  .select2-selection {
    height: unset;
  }

  .select2-selection--single {
    .select2-selection__rendered {
      color: $iron-600;
      font: $font-ps-reg;
    }
  }
}

.select2-select-all {
  @extend .small;
  @extend %a-link;
  @extend %a-secondary;

  &:before {
    content: 'Select All';
  }

  &.selected {
    &:before {
      content: 'Clear All';
    }
  }
}

.react-multi-select-container {
  border: none;
}

.react-multi-select-container .react-select__multi-value {
  margin: $spacer-1;
  padding: $spacer-1;
  border: 2px solid $primary-magic-green;
  border-radius: 24px;
  background-color: transparent;
  color: $primary-practical-blue;

  &__label {
    margin-left: $spacer-2;
    padding: 0;
  }

  &__remove {
    margin-right: $spacer-2;
    padding: 0;

    &,
    &:hover {
      background-color: transparent;
    }
  }
}

.react-multi-select-container .react-select__control,
.react-select-container .react-select__control {
  @extend %form-control;
  border-radius: 0;

  &:hover {
    border-color: $practical-900;

    .react-select__indicators .react-select__indicator {
      &,
      &-separator {
        color: $practical-900;
      }
    }
  }

  &.react-select__control--is-focused {
    border-color: $primary-magic-green;
    box-shadow: none;

    .react-select__indicators .react-select__indicator {
      &,
      &-separator {
        color: $primary-magic-green;
      }
    }
  }
}

.react-multi-select-container .react-select__option,
.react-select-container .react-select__option {
  &:active {
    background-color: $magic-600;
  }

  &--is-focused {
    background-color: $magic-300;
  }

  &--is-selected {
    background-color: $magic-700;
  }
}

select[multiple].select2 {
  opacity: 0;
}

.search-document {
  width: 320px;

  .form-control {
    height: 48px;
  }
}

.searchbar {
  position: relative;

  &.with-label {
    .icon {
      top: calc((50% + 1em) - (#{$font-size-sm} / 2));
    }
  }

  input {
    padding: $spacer-1 $spacer-3;
    font-size: $font-size-md;
    text-indent: $spacer-3;
  }

  .icon {
    position: absolute;
    top: calc(50% - (#{$font-size-sm} / 2));
    left: $font-size-sm;
    font-size: $font-size-sm;
  }
}

.searchbar-lg {
  position: relative;

  input {
    height: unset;

    // Make padding equivalent to btn-lg (which uses $font-size-md)
    // calc({btn-lg-padding-y} - ({searchbar-lg-font-size} - {btn-lg-font-size} / 2))
    padding: calc(#{$spacer-3} - ((#{$font-size-lg} - #{$font-size-md}) / 2)) $spacer-3;
    font-size: $font-size-lg;
    text-indent: $spacer-5;
  }

  .icon {
    position: absolute;
    top: calc(50% - (#{$font-size-lg} / 2));
    left: $font-size-lg;
    font-size: $font-size-lg;
  }
}

.form-dark-bg {
  label {
    color: $iron-050;
  }
}

.reactSelectContainer {
  font: $font-p-reg;

  &.fullWidthOptions {
    .reactSelect__single-value {
      width: calc(100% - #{$spacer-4});
    }
  }

  &.reactSelectContainerError {
    .reactSelect__control {
      border-color: $color-error;
    }
  }

  .reactSelect__control {
    padding: calc(#{$spacer-2} + 1px);
    border: 2px solid $iron-100;
    border-radius: 4px;
    box-shadow: none;

    &.reactSelect__control--is-focused {
      border-color: $primary-practical-blue;
    }

    &.reactSelect__control--menu-is-open {
      border-color: $primary-magic-green;
    }
  }

  .reactSelect__indicator-separator {
    display: none;
  }

  .reactSelect__placeholder,
  .reactSelect__indicator {
    color: $primary-practical-blue;
  }

  .reactSelect__option--is-focused {
    background-color: $magic-050;
  }

  .reactSelect__option--is-selected {
    background-color: $primary-magic-green;
  }
}

.reactSelect--is-disabled {
  .reactSelect__placeholder,
  .reactSelect__indicator {
    color: $iron-300;
  }
}
