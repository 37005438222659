@import '~@estimateone/frontend-components/src/css/variables';

.alertWrapper {
  margin-bottom: $space-32;
}

.alert {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: $space-8 $space-16;

  p {
    margin: 0;
  }
}
