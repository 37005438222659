@import '~@estimateone/frontend-components/src/css/variables';

.errors {
  list-style: none;

  li {
    margin-top: $spacer-2;

    &:first-child {
      margin-top: 0;
    }
  }
}
