@import '~@estimateone/frontend-components/src/css/variables';

.noTradesSelected {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: $space-24;

  .illustration {
    stroke: none;
    fill: $iron-100;
  }

  .text {
    color: $iron-600;
    font: $font-intro;
  }
}
