@import '~@estimateone/frontend-components/src/css/variables';

// changes here should be reflected in the Banner component
.banner-loading {
  // x-padding + font-md lineheight + p padding + font-tiny lineheight
  height: calc(#{$spacer-3 * 2} + #{$spacer-4} + #{$spacer-1} + #{$spacer-3});

  &.banner-success {
    background-color: $magic-400;
  }

  &.banner-info {
    background-color: $practical-400;
  }

  &.banner-warning {
    background-color: $energy-400;
  }

  &.banner-error {
    background-color: $west-400;
  }

  &.banner-grey {
    background-color: $iron-050;
  }
}
