@import '~@estimateone/frontend-components/src/css/variables';

.modalHeader {
  margin-top: 0;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.modalContent {
  margin-bottom: $spacer-5;
  font: $font-ps-reg;
}

.projectNoteFooter {
  margin-top: 0.5rem;
  display: grid;
  align-items: baseline;
  grid-template-columns: fit-content(100%) auto fit-content(100%);
}
