.user-correspondence-mail-form {
  .ql-toolbar,
  .quill-editor {
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

// Mostly making text inside editor approximate default chrome user-agent stylesheet
.quill-editor.ql-container {
  min-height: 150px;
  margin-bottom: 20px;
  color: $tundora !important;
  font-family: 'Lucida Grande', 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 14px !important;

  h1,
  h2 {
    font-weight: bold;
  }

  p,
  ul,
  ol,
  .ql-editor.ql-blank::before {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h1 {
    margin-top: 0.65em;
    margin-bottom: 0.65em;
    font-size: 2em;
  }

  h2 {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 1.5em;
  }

  li:before,
  ol:before {
    text-align: center;
  }

  p,
  ol,
  ul {
    color: $tundora !important;
    font-size: 14px !important;
    line-height: unset;
  }

  li {
    margin: 0;
  }

  strong {
    font-weight: bold;
  }
}

// Overriding the quill 'snow' theme toolbar
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: 'Large Heading';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: 'Heading';
}

.ql-formats {
  .ql-header.ql-picker {
    width: 130px;
  }
}
