@import '~@estimateone/frontend-components/src/css/variables';

.detailsText {
  margin-bottom: $space-24;
  color: $primary-practical-blue;
  font: $font-ps-reg;
}

.documentsList {
  margin-left: $space-24;
}

.projectLabel {
  font: $font-ps-med;
}

.projectDescription {
  font: $font-ps-reg;
}

.privateFileNote {
  display: flex;
  align-items: center;
  font: $font-ps-heavy;
  gap: $space-4;
}

.privateFileNoteIcon rect,
.privateFileNoteIcon path {
  stroke: $primary-practical-blue;
}

.detailsCard {
  :last-child {
    grid-column-start: span 2;
  }
}
