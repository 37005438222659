%btn-primary {
  &,
  &:active {
    border-color: transparent;
    background-color: $primary-magic-green;
    color: $primary-practical-blue;
  }

  &:focus,
  &:hover {
    background-color: $magic-500;
    color: $primary-practical-blue;
  }

  &:focus {
    border-color: $primary-practical-blue;
  }
}
