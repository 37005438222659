@import '~@estimateone/frontend-components/src/css/variables';

.phaseDetailsSection {
  margin-top: $space-48;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-24 $space-32;

  h2 {
    grid-column: span 2;
  }
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  :global(.react-datepicker-wrapper) {
    display: block;
  }
}
