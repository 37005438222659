@import '~@estimateone/frontend-components/src/css/variables';

$headerHeight: 238px;

.container {
  margin-top: $space-12;
  padding: 0 $spacer-5 $spacer-5;

  table {
    tr {
      &:hover {
        background-color: $magic-050;
      }
    }

    td {
      width: 120px;

      &:first-child {
        width: fit-content;
        max-width: 0;
        overflow: hidden;
      }
    }
  }
}

.roadblockModalUnderlay {
  position: fixed;
  z-index: 1000;
  top: $headerHeight;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: $white;
  pointer-events: none;
}

.roadblockModalContainer {
  position: fixed;
  z-index: 1000;
  top: $headerHeight;
  width: 100%;
  height: 100%;
  display: flex;

  .roadblockModal {
    position: relative;
    box-sizing: border-box;
    width: 600px;
    margin: 10% auto auto;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $iron-050;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    background-color: $white;
    text-align: center;

    .roadblockCloseButton {
      position: absolute;
      top: $space-16;
      right: $space-16;
    }

    .roadblockModalTitle {
      h3 {
        width: 414px;
        margin-top: 0;
        margin-bottom: $space-32;
      }
    }

    .roadblockModalDescription {
      margin-bottom: $space-32;
    }

    .unlockButtonContainer {
      width: 100%;
    }
  }
}

.alertContainer {
  margin-bottom: $space-12;
}

.lockedCardContainer {
  margin-top: $space-16;
  margin-bottom: $space-16;
  padding: $space-24;
  display: flex;
  flex-direction: column;
  border: 2px solid $iron-100;
  border-radius: $space-8;
  background-color: unset;

  .lockedCardContainerRow {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    justify-content: space-between;
  }

  .textContainer {
    display: flex;
    flex-direction: column;

    .header {
      font: $font-p-heavy;
    }

    .text {
      font: $font-ps-reg;
    }
  }

  .buttonContainer {
    display: flex;

    button,
    a {
      white-space: nowrap;

      + button,
      + a {
        margin-left: $spacer-2;
      }
    }
  }
}

.subbieName {
  max-width: 100%;
  margin-bottom: $space-8;
  overflow: hidden;
  font: $font-p-med;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tradeContainer {
  min-height: $space-24;
  display: flex;

  > * {
    margin-right: $space-4;
  }
}

.dateTimeCell {
  font: $font-ps-reg;
}

.emptySubbieListCard {
  padding: $space-24 0;
  border-bottom: 1px solid $iron-100;
  font: $font-p-reg;
  text-align: center;

  button {
    font: $font-p-reg;
  }
}
