@import '~@estimateone/frontend-components/src/css/variables';

body.email-splash {
  background: $white;

  .wrapper {
    max-width: 750px;
    margin-top: $space-80;
  }

  .e1-logo {
    width: 52px;
  }

  .heading-container {
    text-align: center;

    h1 {
      font: $font-heading-1;
    }

    h2 {
      font: $font-intro;
    }
  }
}

body.fs-splash {
  padding-top: 7rem;
  background: $steel;
  text-align: center;

  .e1-logo {
    width: 52px;
  }

  .img-welcome {
    width: 770px;
    max-width: 100%;
    margin: $spacer-5 0;
  }

  .splash {
    margin: $spacer-5 0;

    p.auth-sso {
      color: $practical-900;
    }

    h1 {
      color: $primary-magic-green;
    }

    .link,
    .btn-forgot-password {
      background: none;
      color: $primary-magic-green;
      font-weight: $font-semibold;

      &:hover {
        text-decoration: underline;
      }
    }

    .magic-link {
      color: $primary-magic-green;
      font-weight: $font-regular;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .magic-link > span {
      color: $primary-practical-blue;
    }

    .btn-forgot-password {
      .loading-indicator {
        margin-right: $spacer-1;
      }
    }

    .heading-text {
      margin-bottom: $spacer-4;
      color: $primary-practical-blue;

      .heading-email {
        color: $practical-400;
        font-weight: $font-medium;
      }

      .switch-account-trigger {
        color: $iron-400;
        font: $font-tiny-heavy;
      }
    }

    .form-container {
      padding: $spacer-5;
      border-radius: 8px;
      background-color: white;
      color: $iron-600;

      .form-group {
        text-align: left;
      }

      .form-control {
        height: unset;
        padding: $spacer-3;
      }

      .back-container {
        text-align: left;
      }

      .forgot-password-container {
        margin-top: $spacer-3;
      }
    }

    ul.account-list {
      list-style: none;

      li {
        margin-top: $spacer-3;

        .link {
          color: $primary-link-blue;
        }

        .location {
          color: $practical-400;
          font-weight: $font-medium;
        }
      }
    }

    ul.account-admins {
      display: inline-block;
      list-style: none;

      li {
        padding: $spacer-3 $spacer-5;
        border-bottom: 1px solid $iron-200;
        font-size: $font-size-lg;
        font-weight: $font-semibold;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .splash-footer {
      margin-top: $spacer-5;
    }
  }

  .auth-header {
    margin: 0;
  }

  .auth-container-form {
    .btn-block {
      margin-top: $spacer-5;
    }
  }
}

body.error-splash {
  padding-top: 0;
  display: flex;
  align-items: center;
  background-color: $crimson-050;
  text-align: center;

  .btn-transparent {
    border: 2px solid $practical-900;

    &:hover {
      background-color: rgba($white, 0.8);
    }
  }
}

body.error-splash-quote-deleted {
  background-color: $brick;

  .error-content {
    max-width: 560px;
    margin: 0 auto;
    padding-top: 80px;
    text-align: left;

    div {
      &.quote-deleted-image-container {
        margin-bottom: 1rem;
        text-align: center;

        h1 {
          position: absolute;
          color: $crimson-500;
          font-size: 4em;
          font-weight: bolder;
          text-transform: uppercase;
          white-space: nowrap;
        }

        img {
          position: relative;
          z-index: -1;
          left: -30px;
        }
      }

      &.manage-quotes-cta {
        margin-top: 2rem;
        padding: 0.3rem 0.5rem;
        box-shadow: 0 0 18px rgb(0 0 0 / 12%);

        a {
          float: right;
          margin-top: 2px;
        }
      }
    }

    p {
      margin: 0;

      &.deleted-message {
        font-size: 1.2rem;
      }

      &.more-info {
        margin-top: 1rem;
        font-weight: bold;
      }
    }
  }
}

body.admin-splash {
  background-color: $primary-practical-blue;
}

body.error-splash-legacy {
  color: $tundora;
  font-family: $default-font-family;

  form {
    margin-bottom: 10px;
  }

  .error-msg {
    margin: 5px 0 15px;
    padding: 8px;
    border-color: $alto;
    border-radius: 2px;
    background-color: $mandy;
    color: $white;
  }

  .splash {
    width: 420px;
    margin: 180px auto 0;
    text-align: center;

    h2 {
      margin: 0 0 30px;
      color: $white;
      font-size: 29px;
      font-weight: normal;
      text-align: center;
      -webkit-font-smoothing: antialiased;
    }

    p,
    label {
      color: $spindle;

      a {
        color: $spindle;
        font-weight: bold;
      }
    }

    p.lead {
      margin-bottom: 45px;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.8em;
      text-align: center;
    }

    .logo-wb-medium {
      width: 24px;
      height: 22px;
      margin: 0 auto 40px;
      display: block;
      opacity: 0.3;
      background: url('//media.prod.shared.estimateone.com/2024/logos/e1logo-white-45x48.png') no-repeat transparent;
    }

    .form-group {
      width: 100%;
      text-align: left;

      input:not([type='checkbox']) {
        width: 100%;
      }
    }

    form {
      text-align: left;

      .form-group {
        position: relative;

        &.has-error {
          clear: both;

          ~ a.manualaddress-trigger {
            margin-top: 15px;
          }
        }
      }

      button {
        margin-top: 30px !important;
        margin-right: auto;
        margin-left: auto;
        padding: 10px 30px;
        display: block;
      }

      .has-error input.form-control {
        border: solid 2px $chestnut-rose;
        border-radius: 3px;
      }

      .manualaddress-trigger {
        margin-bottom: 0;
        color: $spindle;
      }

      .error-msg {
        position: absolute;
        right: 0;
        margin: 5px 0;
        padding: 2px 5px;
        border-radius: 3px;
        background-color: $chestnut-rose;

        .help-block {
          margin: 0;
          color: $white;
          line-height: 1.42;
          text-align: center;
        }
      }
    }
  }
}

.ie-legacy-support {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  border-top: 1px solid $heath;
  background: $stiletto;
  color: $white;
  font-weight: bold;

  .container {
    width: 960px;
    margin: 0 auto;
    padding: 15px 0;
    font-size: 13px;

    .col-first {
      float: left;
      width: 10%;
      text-align: left;
    }

    .col-second {
      float: left;
      width: 65%;
      text-align: left;
    }

    .col-third {
      float: left;
      width: 25%;
      text-align: right;

      &:after {
        clear: both;
      }
    }

    a.btn {
      font-weight: bold;
    }

    i.icon {
      font-size: 30px;
    }
  }
}

.otp-splash {
  .form-container {
    h1.h4 {
      font-size: 19px;
      font-weight: 700;
    }

    p {
      color: $primary-practical-blue;
    }

    .form-group {
      margin-bottom: 0;
    }

    form[name='attempt_otp_challenge'] {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .opt-code {
        padding: 0.5rem !important;
        color: $primary-practical-blue;
        font-size: 32px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 5px;
        text-align: center;
      }
    }

    .btn-otp-select {
      background-color: $white;
      color: $primary-practical-blue;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.otp-contact-details {
  padding: 0 !important;

  hr {
    margin: 32px 0;
    border-top: 1px solid $iron-300;
  }
}

.link-default,
.link-default:hover,
.link-default:visited,
.link-default:active {
  color: $primary-practical-blue !important;
  text-decoration: underline !important;
}

.profile-onboarding {
  color: $primary-practical-blue;

  div[class^='col-xs-'],
  div[class^='col-sm-'],
  div[class^='col-md-'],
  div[class^='col-lg-'] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
