@import '~@estimateone/frontend-components/src/css/variables';

.supplierCount {
  display: flex;
  font: $font-p-med;

  & svg {
    max-width: $space-16;
    max-height: $space-16;
    margin-top: $space-4;
    margin-left: $space-8;
  }
}

.optedOutLink {
  margin-top: -$space-8;
}

.projectSuppliersEmpty {
  margin-bottom: 0;
  padding: $space-24 0;
  border-top: 1px solid $iron-200;
  border-bottom: 1px solid $iron-200;
  line-height: $space-24;
  text-align: center;
}

.supplierList {
  margin-bottom: $space-8;
  display: flex;
  flex-direction: column;
  gap: $space-12;
}
