@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeader {
  background: $primary-practical-blue;
  color: $white;

  .projectSliderHeader {
    padding: $space-32;

    h2 {
      margin: $space-4 0;
    }
  }
}
