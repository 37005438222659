.invite_note,
.tender_note {
  cursor: pointer;
  color: $primary-practical-blue;
  font-size: $font-size-lg;
  white-space: nowrap;

  &:hover {
    color: $primary-practical-blue;
    text-decoration: none;
  }
}

.invite_note {
  &:after {
    @extend .corner-badge;
    content: attr(data-note-count);
  }

  &[data-note-count='0'] {
    color: $iron-200;

    &:after {
      content: '+';
      width: 1rem;
      padding: 0 0 0 0.1em;
      background-color: $iron-200;
      font-size: 0.65rem;
      line-height: 0.75rem;
    }
  }
}

.icons .rel-status {
  margin: 1px;
}

.tender-table {
  width: 100%;
}

.tender-table-header {
  display: flex;
  justify-content: space-between;
}

.current-tender-table .dataTables_empty,
.closed-tender-table .dataTables_empty,
.are-you-tendering-table .dataTables_empty {
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
}

.btn-group.badge_stage_status_switch.disabled .btn.active {
  border-color: $iron-100;
  background-color: $iron-100;
  color: $iron-600;
}

.btn-group.badge_stage_status_switch.disabled .btn {
  cursor: default;
  pointer-events: none;
}

span.date {
  input.hidden_datetime {
    border: none;
    background: none;
  }
}

.project-options {
  margin-top: 5px;
}

.uploading-file-ctn {
  display: none;
}

.stage_change_details {
  div {
    padding: 8px 15px;
    border-top: 1px solid $alto;
    border-bottom: 1px solid $alto;
    background: $gallery;
  }

  label {
    margin-right: 5px;
    font-size: 13px;
    font-weight: normal;
  }

  .form-control {
    min-width: 120px;
    display: inline;
    font-size: $default-font-size + 1;
  }

  .hidden_datetime {
    width: 125px;
    background: none;
    color: $medium-carmine;
    font-size: $default-font-size + 2;
  }

  .change_date {
    font-size: $default-font-size - 2;
    line-height: 18px;
    text-decoration: underline;
  }
}

.stage-invite {
  .status-header {
    width: 168px;
  }

  .response-dropdown {
    position: relative;
    float: right;

    .subtitle {
      padding: 3px 15px;
      color: $silver-chalice;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.e1-download-link-copy {
  #download-link {
    width: 500px;
  }
}

.trade-select {
  .select_group {
    margin-bottom: 25px;
  }

  .checkbox {
    margin: 4px 0;
  }

  .col-sm-3 {
    padding-left: 0;
  }
}

.mark-as,
.undo {
  padding: 3px 15px;
  color: $silver-chalice;
  font-weight: bold;
}

table.package-requests-table {
  .package-request-change,
  .package-request-decision {
    min-width: 142px;
  }

  td.decision {
    color: $silver-chalice;
  }
}

.inline-tooltip-right {
  &.right {
    margin-left: 14px !important;
  }

  &.in {
    opacity: 1;
  }

  .tooltip-inner {
    box-shadow: 0 3px 10px 0 rgb(52 63 79 / 30%);
    background-color: $shuttle-gray;
    font-size: 0.8em;
  }

  .tooltip-arrow {
    border-right-color: $shuttle-gray;
  }
}

.stage-monitor-dataTable {
  &:not(.notes-visible) {
    p.last-note {
      display: none;
    }
  }

  .p-link {
    $max-lines: 4;

    .last-note {
      max-width: 225px;
      max-height: 1.2em * $max-lines;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      overflow: hidden;
      font-size: 10px;
      line-height: 1.2em;
    }

    .default-note {
      color: $silver;
    }

    p.note-updated-relative {
      height: 12px;
      margin-top: 2px;
      color: $bali-hai;
      font-size: 10px;
    }
  }

  .tooltip {
    @extend .inline-tooltip-right;
  }
}

.stage-requests {
  .request-action {
    width: 200px;
  }

  .package-request-change {
    width: 180px;
    font-size: 12px;
  }

  #package-requests-dataTable {
    thead {
      tr {
        > th:nth-child(1) {
          width: 27%;
        }

        > th:nth-child(2) {
          width: 18%;
        }

        > th:nth-child(3) {
          width: 15%;
        }

        > th:nth-child(4) {
          width: 30%;
        }

        > td:nth-child(5) {
          width: 10%;
        }
      }
    }

    span.request-icon {
      margin-right: 3px;
      padding: 3px 4px;
      border-radius: 4px;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;

      &.e1-request {
        background: $boston-blue;
        color: $botticelli;

        &:before {
          content: 'E1';
        }
      }

      &.itp-request {
        background: $alto;
        color: $boulder;

        &:before {
          content: 'ITP';
        }
      }
    }
  }
}

.resend-addenda {
  display: none;
}

.generate-packages-modal {
  width: 750px;

  .modal-body {
    max-height: 500px !important;
  }

  .checkbox label {
    padding-left: 5px;
  }
}

.view-changes-modal {
  .modal-body {
    max-height: 600px !important;
  }
}

a.project-link {
  color: $astral;
  font-size: 13px;
}

.obsolete-warning {
  padding: 25px 15px;
  border: 1px solid $medium-carmine;
  background: $iron-100;
  color: $heath;
  font-size: 14px;

  h2 {
    color: $heath;
    font-family: 'lucida grande', 'Arial', sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
}

span.invitation-error {
  float: left;
  padding-top: 4px;
  padding-right: 5px;
  cursor: pointer;
  color: $medium-carmine;
  font-size: 22px;
}

.tenders-table-flag-wrapper {
  white-space: nowrap;

  div {
    margin-right: $spacer-2;
    display: inline-block;
  }

  .builder-code-icon-container {
    span {
      cursor: pointer;
      vertical-align: text-bottom;
    }
  }
}

.fixed-price-quote:has(input[type='hidden']) {
  display: none;
}
