@import '~@estimateone/frontend-components/src/css/variables';

.tagsHeader {
  margin-top: $space-8;
  margin-bottom: $space-4;
  font: $font-tiny-heavy;
}

.tag {
  margin-right: $space-4;
  margin-bottom: $space-4;
}

.tagNotProvided {
  color: $iron-600;
  font: $font-ps-reg;
}
