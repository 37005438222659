@import '~@estimateone/frontend-components/src/css/variables';

.categoriesContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tagNotProvided {
  color: $iron-600;
  font: $font-ps-reg;
}
