@import '~@estimateone/frontend-components/src/css/variables';

.contactPersonSection {
  margin-top: $space-48;
  display: grid;
  grid-template: 'heading heading' auto
    'name name' auto
    'email email' auto
    'phone .' auto / 1fr 1fr;
  gap: $space-24 $space-32;
}

.headingSection {
  display: flex;
  align-items: center;
  grid-area: heading;
}

.tooltip {
  margin-left: $space-8;
}

.name {
  grid-area: name;
}

.email {
  grid-area: email;
}

.phone {
  grid-area: phone;
}
