@import '~@estimateone/frontend-components/src/css/variables';

.tickText {
  font: $font-ps-reg;
}

.tickTextQuarter {
  font: $font-ps-reg;
}

.tickTextYear {
  font: $font-tiny-med;
}

.rightTickText {
  color: $iron-600;
}
