@import '~@estimateone/frontend-components/src/css/variables';

.headerIcon {
  display: flex;
  justify-content: center;

  svg {
    stroke: none;
    fill: $practical-500;
  }
}

.headerText {
  margin-top: $space-16;
  display: flex;
  justify-content: center;
  font: $font-heading-3;
}

.body {
  margin-bottom: $space-16;
  font: $font-p-reg;
}

.content {
  margin-bottom: $space-24;
}

.highlightText {
  font: $font-p-heavy;
}
