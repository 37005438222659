@import '~@estimateone/frontend-components/src/css/variables';

.modalButtonOptions {
  width: 100%;
  display: grid;
  column-gap: $space-12;
  grid-template-columns: 1fr 1fr;
}

.modalSection {
  display: flex;
  flex-flow: column nowrap;
  color: $primary-practical-blue;
  gap: $space-32;

  h1 {
    margin: 0;
    font: $font-heading-3;
  }

  .modalBody {
    display: flex;
    flex-flow: column nowrap;
    gap: $space-16;
  }
}
