@import 'mixins/buttons';
@import 'mixins/nav';
@import 'mixins/tables';

@mixin section-header {
  margin: $spacer-5 0 $spacer-3;

  h3 {
    margin: 0;
    align-self: center;
  }
}

@mixin link-style($color, $hover-text) {
  background: linear-gradient(to top, $color 1px, transparent 1px);
  color: $color;

  &:active,
  &:focus,
  &:hover {
    color: $hover-text;
  }
}

@mixin date-style($color, $font-weight: $font-semibold) {
  color: $color;
  font-weight: $font-weight;
  white-space: nowrap;
}

@mixin date-display(
  $base-color: $primary-link-blue,
  $secondary-color: $practical-050,
  $m-text-color: $white
) {
  width: 4rem;
  height: 4rem;
  border-radius: 6px;
  background: $secondary-color;
  text-align: center;

  .month {
    padding: $spacer-1 0;
    border-radius: 6px 6px 0 0;
    background: $base-color;
    color: $m-text-color;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .day {
    color: $base-color;
    font-size: $font-size-lg;

    // Display box height - month line-height - month padding-y
    line-height: calc(4rem - 1rem - (#{$spacer-1} * 2));
  }
}

@mixin gradient-vertical(
  $start-color: #555,
  $end-color: #333,
  $start-percent: 0%,
  $end-percent: 100%
) {
  background-image: linear-gradient(
    to bottom,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

/** SLIDER **/
@mixin slider-header-nav-tabs {
  margin: $spacer-3 0 0 0;
  border: none;

  > li {
    margin-bottom: 0;
    /* stylelint-disable selector-pseudo-class-disallowed-list */
    &:global(.active) {
      > a {
        border: none;
        border-bottom: 4px solid $primary-magic-green;
        background: none;
        color: $primary-magic-green;
      }
    }

    > a {
      padding: $spacer-2 0;
      border: none;
      border-bottom: 4px solid transparent;
      color: $white;
      font: $font-p-heavy;

      &:focus,
      &:hover {
        background: none;
        color: $primary-magic-green;
      }
    }
  }
}

@mixin slider-body-nav-tabs {
  margin-bottom: 0;

  > li {
    &:global(.active) {
      > a {
        border-bottom: 4px solid $primary-magic-green;
        color: $primary-practical-blue;
      }
    }

    > a {
      border-color: transparent;
      border-top: none;
      border-bottom: 4px solid transparent;

      &:focus,
      &:hover {
        border-color: transparent;
        background: none;
        color: $primary-practical-blue;
      }
    }
  }
}

/*
## CSS ARROWS
Mixin to give css arrows (with given properties) on elements. (based on http://cssarrowplease.com/)
*/
@mixin arrow-top($size, $color, $border-width, $border-color, $offset) {
  border: $border-width solid $border-color;
  background: $color;

  &:after,
  &:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
  }

  &:after {
    bottom: calc(100% - 1px);
    left: 50% - $offset;
    margin-left: 0 - $size;
    border-width: $size;
    border-bottom-color: $color;
  }

  &:before {
    left: 50% - $offset;
    margin-left: 0 - ($size + round(1.4142 * $border-width));
    border-width: $size + round(1.4142 * $border-width);
    border-bottom-color: $border-color;
  }
}

@mixin icon-bar-inner-wrapper($font-size, $icon-size) {
  box-sizing: border-box;
  margin: 0 auto;
  display: table;
  font-size: $font-size;

  .icon {
    padding-right: $spacer-2;
    display: table-cell;
    font-size: $icon-size;
  }

  p {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  a {
    color: $white;
    font-weight: 700;
    text-decoration: underline;
  }
}
