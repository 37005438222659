@import '~@estimateone/frontend-components/src/css/variables';

/*
 * We've actually been using one level lower than the variables everywhere
 * in terms of rendering as h1 was deemed too large.
 * These variables are not a mistake.
 */

.heading1 {
  font: $font-heading-2;
}

.heading2 {
  font: $font-heading-3;
}

.heading3 {
  font: $font-intro;
}

.heading4 {
  font: $font-p-heavy;
}

.heading5 {
  font: $font-ps-heavy;
}

.heading6 {
  font: $font-tiny-heavy;
}
