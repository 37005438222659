@import '~@estimateone/frontend-components/src/css/variables';

.button {
  padding: 0;
  cursor: pointer;
  border: none;
  outline: inherit;
  background: none;
  color: inherit;
  font: inherit;
}
