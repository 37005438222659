@import '~@estimateone/frontend-components/src/css/variables';

.summaryContainer {
  margin-bottom: $spacer-3;
  padding-top: $spacer-4;
  background-color: $iron-100;
}

.footerContainer {
  color: $iron-600;
  font: $font-ps-heavy;
}

.summaryRowTitle {
  font-weight: $font-semibold;
}

.tradesList {
  display: flex;
  flex-wrap: wrap;

  & > span {
    margin-right: $spacer-2;
    margin-bottom: $spacer-2;
  }
}
