@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeader {
  background: $primary-practical-blue;
  color: $white;

  .leftIcon {
    font: $font-ps-heavy;
  }

  .sliderBackButton {
    color: $white;
  }

  .backButton {
    padding-top: $space-24;
    padding-left: $space-32;
  }

  .projectSliderHeader {
    padding: $space-32;
  }

  > hr {
    margin: 0;
  }

  .projectId {
    opacity: 0.6;
    font: $font-ps-heavy;
  }

  .printBtn {
    padding: 0;
    background: none;
    color: $white;

    &:hover {
      background: inherit;
    }
  }

  h2 {
    margin: $space-4 0;
  }

  .viewMap {
    margin-left: $space-16;
    color: $white;
  }

  .watchlistSelector {
    color: $primary-practical-blue;
  }

  .subHeader {
    padding: $space-24;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $steel;
    color: $primary-practical-blue;

    h2 {
      margin: $space-4 0;
    }

    span {
      font-size: $font-size-sm;
    }
  }
}
