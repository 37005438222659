@import '~@estimateone/frontend-components/src/css/variables';

.documentsContainer {
  margin-top: $space-24;
  display: flex;
  flex-direction: column;

  .procoreFolder {
    margin-bottom: $space-24;
  }
}

.labelHeavy {
  font: $font-p-heavy;
}

.labelLight {
  font: $font-ps-reg;
}

.documentItemList {
  list-style-type: none;
}

.documentItem {
  margin-top: $space-12;
  margin-left: $space-32;
}

.documentLabel {
  display: flex;
  color: $primary-practical-blue;
  font: $font-p-reg;
  gap: $space-4;
}

.documentLabelLocked {
  color: $iron-600;
}
