@import '~@estimateone/frontend-components/src/css/variables';

.projectHistorySection {
  margin-bottom: $space-24;
}

.projectHistoryHeadingWrapper {
  margin-bottom: $space-16;
  display: flex;
  align-items: center;
}

.projectHistoryHeader {
  margin: 0 $space-40 0 0;
  display: flex;
  align-items: center;
  color: $primary-practical-blue;
  font: $font-p-heavy;
}

.projectHistorySkeleton {
  margin-bottom: $space-40;
}

.projectHistorySubheader {
  color: $primary-practical-blue;
  font: $font-ps-reg;
}

.projectHistoryEmptyContentForOwnAccount {
  color: $practical-800;
  font: $font-ps-reg;

  .content {
    color: $practical-900;
    font: $font-p-reg;
  }
}

.projectHistoryAddButton {
  width: fit-content;
}

.projectHistoryEmptyContent {
  color: $iron-600;
  font: $font-ps-reg;
}

.historyContainer {
  display: flex;
  flex-direction: column;
  gap: $space-12;
}

.kbArticleLink {
  margin-left: $space-8;
}
