@import './variables';

%notificationFeatureContainer {
  height: 100%;
  margin-right: $spacer-3;
  display: flex;
  align-items: center;
}

%iconContainer {
  color: $white;
}

$list-left-offset: $spacer-2;

.loadingIconContainer {
  @extend %notificationFeatureContainer;
  @extend %iconContainer;
  opacity: 0.4;
}

.notificationFeatureContainer {
  @extend %notificationFeatureContainer;
  position: relative;

  > button {
    margin: 0;
    border: none;
  }
}

.notificationsIconContainer {
  @extend %iconContainer;
  position: relative;
  display: contents;

  &.hasUnreadNotifications {
    padding-right: 0;

    &:after {
      content: attr(data-unread-count);
      position: absolute;
      top: 0.3em;
      left: 1.1em;
      min-width: 2em;
      height: 2em;
      padding: .25em;
      border: 2px solid $primary-practical-blue;
      border-radius: 1em;
      background-color: $primary-magic-green;
      color: $primary-practical-blue;
      font-size: 0.8em;
    }
  }
}

// Animation classes
.notificationListEnter {
  opacity: 0;
  transform: scale(0.9) translateX(-25px);
}

.notificationListEnterActive {
  opacity: 1;
  transform: translateX(-$list-left-offset);
  transition: opacity 200ms, transform 200ms;
}

.notificationListExit {
  opacity: 1;
}

.notificationListExitActive {
  opacity: 0;
  transform: scale(0.9) translateX(-25px);
  transition: opacity 200ms, transform 200ms;
}
