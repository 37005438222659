@import '~@estimateone/frontend-components/src/css/variables';

.billingContactSelector {
  padding-top: $spacer-4;
  padding-bottom: $spacer-3;
}

.label {
  font: $font-tiny-reg;
  text-align: left;
}
