@import '~@estimateone/frontend-components/src/css/variables';

.projectHistoryForm {
  display: grid;
  grid-gap: $space-24;
  grid-template-columns: 1fr;
}

.adjacentFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $space-8;
}

.formHeader {
  margin-top: 0 !important;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.textAreaWrapper textarea {
  height: 150px;
  color: $practical-900;
  font: $font-ps-reg;
  resize: vertical;
}

.kbArticleLink {
  margin-left: $space-8;
}

.formDescription {
  margin-bottom: $space-24;
}

.dateField {
  width: 100%;
}
