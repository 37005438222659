@import '~@estimateone/frontend-components/src/css/variables';

.upsellCard {
  padding: $space-24;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: $space-4;
  background-color: $electric-025;

  .textContainer {
    display: flex;
    flex-direction: column;

    .header {
      font: $font-p-heavy;
    }

    .text {
      font: $font-ps-reg;
    }
  }

  .buttonContainer {
    margin-left: $spacer-7;
    display: flex;

    button,
    a {
      white-space: nowrap;

      + button,
      + a {
        margin-left: $spacer-2;
      }
    }
  }
}
