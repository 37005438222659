@import '~@estimateone/frontend-components/src/css/variables';

.tenderWizardSubNavContainer {
  margin-bottom: $space-24;
}

.tenderWizardSubNavBtn {
  max-width: 215px;
  display: flex;
  gap: $space-8;
}
