@import '~@estimateone/frontend-components/src/css/variables';

.formField {
  margin-top: $space-24;
}

.preferredIndicator {
  margin-right: $space-8;
}

.fullName {
  margin-right: $space-12;
  font: $font-p-reg;
}

.email {
  margin-right: $space-8;
  font: $font-ps-reg;
}

.phone {
  font: $font-ps-reg;
}
