@media all {
  .invoice-container {
    width: 920px;

    .invoice-header {
      margin-top: 50px;
    }

    .ourAddress {
      width: 30%;
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 1.6em;
      text-align: right;
    }

    .issuedDetails {
      width: 30%;

      h2 {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
        padding: 5px 12px !important;
        display: inline-block !important;
        background: $cod-gray !important;
        color: $white !important;
        font-size: 18px !important;
        font-weight: normal !important;
      }

      p {
        font-size: 15px !important;
        line-height: 1.5em !important;
      }
    }

    .logo {
      width: 225px;
      height: 24px;
    }

    .newBrandingLogo {
      height: 50px;
    }

    h1 {
      margin-top: 50px !important;
      color: $cerulean !important;
      font-size: 38px !important;
      font-weight: bold !important;
    }

    .invoiceDetails {
      width: 45% !important;
      margin-bottom: 10px !important;
      padding: 20px 30px !important;
      border: 1px solid $catskill-white !important;
      border-radius: 8px !important;
      background: $catskill-white !important;
      font-size: 15px !important;

      div {
        padding-bottom: 6px !important;
      }
    }

    table.invoiceTable {
      width: 100%;
      margin-top: 25px;

      tr {
        &:nth-child(odd) td {
          background: $catskill-white !important;
        }

        &.totalLine td:nth-child(2) {
          font-weight: bold;
          text-align: right;
        }

        &.grandTotal td {
          color: $cerulean !important;
        }

        th {
          padding: 6px 10px;
          background: $bay-of-many !important;
          color: $white !important;
          text-align: left;

          &:first-child {
            border-top-left-radius: 4px;
          }
        }

        td {
          padding: 6px 10px;
          border-bottom: 1px solid $catskill-white !important;

          &.invoice-item-qty {
            width: 10%;
          }

          &.invoice-item-name {
            width: 70%;
          }

          &.invoice-item-amount {
            width: 20%;
          }
        }
      }
    }

    .status {
      clear: both;
      margin-bottom: 40px;
      padding: 8px;
      color: $white !important;
      font-size: 19px;
      font-weight: 600 !important;
      text-align: center;
      text-transform: uppercase;

      &.status-ok {
        background: $keppel !important;
      }

      &.status-error {
        background: $roman !important;
      }

      &.status-warning {
        background: $tree-poppy !important;
      }
    }

    .payment-container {
      margin-top: 50px;
      padding-top: 20px;
      border-top: 2px solid $geyser !important;

      h3 {
        color: $shuttle-gray !important;
        font-size: 20px;
        font-weight: normal;
      }

      .paymentMethods {
        margin-top: 25px;

        div {
          p {
            padding: 3px 10px;
            font-size: 15px;
            line-height: 1.6em;
          }

          h3 {
            padding: 6px 10px;
            border-radius: 4px 4px 0 0;
            background: $shuttle-gray !important;
            color: $white !important;
          }
        }
      }
    }

    .details {
      clear: both;
      margin: 20px 0;
      display: block;

      &:before {
        content: ' ';
        display: table;
      }

      &:after {
        content: ' ';
        display: table;
      }
    }

    .focus {
      font-weight: bold;
    }

    a[href]:after {
      content: '' !important;
    }
  }
}
