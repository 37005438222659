@import '~@estimateone/frontend-components/src/css/variables';

.profilePage {
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
}

.profilePanel {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
}

.breadcrumb {
  height: $space-32;
  padding-top: $space-8;
  padding-left: $space-12;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.backButton {
  margin-right: $space-8;
  display: flex;
  stroke: $iron-600;
  color: $primary-practical-blue;
  font: $font-tiny-med;
}

.alignItems {
  margin-top: $space-8;
  display: flex;
  flex-direction: row;
  align-items: center;
  font: $font-tiny-heavy;
  text-decoration: none;
}

.text {
  margin-top: 0;
  color: $iron-600;
  font: $font-tiny-heavy;
  text-decoration: none;
}
