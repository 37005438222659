.modal-backdrop-behind-slider {
  z-index: 10;
}

.mfp-bg,
.modal-backdrop {
  background: $primary-practical-blue;
}

.mfp-bg,
.modal-backdrop.in {
  opacity: 0.8;
}

.e1-modal {
  &.twofa-modal {
    max-width: 400px;
  }

  .modal-content {
    padding: $spacer-5;
    border-radius: 4px;
    box-shadow: none;
  }

  .modal-header,
  .modal-footer {
    margin: 0;
    border: none;
    background: none;

    .wide-buttons {
      margin-top: $space-12;
      padding-right: $space-8;
      padding-left: $space-8;

      .btn {
        padding-top: $space-12;
        padding-bottom: $space-12;

        &.primary-text-color {
          color: $primary-practical-blue;
        }
      }
    }
  }

  .modal-body {
    overflow-y: auto;

    .section-header {
      @include section-header;
    }
  }
}

.modal-title {
  .icon {
    @extend .icon-lg;
    margin: $spacer-3 0;
    display: block;

    .modal-lg &,
    .modal-mega & {
      @extend .icon-xl;
    }
  }
}

.modal-bottomless {
  .modal-footer {
    padding: 0;
  }
}

.modal-instructional {
  text-align: center;

  .modal-header {
    padding-bottom: 0;
  }
}

.file-upload-error {
  text-align: left;
}

// For React Bootstrap modals
.modal.in {
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%) !important;
  }
}

body.doc-viewer-wrapper {
  &.is-mobile,
  &.is-tablet {
    .mfp-wrap,
    .mfp-bg {
      height: 100vh !important;
    }
  }
}

iframe.full-screen-frame {
  position: fixed;
  z-index: 1082;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: $white url('#{$image-path}ajax-loader-wb.gif') center center no-repeat;

  &.dark {
    background: rgb(45 55 68 / 97%) url('#{$image-path}ajax-loader-docpreview.gif') center center
      no-repeat;
  }
}

@media (min-width: 790px) {
  .modal-med {
    width: 750px;
  }
}

@media (min-width: 980px) {
  .modal-mega {
    width: 970px;

    .modal-body {
      max-height: 655px !important;
    }
  }
}

.mfp-iframe-holder {
  width: 100%;
  height: 100%;
  padding: 0;

  .mfp-content {
    max-width: 100%;
    height: 100%;
  }

  .mfp-iframe-scaler {
    padding-top: 0;
  }
}

.mfp-iframe-holder {
  width: 100%;
  height: 100%;
  padding: 0;

  .mfp-content {
    max-width: 100%;
    height: 100%;
  }

  .mfp-iframe-scaler {
    padding-top: 0;
  }
}

// Custom modals
.e1-nps-modal {
  .modal-body {
    @media (max-width: 600px) {
      .nps-score-hints {
        display: none;
      }

      .nps-score-options {
        flex-direction: column;

        li {
          margin-top: 0.2rem;

          a {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    h6 {
      color: $iron-400;
      text-transform: uppercase;
    }

    h4 {
      color: $primary-link-blue;
    }

    .nps-score-container {
      margin: $spacer-4;
    }

    .nps-score-options {
      display: flex;
      justify-content: space-between;
      list-style: none;

      li {
        display: flex;
        border-radius: 8px;
        background: $iron-100;

        &:hover,
        &.active {
          background-color: $primary-link-blue;

          a {
            color: $white;
          }
        }

        .nps-score {
          padding: $spacer-2 $spacer-3;
          color: $primary-practical-blue;
          font: $font-p-med;
          text-decoration: none;
        }
      }
    }

    .nps-score-hints {
      margin-top: $spacer-2;
      display: flex;
      justify-content: space-between;
      color: $iron-500;
      font-style: italic;
    }
  }
}
