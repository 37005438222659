@import '~@estimateone/frontend-components/src/css/variables';

.scheduleFilter {
  min-width: 240px;
}

.actionBar {
  margin-bottom: $space-16;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: $space-16;
}

.scheduleFiltersContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: $space-16;
}

.selectFilterContainer label {
  padding-bottom: $space-8;
}

.tableActionsContainer {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  gap: $space-16;
}

*:fullscreen {
  .actionBar {
    padding: $space-24 $space-24 0;
  }
}
