@import '../../../../../../../css/_shared/variables';

.roadblockGraphicContainer.roadblockGraphicContainer.roadblockGraphicContainer {
  width: 504px;
  height: 160px;
  margin: 24px auto;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: $iron-025;

  .graphicIconContainer {
    width: 184px;
    height: 122px;
    align-self: flex-end;
    border: 2px solid $primary-link-blue;
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $white;
    text-align: center;

    .graphicIcon {
      margin-top: 24px;
    }

    .redactedBlock {
      width: 136px;
      height: 12px;
      margin: auto auto $spacer-1;
      background-color: $iron-050;
    }

    .redactedBlock:first-of-type {
      margin-top: $spacer-2;
    }
  }

  .noCreditsIconContainer {
    border: 2px solid $crimson-500;
    border-bottom: 0;
  }

  .additionalLicenseButton {
    box-sizing: border-box;
    height: $space-48;
    padding: $space-12 $space-24;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border: 2px solid $electric-500;
    border-radius: 32px;
    background: $electric-025;
    font: $font-heading-3;
  }

  .additionalLicensePointer {
    position: absolute;
    margin-top: 87px;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 29.9%));
  }
}

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
.lockIcon {
  margin: 0 $spacer-1;
  fill: $iron-600;
}
