@import '~@estimateone/frontend-components/src/css/variables';

.contactCheckboxContainer {
  width: 100%;
  padding: $space-8 $space-12;
  display: flex;
  column-gap: 1.25rem;
  align-items: center;

  &:hover {
    border-radius: 4px;
    background-color: $iron-050;
  }

  &:focus-within {
    border-radius: 4px;
    outline: $focus-outline;
    background-color: $iron-050;
  }
}

.contactCheckbox {
  align-self: flex-start;
}

.contactCheckboxLabel {
  margin: 0;
  cursor: pointer;
  display: flex;
  column-gap: $space-4;
  align-items: center;

  .labelSeparator {
    font: $font-p-heavy;
  }
}

.unSubscribed {
  color: $iron-300;
}
