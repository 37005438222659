.dropdown .dropdown-toggle {
  &.btn-primary {
    @extend %btn-primary;
  }
}

.dropdown-menu {
  color: $iron-600;
  font: $font-ps-reg;

  h4,
  li > * {
    padding: $spacer-1 $spacer-3;
  }

  hr {
    margin: $spacer-1 0;
  }

  h4 {
    color: $iron-300;
    font: $font-ps-med;
    text-transform: uppercase;
    white-space: nowrap;
  }

  ul {
    list-style: none;
  }

  ul.site-links {
    margin-left: 1rem;
  }

  ul.export-links {
    margin-left: 1rem;
  }

  li {
    white-space: nowrap;

    a {
      cursor: pointer;
      display: block;
      color: $iron-600;

      &:hover {
        background-color: $magic-050;
        color: $primary-practical-blue;
        text-decoration: none;
      }

      &:focus {
        background-color: $iron-025;
      }

      &.dropdown-danger {
        @extend %danger;
      }

      &.add-to-project {
        @extend %success;
      }

      &.btn {
        margin: 8px 10px 12px;
        font-weight: $font-semibold;
      }
    }

    .divider {
      margin: $spacer-1 0;
    }
  }

  .dropdown-menu-item {
    white-space: nowrap;

    > * {
      width: 100%;
      margin: 0;
      display: block;
      border: 0;
      background: transparent;
      color: $iron-600;
      text-align: left;

      &:hover {
        background-color: $magic-050;
        color: $primary-practical-blue;
        text-decoration: none;
      }

      &:focus {
        background-color: $iron-025;
      }

      &.dropdown-danger {
        @extend %danger;
      }
    }
  }

  .form-control {
    width: calc(100% - (#{$spacer-3} * 2));
    margin: $spacer-1 $spacer-3;
  }
}

.header {
  .dropdown-menu {
    .addressbook_search_container {
      width: 22rem;
      margin-bottom: $spacer-2;
    }
  }

  .projects-menu {
    .project_search_container {
      width: 25rem;

      .project_search_results {
        display: none;
      }
    }

    .projects-menu-item {
      color: $primary-practical-blue;
    }

    .projects-menu-item-disabled {
      display: block;
      color: $iron-600;
      text-decoration: none;
    }

    .projects-menu-item-date {
      float: right;
      padding-left: $spacer-3;
      color: $iron-300;
      font-size: $font-size-sm;
      text-align: right;
      text-transform: uppercase;
    }
  }

  .mode-switch {
    .dropdown-menu {
      p {
        color: $iron-400;
        font: $font-ps-reg;
        font-style: italic;
      }
    }
  }
}
