div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #dddddd;
  background-color: #f3f3f3;
}
div.dt-button-info > div {
  padding: 1em;
}

button.dt-button,
div.dt-button,
button.dt-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 0.333em;
  padding: 0.5em 1em;
  border: 1px solid #999999;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.88em;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  background-image: linear-gradient(to bottom, white 0%, #e9e9e9 100%);
  user-select: none;
  text-decoration: none;
  outline: none;
}
button.dt-button.disabled,
div.dt-button.disabled,
button.dt-button.disabled {
  color: #999999;
  border: 1px solid #d0d0d0;
  cursor: default;
  background-image: linear-gradient(to bottom, #ffffff 0%, #f9f9f9 100%);
}
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled) {
  background-image: linear-gradient(to bottom, #f3f3f3 0%, #e2e2e2 100%);
  box-shadow: inset 1px 1px 3px #999999;
}
button.dt-button:active:not(.disabled):hover:not(.disabled),
button.dt-button.active:not(.disabled):hover:not(.disabled),
div.dt-button:active:not(.disabled):hover:not(.disabled),
div.dt-button.active:not(.disabled):hover:not(.disabled),
button.dt-button:active:not(.disabled):hover:not(.disabled),
button.dt-button.active:not(.disabled):hover:not(.disabled) {
  box-shadow: inset 1px 1px 3px #999999;
  background-image: linear-gradient(to bottom, #eaeaea 0%, #cccccc 100%);
}
button.dt-button:hover,
div.dt-button:hover,
button.dt-button:hover {
  text-decoration: none;
}
button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
button.dt-button:hover:not(.disabled) {
  border: 1px solid #666666;
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #e0e0e0 100%);
}
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
button.dt-button:focus:not(.disabled) {
  border: 1px solid #426c9e;
  text-shadow: 0 1px 0 #c4def1;
  outline: none;
  background-image: linear-gradient(to bottom, #bddef4 0%, #79ace9 100%);
}

.dt-button embed {
  outline: none;
}

div.dt-buttons {
  position: relative;
  float: left;
}
div.dt-buttons.buttons-right {
  float: right;
}

div.dt-button-collection {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  margin-top: 3px;
  padding: 8px 8px 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: white;
  overflow: hidden;
  z-index: 2002;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  column-gap: 8px;
}
div.dt-button-collection button.dt-button,
div.dt-button-collection div.dt-button,
div.dt-button-collection button.dt-button {
  position: relative;
  left: 0;
  right: 0;
  display: block;
  float: none;
  margin-bottom: 4px;
  margin-right: 0;
}
div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled) {
  background-image: linear-gradient(to bottom, #f0f0f0 0%, #dadada 100%);
  box-shadow: inset 1px 1px 3px #666666;
}
div.dt-button-collection.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
}
div.dt-button-collection.fixed.two-column {
  margin-left: -150px;
}
div.dt-button-collection.fixed.three-column {
  margin-left: -225px;
}
div.dt-button-collection.fixed.four-column {
  margin-left: -300px;
}
div.dt-button-collection > * {
  break-inside: avoid;
}
div.dt-button-collection.two-column {
  width: 300px;
  padding-bottom: 1px;
  column-count: 2;
}
div.dt-button-collection.three-column {
  width: 450px;
  padding-bottom: 1px;
  column-count: 3;
}
div.dt-button-collection.four-column {
  width: 600px;
  padding-bottom: 1px;
  column-count: 4;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse farthest-corner at center,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  z-index: 2001;
}

@media screen and (max-width: 640px) {
  div.dt-buttons {
    float: none !important;
    text-align: center;
  }
}
