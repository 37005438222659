table {
  .fit-to-content {
    width: 1px;
    white-space: nowrap;
  }

  .tooltip-inner {
    background-color: $primary-practical-blue;
  }
}

%table {
  @extend %table-border;
  width: 100%;
  border-collapse: collapse;

  &,
  p {
    font: $font-ps-reg;
  }

  p {
    padding-bottom: 0;
  }

  thead,
  tbody {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
      line-height: unset;
    }
  }

  thead {
    @include table-header-sort-icons;
    background-color: $iron-050;

    tr {
      th {
        border: none;
        font: $font-tiny-heavy;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        &.sorting_asc,
        &.sorting_desc {
          @extend %table-header-current-sorting;
        }
      }
    }
  }

  tbody {
    & + tbody {
      @extend %table-row-separator;
    }

    tr {
      &:first-child {
        > td {
          border-top: none;
        }
      }

      &:hover {
        background-color: $magic-050;
      }

      &.group {
        cursor: pointer;

        &,
        &:hover {
          background: $iron-025;
        }

        td {
          font-weight: $font-semibold;

          .badge {
            vertical-align: top;
          }
        }

        .group-item-expander {
          padding-top: calc(#{$spacer-3} - #{$spacer-1});
          padding-bottom: calc(#{$spacer-3} - #{$spacer-1});
        }
      }

      &.disabled-row {
        opacity: 0.5;
      }

      &.inactive {
        display: none;
      }

      td {
        @extend %table-row-separator;
      }
    }
  }
}

table.table {
  @extend %table;
}

table.dataTable {
  @extend %table;

  &.no-footer {
    @extend %table-border;
  }
}

table.accountSharedLicensesTable {
  thead {
    visibility: collapse;
  }
}

table.table-data-align-top {
  tbody {
    td {
      vertical-align: top;
    }
  }
}

table.no-bg,
%table-no-bg {
  tr {
    background: none !important;
  }
}

/* JS triggered icon changes */
tr.group {
  [class^='icon-'] {
    @extend .icon;
    margin-right: $spacer-2;
  }

  .icon-arrow {
    @extend .icon-chevron-right;
    @extend .icon-xs;
    transition: all 0.2s;
    font-weight: $font-semibold;
  }

  .collapsed-group {
    .icon-folder::before {
      @extend %icon-folder-before;
    }
  }

  .expanded-group {
    .icon-arrow {
      transform: rotate(90deg);
    }

    .icon-folder::before {
      @extend %icon-folder-open-before;
      color: $primary-link-blue;
    }
  }
}
