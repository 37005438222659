@import '~@estimateone/frontend-components/src/css/variables';

.actionItemWithIcon {
  padding-left: $space-8;
}

.selectedIcon {
  margin: $space-4;
}

.selectedButton {
  display: flex;
  align-items: center;
}

.selectedLabel {
  margin-left: $space-8;
  font: $font-p-heavy;
}

.unselectedLabel {
  padding-left: $space-32;
}

.actionItemWithoutIcon {
  padding-left: $space-16;
}

.menuDivider hr {
  margin: $space-8 0;
}
