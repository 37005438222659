@import '../../../css/_shared/legacy/colours';

.reactSlider {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reactSliderBody {
  flex-grow: 1;
}

.sliderFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 74px;
  box-shadow: inset 0 1px 0 0 $alto;
  background-color: $seashell;
}
