@import '~@estimateone/frontend-components/src/css/variables';

.editableColumnHeader {
  padding-left: 8px;
}

.hiddenRefresh {
  display: none;
}

.tableContainer {
  max-width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 100%;
  overflow-y: auto;

  table > thead {
    position: relative;
    z-index: 3;
    top: 0;
    transform: translate3d(0, 0, 0);
  }

  table > thead > tr > th,
  table > tbody > tr > td {
    min-height: 64px;
  }

  table tr th:nth-child(1),
  table tr td:nth-child(1),
  table tr th:nth-child(2),
  table tr td:nth-child(2) {
    position: sticky;
    z-index: 2;
    left: 0;
  }

  table tr th:nth-child(1),
  table tr th:nth-child(2) {
    z-index: 4;
  }

  table tr th:nth-child(2),
  table tr td:nth-child(2) {
    left: 9rem;
  }

  table tr th:nth-child(1),
  table tr td:nth-child(1) {
    min-width: 9rem;
    max-width: 9rem;
  }

  table tr td:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*
    Create a 1px right side border
    because we are using sticky position for first two columns,
    so the border of the first two columns is not visible (it collapses)
  */

  table tr th:nth-child(2)::after,
  table tr td:nth-child(2)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 100%;
    display: inline-block;
    border: none;
    border-right: solid 1px $iron-100;
  }

  table tr {
    td:nth-child(1),
    td:nth-child(2) {
      background-color: $white;
    }
  }

  tbody tr:hover {
    background-color: $iron-025;

    td:nth-child(1),
    td:nth-child(2) {
      background-color: $iron-025;
    }
  }

  tbody tr:focus-within {
    background-color: $magic-050;

    td:nth-child(1),
    td:nth-child(2) {
      background-color: $magic-050;
    }
  }

  td {
    height: 64px;
    padding: 0;
  }

  tr > th {
    z-index: 2;

    // Why: to make room for displaying error message under inline editable header ( custom column )
    // default padding : 0.75em makes it height of table header 43.9px
    height: 3.65em; // so increasing height to make table header height is 43.9px

    // since we are adding height removing top and bottom padding and keeping left and right padding
    padding: 0 12px;
  }
}

.modalHeading {
  margin: 0 0 32px;
  font: $font-heading-3;
}

.modalText {
  margin: 0 0 48px;
  font: $font-ps-reg;
}

.dateInterval {
  width: 40px;
  height: 20px;
  text-align: center;
}

.iconColumnHeader {
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    margin: 0;
  }
}

.timeRemaining {
  @extend %font-mini-caps;
  padding: 2px 8px 0;
  display: table-cell;
  vertical-align: middle;
  font-weight: 400;
}

.packageNameCell {
  padding: 4px 12px;
}

.cellAlign {
  width: 100%;
  height: 100%;
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cellWarning {
  background-color: rgba($west-400, 0.2);

  .timeRemaining {
    &:before {
      content: ' ';
      width: 8px;
      height: 8px;
      margin-right: $space-4;
      display: inline-block;
      border-radius: 50%;
      background: $west-500;
    }
  }
}

.cellDanger {
  background-color: rgba($crimson-400, 0.2);
  color: $crimson-600;

  .timeRemaining {
    &:before {
      content: ' ';
      width: 8px;
      height: 8px;
      margin-right: $space-4;
      display: inline-block;
      animation-name: alarm;
      animation-duration: 4.5s;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
      border-radius: 50%;
      background: $crimson-600;
    }
  }
}

.cellComplete {
  background-color: rgba($practical-400, 0.2);
}

.cellSuccess {
  background-color: rgba($magic-400, 0.2);
}

@keyframes alarm {
  0% {
    box-shadow: 0 0 0 0 rgba($crimson-600, 0.5);
  }

  55% {
    box-shadow: 0 0 0 0 rgba($crimson-600, 0.5);
  }

  100% {
    box-shadow: 0 0 0 0.4em rgba($crimson-600, 0);
  }
}

.tooltipSpacing div:nth-child(2) {
  margin-bottom: 20px !important;
}

table tr:hover .dateError,
.dateError {
  margin: 0;
  border: 2px solid $crimson-600;
  border-radius: 4px;
}

.dateErrorMessage {
  width: 134px;
  color: $crimson-600;
  font: $font-ps-heavy;
  line-height: 125%;
  overflow-wrap: break-word;
}

.statsHeader {
  margin-top: -$space-32;
  margin-bottom: $space-32;
  padding-top: $space-32;
  background-color: $steel;
}

.noDataText {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
}

.financeHeader {
  width: 100%;
  text-align: right;
}

.financeHeaderWithTooltip {
  width: 100%;
  display: flex;
  text-align: right;
  gap: $space-4;
}

.variance {
  width: 100%;
  text-align: right;
}

.displayVariance {
  white-space: nowrap;
}

.noVariance {
  color: $practical-900;
}

.positiveVariance {
  color: $power-700;
}

.negativeVariance {
  color: $crimson-600;
}

.awardedCompanyName {
  max-width: 7rem;
  margin-right: 0.5rem;
  overflow: hidden;
  font: $font-tiny-med;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quoteCoverageAttrHeader {
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quoteCoverageAttrHeader span {
  margin-left: 2px;
}

.quoteCoverageAttrContent {
  display: flex;
  justify-content: center;
}

.alertBanner {
  margin-bottom: $space-24;
}

.fullscreenLettingScheduleRoot:fullscreen {
  .tableContainer {
    /**
     * This ensure the scroll bar always appears at the bottom of the screen, instead of
     * min-height distance (350px) down
     */
    min-height: 0;
    padding: 0 $space-24;
  }

  table tr th:nth-child(1),
  table tr td:nth-child(1) {
    left: -$space-24;
  }

  table tr th:nth-child(2),
  table tr td:nth-child(2) {
    left: 9rem - $space-24;
  }
}
