@import '../../../../../../../css/_shared/variables';

.buttonsContainer {
  margin-top: $spacer-6;
  margin-bottom: $spacer-4;
  padding: 0 30px;
  display: flex;

  :first-child {
    margin-right: $space-12;
  }
}

.buttonContainer {
  padding: 20px 30px;
}
