@import '~@estimateone/frontend-components/src/css/variables';

.alertContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: $space-12;
}

.alertHeader {
  display: flex;
  column-gap: $space-8;
  align-items: center;
}

.alertHeaderNoMargin {
  margin: 0;
}

.incompleteReasons {
  padding-left: $space-24;
}
