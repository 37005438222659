/* HTML */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

html,
body {
  height: 100%;
  font: $font-p-reg;
}

body {
  margin: 0;
  padding: 0;
  color: $primary-practical-blue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.blank {
    background: none;
  }

  &.two-col {
    .container-fluid > .row {
      display: flex;
      flex: auto;
    }

    .content-left,
    .content-right {
      flex-basis: 50%;
    }

    .content-left {
      padding: $spacer-7;
      background: $white;
      color: $primary-practical-blue;

      h1 {
        color: $primary-magic-green;
      }

      .e1-logo {
        width: 52px;
      }
    }

    .content-right {
      padding: $spacer-7;
      background-color: $primary-practical-blue;
      color: $white;

      .btn-close {
        color: $white;
      }

      .link {
        color: $white;
      }
    }
  }
}

* {
  outline: medium none;
}

div,
ul,
li,
form {
  margin: 0;
  padding: 0;
}

img {
  border: medium none;
  outline: medium none;
}

hr {
  margin: $spacer-3 0;
}

/* Main Container / Wrapper / Header / Footer Styles */
.container-fluid {
  min-width: $min-container-width;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  &.blurred {
    filter: blur(4px);
  }
}

.wrapper {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;
  padding: 0 $container-y-padding;
  flex: 1 0 auto;
}

.fluid-wrapper {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 $container-y-padding;

  // Ensures the width of the container at least matches any other containers
  // on the screen when the screen is larger than the container

  @media screen and (min-width: $max-container-width) {
    min-width: $max-container-width;
  }

  // When the screen is smaller than our max container width,
  // we ensure this fluid container spans 100%

  @media screen and (max-width: $max-container-width) {
    width: 100%;
  }
}

.app-height-wrapper {
  min-height: 800px;
}

.react-content-wrapper {
  flex: 1 0 auto;
}

.section {
  margin-bottom: 2rem;
  padding: 0;

  &:after {
    clear: both;
  }

  h2 {
    span.breadcrumb-separator {
      padding: 0 4px;
      color: $alto;
      font-size: 20px;
      font-weight: normal;
    }

    span.first {
      color: $mine-shaft;
    }
  }
}

.loading-container {
  &.has-loaded {
    .loading {
      display: none;
    }

    .loaded {
      display: block;
    }
  }

  .loading {
    margin-bottom: 20px;
    text-align: left;

    p {
      margin-top: 10px;
      color: $boulder;
    }
  }

  .loaded {
    display: none;

    .empty-text-placeholder {
      display: none;
    }
  }
}

.watch-toggle {
  width: 18px;
  height: 19px;
  display: block;
  border: none;
  background: url('#{$image-path}watchlist.png') no-repeat transparent;
  text-indent: -9999px;

  &.active {
    background-position: -18px 0;

    &:after {
      content: '✔';
    }
  }
}

.unactioned-invitations-indicator {
  content: '\00a0';
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 1em;
  background: $primary-magic-green;
}

div.trade-select {
  div.select_group {
    div {
      input {
        margin-right: 5px;
      }
    }
  }

  label {
    min-height: 0;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0;

    .checkbox {
      margin-top: 6px;
      margin-bottom: 0;
    }
  }
}

.tender-container {
  .none {
    margin-bottom: 10px;
  }
}

.col-padding {
  padding-right: 5%;
}

.pointer-cursor {
  cursor: pointer;
  text-decoration: none;
}

.vcenter {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

span[data-markjs] {
  &.highlight {
    background: $peach;
    font-weight: bold;
  }
}

.license-price-strikethrough {
  text-decoration: line-through;
}

.discount-active {
  color: $primary-magic-green;
}
