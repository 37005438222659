@import '~@estimateone/frontend-components/src/css/variables';

.unstyledButton {
  padding: 0;
  cursor: pointer;
  border: none;
  outline: inherit;
  background: none;
  color: inherit;
  font: inherit;
}

.logo {
  width: 100px;
  height: 100px;
}

.uploading {
  filter: blur(2px);
}

.uploadContainer {
  position: relative;
}

.loadingContainer {
  position: absolute;
  inset: 0;
}
