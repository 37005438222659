@import '../../../../../css/_shared/variables';
@import '../../../../../css/app/mixins';
@import '../../../../../css/_shared/legacy/colours';
@import '../../mixins';

$tenderer-e1-colour: #2172aa;
$tenderer-user-colour: #d927b2;
$autocomplete-item-hover: #fffce6;

@media screen and (max-height: 900px) {
  .hideOnScroll {
    visibility: visible;
    opacity: 1;
    transition: line-height 0.8s linear, height 0.8s linear, padding-top 0.5s linear,
      padding-bottom 0.5s linear, opacity 1s linear;

    .sliderHeader.compacted & {
      height: 0;
      margin: 0;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      transition: font-size 1s linear, padding-top 0.5s linear, padding-bottom 0.5s linear,
        opacity 1s linear;
      font-size: 0;
      line-height: 0;
    }
  }
}

.sliderHeader {
  .projectSliderHeader {
    padding: $spacer-5;
    background: $primary-practical-blue;
    color: $white;
  }

  > hr {
    margin: 0;
  }

  .projectId {
    opacity: 0.6;
    font: $font-ps-heavy;
  }

  .printBtn {
    padding: 0;
    background: none;
    color: $white;

    &:hover {
      background: inherit;
    }
  }

  h2 {
    margin: $spacer-1 0;
  }

  .viewMap {
    margin-left: $spacer-3;
    color: $white;
  }

  .watchlistSelector {
    color: $primary-practical-blue;
  }
}

.projectDetailsContainer {
  display: flex;
  justify-content: space-between;
}

.projectDetails {
  padding: $spacer-4 $spacer-5;
  background-color: $steel;
  color: $primary-practical-blue;
  font-size: $font-size-xs;

  div {
    flex-grow: 1;
  }

  h3 {
    margin: $spacer-1 0 0 0;
  }

  span {
    font: $font-ps-reg;
  }
}
