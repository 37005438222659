@import '~@estimateone/frontend-components/src/css/variables';

.formContainer {
  display: flex;
  flex-direction: column;
  gap: $space-12;
}

.abnText {
  color: $iron-600;
  font: $font-ps-reg;
}
