@import '~@estimateone/frontend-components/src/css/variables';

.serverError {
  margin-bottom: $space-24;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    margin: 0;
    display: inline;
    color: $primary-practical-blue;
    font: $font-p-reg;
  }
}

.serverErrorIcon {
  margin-right: $space-8;
}

.serverErrorCloseIcon {
  margin-top: $space-8;
  margin-left: $space-8;
}
