@media print {
  @page {
    margin: 2em;
    size: a4;
  }

  .project-watchlist-trigger {
    background: none;
    color: $mine-shaft;
    font-size: 16px;
    text-indent: 0;
  }

  iframe:not(.email-frame) {
    display: none;
  }
}
