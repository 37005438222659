@import '~@estimateone/frontend-components/src/css/variables';

.tradesHeaderContainer {
  display: flex;
  align-items: center;
}

.tradesHeader {
  margin-top: $space-8;
  margin-bottom: $space-4;
  font: $font-tiny-heavy;
}

.knowledgeBaseLink {
  display: flex;
  align-items: center;
}
