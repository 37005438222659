@import '~@estimateone/frontend-components/src/css/variables';

.editableNumber {
  background-color: inherit;

  &:hover {
    margin: 1px;
    border: 1px solid $iron-500;
    border-radius: 2px;
    background-color: white;
  }

  &:focus {
    margin: 0;
    padding: 6px;
    border: 2px solid $primary-magic-green;
    border-radius: 4px;
    background-color: white;
  }

  &::placeholder {
    color: $iron-400;
  }

  .errorState {
    border: 2px solid $crimson-600;
    border-radius: 4px;

    &:focus {
      margin: 0;
      border: 2px solid $crimson-600;
      border-radius: 4px;
    }
  }
}

.editableNumber {
  box-sizing: content-box;
  width: 104px;
  height: 20px;
  margin: 2px;
  padding: 6px;
  border: 0;
  color: $practical-900;
  font: $font-ps-reg;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: $iron-400;
  }

  &:focus {
    margin: 0;
    border: 2px solid $primary-magic-green;
    border-radius: 4px;
  }
}

.errorState {
  border: 2px solid $crimson-600;
  border-radius: 4px;

  &:focus {
    margin: 0;
    border: 2px solid $crimson-600;
    border-radius: 4px;
  }
}

.errorMessage {
  color: $crimson-600;
  font: $font-ps-med;
}
