@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeading {
  margin: 0;
  color: $white;
  font: $font-heading-2;
}

.sliderSteps {
  display: flex;
  justify-content: center;

  li {
    span {
      padding: 0 $space-16;
    }
  }

  li:last-of-type {
    span {
      padding: 0;
    }
  }
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.procoreItemsDescription {
  margin-bottom: $space-24;
  color: $primary-practical-blue;
  font: $font-ps-reg;

  p:nth-child(1) {
    margin-bottom: $space-16;
  }
}

.procorePermissionsErrorModalContainer {
  display: flex;
  flex-direction: column;
  color: $primary-practical-blue;
  font: $font-p-reg;
}

.procorePermissionsErrorModalHeading {
  margin-top: 0 !important;
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.procorePermissionsErrorModalDescription {
  margin-bottom: $space-16;
  font: $font-p-reg;
}

.procorePermissionsErrorModalDescriptionLink {
  color: $primary-practical-blue;
  font: $font-p-heavy;
}

.procorePermissionsErrorModalButtonContainer {
  margin-top: $space-40;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}
