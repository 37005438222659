.history {
  .icon-container {
    margin-right: $spacer-3;
  }

  .corr-log {
    @extend .note;
    background-color: $ground;

    .author {
      font-weight: $font-semibold;
    }

    .target {
      @extend %info;
    }
  }
}

.correspondence_view_controls {
  height: 40px;
  background: $silver;
}

@media print {
  .correspondence_view_controls {
    display: none;
  }

  .correspondence_view {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 15px;
    display: block !important;
    background-color: white;
  }
}

.correspondence-error-help,
.correspondence-intro-help {
  width: 100%;
  height: 290px;
  margin: 0 auto;
  display: block;
  background: url('#{$image-path}correspondence-error.png') transparent no-repeat;
  background-position: center;
  background-size: cover;
}

.correspondence-intro-help {
  height: 294px;
  background-image: url('#{$image-path}correspondence-intro.png');
}

.correspondence-button-base-style {
  height: 35px;
  border: solid 1px $heather;
  border-radius: 4px;
  background-color: $catskill-white;
  font-size: 13px;

  i {
    padding-top: 6px;
    color: $silver-chalice;
    font-size: 16px;
  }
}

textarea.correspondence-content {
  min-height: 150px;
}

/*** Correspondence Reset ***/
.cp-body {
  div,
  p,
  span,
  label,
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
    opacity: 1;
    background: none;
    color: $mine-shaft;
    line-height: 1.5em;
    text-transform: none;
  }
}

.correspondence-filter-ctn {
  label {
    padding-right: 10px;
    vertical-align: super;
  }

  div {
    display: inline-block;
  }
}

.correspondence-external-mail-container {
  .file-uploader {
    .upload-status-container {
      &[data-upload-status='ready'] {
        div[data-visible-status='ready'] {
          display: initial;
        }
      }

      &[data-upload-status='in-progress'] {
        div[data-visible-status='in-progress'] {
          display: initial;
        }
      }

      div.instructions {
        .percentage {
          &:after {
            content: '%';
          }
        }
      }

      div[data-visible-status] {
        display: none;
        transition: 0.3s;
      }
    }
  }
}
