.header {
  padding: $spacer-5 0 0 0;
  background: $primary-practical-blue;
  -webkit-font-smoothing: antialiased;

  .company-menu {
    margin-bottom: $spacer-3;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .e1-logo {
    height: 30px;
    margin-right: $spacer-3;
  }

  i {
    &.icon-chevron-down {
      color: rgba($white, 0.6);

      &:active,
      &:focus,
      &:hover {
        color: rgba($white, 0.8);
      }
    }
  }

  .upgrade-link,
  .pay-invoice-link {
    margin: $spacer-1 0 $spacer-1 $spacer-3;
    display: inline-block;
    color: $white;
    font: $font-ps-med;
  }

  .update-full {
    padding: $spacer-1 $spacer-2;
    display: inline-block;
    border: solid 1px rgba($white, 0.25);
    border-radius: 4px;
    color: $white;
    font: $font-ps-med;

    a.office-select-trigger {
      color: $white;
    }
  }

  .user-info,
  .mode-switch-link {
    &.open {
      background: rgba($white, 0.1);
    }
  }

  .user-info {
    border: solid 1px rgba($white, 0.25);
    border-radius: 4px;

    .username-link {
      padding: $spacer-2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;
      color: $white;
      font: $font-tiny-reg;

      &:focus,
      &:hover {
        text-decoration: none;
      }

      .userinfo-details {
        margin-right: $spacer-7;
        display: flex;
        flex-direction: column;

        &.with-profiles-new-tag {
          margin-right: $spacer-4;
        }

        .userinfo-username {
          font-weight: $font-medium;
        }
      }
    }

    .menu-profiles-new-tag {
      margin-right: $spacer-1;
    }
  }

  .mode-switch {
    padding: $spacer-1 $spacer-2;
    display: inline-block;
    border: 1px solid rgba($white, 0.1);
    border-radius: 3px;

    &.open {
      background: rgba($white, 0.1);
    }

    a.mode-switch-link {
      background: none;
      color: $white;
      font: $font-tiny-reg;
      text-decoration: none;

      .icon-chevron-down {
        font-size: 0.4rem;
      }
    }

    .mode_notification {
      @include arrow-top(10px, $primary-link-blue, 1px, $primary-link-blue, 40);
      position: absolute;
      z-index: 100;
      top: $spacer-5;
      width: 23rem;
      padding: $spacer-3;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba($primary-practical-blue, 0.2);
      background-color: $primary-link-blue;
    }
  }
}

span.mode-indicator {
  width: 10px;
  height: 10px;
  margin-right: $spacer-1;
  display: inline-block;
  border-radius: 8px;

  &.estimating {
    background-color: $electric-500;
  }

  &.construction {
    background-color: $energy-500;
  }
}
