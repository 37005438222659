@import '~@estimateone/frontend-components/src/css/variables';

$button-border-width: 2px;
$button-border-radius: 4px;

@mixin buttonPadding($y, $x) {
  padding: calc(#{$y} - #{$button-border-width}) calc(#{$x} - #{$button-border-width});
}

// Silent classes
%small {
  @include buttonPadding($space-8, $space-12);
  font: $font-ps-heavy;
}

%medium {
  @include buttonPadding($space-12, $space-16);
  font: $font-p-heavy;
}

%large {
  padding: 1em 1.5em;
  font: $font-p-heavy;
}

.fec--button {
  &-size {
    &__small {
      @extend %small;
    }

    &__medium {
      @extend %medium;
    }

    &__large {
      @extend %large;
    }
  }
}
