@import '~@estimateone/frontend-components/src/css/variables';

.coverageStatus {
  display: flex;
}

.remainingQuoteCount {
  color: $iron-400;
  font: $font-tiny-reg;
  line-height: 16px;
}
