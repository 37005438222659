@import '~@estimateone/frontend-components/src/css/variables';

.button {
  margin-right: $space-8;
}

.warningModalInfoMessage {
  margin: $space-16 0 $space-16 $space-8;
}

.warningModalAdditionalMessage {
  margin: $space-16 0 $space-16 0;
}

.warningModalInfoIcon {
  margin-top: 3px;
}
