@import '~@estimateone/frontend-components/src/css/variables';

.projectDetails {
  display: grid;
  grid-template-columns: 1fr;
  gap: $space-24 $space-32;

  .detailsHeading {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    button {
      margin-left: $space-16;
    }
  }
}
