@import '~@estimateone/frontend-components/src/css/variables';

.procoreConnectContainer {
  margin-right: $space-16;
  display: flex;
  justify-content: center;
  gap: $space-8;
}

.procoreConnectButtonIcon {
  stroke: none;
}

.procoreConnectButtonDisabledIcon {
  stroke: none;
  fill: #b2b2b2;
  color: #b2b2b2;
}
