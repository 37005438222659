@import '~@estimateone/frontend-components/src/css/variables';

.name {
  font: $font-heading-3;
}

.details {
  color: $iron-700;
  font: $font-intro;
  font-weight: $font-regular;
}
