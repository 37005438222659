@import '~@estimateone/frontend-components/src/css/variables';

.formWrapper {
  display: grid;
  grid-gap: $space-32;
  grid-template-columns: 1fr;
}

.formSection {
  display: grid;
  grid-gap: $space-24;
}

.formSectionTitle {
  margin-top: 0;
  margin-bottom: 0;
}

.formSectionSubtitle {
  font: $font-p-heavy;
}

.serviceAreaContainer {
  display: grid;
  grid-gap: $space-12;
}

.adjacentFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $space-24;
}
