@import '~@estimateone/frontend-components/src/css/variables';

.formField {
  margin-top: $space-24;
}

.checkbox {
  margin-top: $space-8;
}

.noScopeText {
  margin-top: $space-8;
  margin-bottom: 0;
  color: $iron-600;
}
