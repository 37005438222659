@import '~@estimateone/frontend-components/src/css/variables';

.item {
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.timelineRender {
  display: grid;
}

.timelineRender .bullet {
  grid-column: 1;
  grid-row: 1;
}

.timelineRender .line {
  width: 2px;
  height: 100%;
  margin-top: 8px;
  margin-left: 4px;
  grid-column: 1;
  grid-row: 1;
  background-color: $iron-100;
}

.item:last-child .line {
  display: none;
}

.timelineContent {
  margin-bottom: 24px;
}

.item:last-child .timelineContent {
  margin-bottom: 0;
}
