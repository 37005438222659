// White (ish)
$legacy-iron: #d4d7d9;
$mystic: #e2ebed;
$gallery: #efefef;
$athens-gray: #eef0f3;
$catskill-white: #eef6f7;
$seashell: #f1f1f1;
$wild-sand: #f4f4f4;

// Yellow (ish)
$bourbon: #ba6f1e;
$tree-poppy: #fc9c1d;
$saffron: #f4c430;
$peach: #ffe5b4;

// Grey (ish)
$bali-hai: #859faf;
$geyser: #d4dfe2;
$silver-chalice: #acacac;
$dusty-gray: #a8989b;
$heather: #b7c3d0;
$wistful: #a4a6d3;
$alto: #dbdbdb;
$silver: #c0c0c0;
$boulder: #7a7a7a;
$dove-gray: #6d6c6c;
$oslo-gray: #878d91;
$shuttle-gray: #5f6672;
$nevada: #646e75;
$slate-gray: #708090;
$rolling-stone: #747d83;
$outer-space: #2d383a;
$mako: #444954;
$trout: #4a4e5a;
$tundora: #4a4244;
$oxford-blue: #384555;
$mine-shaft: #323232;
$emperor: #514649;
$river-bed: #434c59;
$ebony-clay: #26283b;
$cod-gray: #0b0b0b;

// Red(ish)
$chestnut-rose: #cd5c5c;
$fuzzy-wuzzy-brown: #c45655;
$roman: #de6360;
$mandy: #e25465;
$apple-blossom: #af4d43;
$medium-carmine: #af4035;
$stiletto: #9c3336;
$red: #ff0000;
$heath: #541012;

// Green (ish)
$caribbean-green: #00cc99;
$viridian: #40826d;
$ocean-green: #41aa78;
$goblin: #3d7d52;
$keppel: #3ab09e;
$aqua-forest: #5fa777;
$turquoise: #30d5c8;
$apple: #4fa83d;
$puerto-rico: #3fc1aa;

// Blue (ish)
$spindle: #b6d1ea;
$fiord: #405169;
$cerulean: #02a4d3;
$boston-blue: #3b91b4;
$curious-blue: #2596d1;
$jelly-bean: #297b9a;
$astral: #327da0;
$matisse: #1b659d;
$bay-of-many: #273a81;
$cerulean-blue: #2a52be;
$astronaut: #283a77;
$san-juan: #304b6a;
$fountain-blue: #56b4be;
$shakespeare: #4eabd1;
$seagull: #80ccea;
$ship-cove: #788bba;
$viking: #64ccdb;
$botticelli: #c7dde5;
$link-water: #d9e4f5;
$pattens-blue: #f4f8fb;

// allow for importing sass colours into js
:export {
  slate-gray: $slate-gray;
  slateGray: $slate-gray;
}
