li {
  line-height: 1.5;
}

ol {
  font: $font-p-reg;

  &.small {
    font: $font-ps-reg;
  }

  li {
    margin-bottom: $spacer-2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-csv {
  padding-bottom: $spacer-2;
  font: $font-p-reg;

  li {
    display: contents;
  }

  li:after {
    content: ',';
  }

  li:last-child:after {
    content: none;
  }

  li label {
    font-weight: normal;
    text-decoration: underline;
  }
}

.list-col-3 {
  li {
    float: left;
    width: 32%;
    padding: $spacer-1 0;
    font: $font-ps-reg;
  }
}
