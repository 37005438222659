@import '~@estimateone/frontend-components/src/css/variables';

.fullSetOnlyAlert {
  margin-top: $space-16;
  margin-bottom: $space-16;
}

.alertTitle {
  font: $font-p-heavy;
}
