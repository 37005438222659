@import '~@estimateone/frontend-components/src/css/variables';

.accessControlRow {
  gap: 30px;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: $space-16;
}

// override margin between divs that RadioButtonGroup forces
.radioButtonGroup div {
  margin-bottom: 0;
}

.radioButtonGroup div label {
  margin: $space-16 0 $space-4 0;
}

.optionTitle {
  font: $font-intro;
}

.optionText {
  margin-left: $space-32;
}

.optionText ul {
  list-style-position: inside;

  li {
    margin-top: $space-4;
    padding-left: $space-8;
  }
}

.buttonRow {
  align-self: end;
}

.usersTableHeader {
  margin-bottom: $space-16;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.usersTableHeading {
  margin: 12px 0 0;
}

.addMemberButton {
  padding: 8px 10px;
}

.emptyStateText {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
}

.adminUserTableHeader {
  margin: $space-16 0 $space-8 0;
  display: flex;
  flex-direction: column;
}

.buttons {
  margin-top: $space-48;
  display: flex;

  :first-child {
    margin-right: $space-16;
  }
}

.modalHeading {
  margin: 0 0 32px;
  font: $font-heading-3;
}
