@import '~@estimateone/frontend-components/src/css/variables';

.filterTitle {
  display: flex;
  align-items: flex-end;

  h2 {
    margin: 0;
    padding-top: $space-16;
    font: $font-heading-3;
    font-weight: 600;
  }
}

.filterTitleError {
  @extend .filterTitle;
  color: $crimson-600;
}

.successIcon {
  width: 24px;
  height: 24px;
  margin-left: $space-4;
}

.filterTitleIconSelected {
  fill: $primary-magic-green;
}

.filterTitleIconNotSelected {
  display: none;
}
