@import '~@estimateone/frontend-components/src/css/variables';

.errorMessage {
  margin-bottom: $space-32;
}

.summary {
  margin: 0;
  font: $font-ps-reg;
}

.formField {
  margin-top: $space-24;
}

.correspondenceAndScopesContainer {
  display: flex;
  gap: $space-32;
}

.correspondenceContainer {
  max-width: 50%;
  padding-top: $space-24;
  flex-grow: 1;
}

.scopesContainer {
  margin-top: $space-24;
  flex-grow: 1;
}
