@import '../variables';

.notificationListContainer {
  position: absolute;
  z-index: 1000;
  top: calc(100% + #{$spacer-2});
  width: 17rem;
  transform: translateX(-$list-left-offset);

  &:before {
    content: ' ';
    position: absolute;
    top: -6px;
    left: 14px;
    width: 0;
    height: 0;
    display: block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $white;
    border-left: 6px solid transparent;
  }
}

.notificationList {
  max-height: calc(100vh - 7rem);
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  background: $white;

  ul {
    margin: -#{$spacer-1} 0 #{$spacer-3} 0;
    list-style: none;
    font-size: $font-size-xs;
  }
}

.notificationListHeader {
  position: relative;
  padding: $spacer-4;

  > button {
    position: absolute;
    top: $spacer-3;
    right: $spacer-3;

    &:hover {
      color: inherit;
    }
  }

  h4 {
    line-height: 1;
  }
}

.unreadIndicator {
  margin-right: 1em;
  opacity: 0;
}

.notification {
  margin-top: $spacer-1;
  padding: $spacer-2 $spacer-4;
  cursor: pointer;
  display: flex;

  &:first-child {
    margin-top: 0;
  }

  &.unread {
    background-color: $magic-050;

    .unreadIndicator {
      opacity: 1;
    }
  }
}

.empty {
  padding: 0 $spacer-4 $spacer-4;
  color: $iron-300;
  font: $font-ps-reg;
}

// Animation classes
.notificationEnter {
  opacity: 0;
  transform: scale(0.9) translateX($list-left-offset);
}

.notificationEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
