div[data-form-name='eway_form'],
form[data-form-name='eway_form'],
div.eway-form {
  div.form-group > div {
    height: 35px;
  }

  label.danger {
    color: $color-error;
  }
}
