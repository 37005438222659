@import '~@estimateone/frontend-components/src/css/variables';

.loadingState {
  padding: $space-24 $space-16;
  flex: auto;
}

.errorIconContainer {
  padding: $space-24 $space-16;
  flex: auto;
}
