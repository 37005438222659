@import '~@estimateone/frontend-components/src/css/variables';

.fileIcon {
  margin-right: 8px;
}

.loadingContainer,
.scopesButton {
  margin-right: $space-16;
}

.loadingContainer {
  padding-top: 14px;
}
