@import '~@estimateone/frontend-components/src/css/variables';

.pageWrapper {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 35px;
  flex: 1 0 auto;
  -webkit-box-flex: 1;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 1.15;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.notificationIcon {
  fill: $primary-magic-green;
}

.notificationText {
  width: 100%;
  margin-bottom: 0;

  button {
    font: $font-p-heavy;
  }
}

.overlay {
  position: fixed;
}
