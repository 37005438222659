.loadingSpinnerWrapper {
  position: fixed;
  z-index: 999;
  background-color: #ffffff;
  inset: 0;
}

.refetchLoading {
  opacity: 0.5;
}
