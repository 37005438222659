/* ------------------------
	icons
------------------------- */
@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

$icon-pdf: unicode(ea02);
$icon-add-notes: unicode(ea03);
$icon-starting-soon: unicode(ea05);
$icon-upload: unicode(ea07);
$icon-settings: unicode(ea08);
$icon-notifications: unicode(ea09);
$icon-search: unicode(ea0a);
$icon-print: unicode(ea0b);
$icon-search-mini: unicode(ea0c);
$icon-more: unicode(ea0d);
$icon-menu-expand: unicode(ea0e);
$icon-menu-collapse: unicode(ea0f);
$icon-list-view: unicode(ea10);
$icon-map-view: unicode(ea11);
$icon-grid-view: unicode(ea12);
$icon-folder: unicode(ea13);
$icon-folder-open: unicode(ea14);
$icon-flag: unicode(ea15);
$icon-file: unicode(ea16);
$icon-edit: unicode(ea17);
$icon-download: unicode(ea18);
$icon-contact: unicode(ea19);
$icon-compose: unicode(ea1a);
$icon-close: unicode(ea1b);
$icon-chevron-up: unicode(ea1c);
$icon-chevron-right: unicode(ea1d);
$icon-chevron-left: unicode(ea1e);
$icon-chevron-down: unicode(ea1f);
$icon-check: unicode(ea20);
$icon-calendar: unicode(ea21);
$icon-builder-removed: unicode(ea22);
$icon-attach: unicode(ea23);
$icon-builder-added: unicode(ea24);
$icon-archive: unicode(ea2b);
$icon-add: unicode(ea2c);
$icon-help: unicode(ea2d);
$icon-slide-mini: unicode(ea2e);
$icon-settings-mini: unicode(ea2f);
$icon-notifications-solid: unicode(ea30);
$icon-reorder: unicode(ea31);
$icon-reorder-up: unicode(ea32);
$icon-reorder-down: unicode(ea33);
$icon-remove-user-mini: unicode(ea34);
$icon-quoting: unicode(ea35);
$icon-quoted: unicode(ea36);
$icon-not-interested: unicode(ea37);
$icon-link-mini: unicode(ea38);
$icon-interested: unicode(ea39);
$icon-help-mini: unicode(ea3a);
$icon-edit-mini: unicode(ea3b);
$icon-edit-profile-mini: unicode(ea3c);
$icon-delete-mini: unicode(ea3d);
$icon-contact-mini: unicode(ea3e);
$icon-clear-mini: unicode(ea3f);
$icon-check-mini: unicode(ea40);
$icon-add-mini: unicode(ea41);
$icon-folder-solid: unicode(ea42);
$icon-send: unicode(ea43);
$icon-f-chat: unicode(ea45);
$icon-search-content: unicode(ea46);
$icon-replace: unicode(ea47);
$icon-crane: unicode(ea48);
$icon-configuration-tool: unicode(ea49);
$icon-handover: unicode(ea4a);
$icon-dashboard-flipped: unicode(ea4b);
$icon-editadd-file: unicode(ea4f);
$icon-document-layers: unicode(ea50);
$icon-gridmatrix: unicode(ea54);
$icon-ruler-pencil: unicode(ea55);
$icon-c-info: unicode(ea56);
$icon-undo: unicode(ea59);
$icon-packages: unicode(ea5a);
$icon-f-chat-sm: unicode(ea5b);
$icon-magic: unicode(ea5d);
$icon-tool-hand: unicode(ea5f);
$icon-redo: unicode(ea64);
$icon-f-delete: unicode(ea65);
$icon-f-add: unicode(ea66);
$icon-fullscreen: unicode(ea67);
$icon-view: unicode(ea68);
$icon-lock: unicode(ea69);
$icon-unlocked: unicode(ea6a);
$icon-flag-solid: unicode(ea6b);
$icon-notes: unicode(ea6f);
$icon-awarded: unicode(ea73);
$icon-arrow-right: unicode(ea7f);
$icon-arrow-up-n-out: unicode(ea80);
$icon-arrow-left: unicode(ea81);
$icon-arrow-down: unicode(ea82);
$icon-arrow-up: unicode(ea83);
$icon-bin: unicode(ea8d);
$icon-update: unicode(ea8e);
$icon-t-warning: unicode(ea8f);
$icon-hourglass: unicode(ea90);
$icon-merge: unicode(ea91);

.icon-pdf::before {
  content: $icon-pdf;
}

.icon-add-notes::before {
  content: $icon-add-notes;
}

.icon-starting-soon::before {
  content: $icon-starting-soon;
}

.icon-upload::before {
  content: $icon-upload;
}

.icon-settings::before {
  content: $icon-settings;
}

.icon-notifications::before {
  content: $icon-notifications;
}

.icon-search::before {
  content: $icon-search;
}

.icon-print::before {
  content: $icon-print;
}

.icon-search-mini::before {
  content: $icon-search-mini;
}

.icon-more::before {
  content: $icon-more;
}

.icon-menu-expand::before {
  content: $icon-menu-expand;
}

.icon-menu-collapse::before {
  content: $icon-menu-collapse;
}

.icon-list-view::before {
  content: $icon-list-view;
}

.icon-map-view::before {
  content: $icon-map-view;
}

.icon-grid-view::before {
  content: $icon-grid-view;
}

%icon-folder-before {
  content: $icon-folder;
}

.icon-folder::before {
  @extend %icon-folder-before;
}

%icon-folder-open-before {
  content: $icon-folder-open;
}

.icon-folder-open::before {
  @extend %icon-folder-open-before;
}

.icon-flag::before {
  content: $icon-flag;
}

.icon-file::before {
  content: $icon-file;
}

.icon-edit::before {
  content: $icon-edit;
}

.icon-download::before {
  content: $icon-download;
}

.icon-contact::before {
  content: $icon-contact;
}

.icon-compose::before {
  content: $icon-compose;
}

.icon-close::before {
  content: $icon-close;
}

.icon-chevron-up::before {
  content: $icon-chevron-up;
}

.icon-chevron-right::before {
  content: $icon-chevron-right;
}

.icon-chevron-left::before {
  content: $icon-chevron-left;
}

.icon-chevron-down::before {
  content: $icon-chevron-down;
}

.icon-check::before {
  content: $icon-check;
}

.icon-calendar::before {
  content: $icon-calendar;
}

.icon-builder-removed::before {
  content: $icon-builder-removed;
}

.icon-attach::before {
  content: $icon-attach;
}

.icon-builder-added::before {
  content: $icon-builder-added;
}

.icon-archive::before {
  content: $icon-archive;
}

.icon-add::before {
  content: $icon-add;
}

.icon-help::before {
  content: $icon-help;
}

.icon-slide-mini::before {
  content: $icon-slide-mini;
}

.icon-settings-mini::before {
  content: $icon-settings-mini;
}

.icon-notifications-solid::before {
  content: $icon-notifications-solid;
}

%icon-reorder-before {
  content: $icon-reorder;
}

.icon-reorder::before {
  @extend %icon-reorder-before;
}

%icon-reorder-up-before {
  content: $icon-reorder-up;
}

.icon-reorder-up::before {
  @extend %icon-reorder-up-before;
}

%icon-reorder-down-before {
  content: $icon-reorder-down;
}

.icon-reorder-down::before {
  @extend %icon-reorder-down-before;
}

.icon-remove-user-mini::before {
  content: $icon-remove-user-mini;
}

.icon-quoting::before {
  content: $icon-quoting;
}

.icon-quoted::before {
  content: $icon-quoted;
}

.icon-not-interested::before {
  content: $icon-not-interested;
}

.icon-link-mini::before {
  content: $icon-link-mini;
}

.icon-interested::before {
  content: $icon-interested;
}

.icon-help-mini::before {
  content: $icon-help-mini;
}

.icon-edit-mini::before {
  content: $icon-edit-mini;
}

.icon-edit-profile-mini::before {
  content: $icon-edit-profile-mini;
}

.icon-delete-mini::before {
  content: $icon-delete-mini;
}

.icon-contact-mini::before {
  content: $icon-contact-mini;
}

.icon-clear-mini::before {
  content: $icon-clear-mini;
}

%icon-check-mini-before {
  content: $icon-check-mini;
}

.icon-check-mini::before {
  @extend %icon-check-mini-before;
}

.icon-add-mini::before {
  content: $icon-add-mini;
}

.icon-folder-solid::before {
  content: $icon-folder-solid;
}

.icon-send::before {
  content: $icon-send;
}

.icon-f-chat::before {
  content: $icon-f-chat;
}

.icon-search-content::before {
  content: $icon-search-content;
}

.icon-replace::before {
  content: $icon-replace;
}

.icon-crane::before {
  content: $icon-crane;
}

.icon-configuration-tool::before {
  content: $icon-configuration-tool;
}

.icon-handover::before {
  content: $icon-handover;
}

.icon-dashboard-flipped::before {
  content: $icon-dashboard-flipped;
}

.icon-editadd-file::before {
  content: $icon-editadd-file;
}

.icon-document-layers::before {
  content: $icon-document-layers;
}

.icon-gridmatrix::before {
  content: $icon-gridmatrix;
}

.icon-ruler-pencil::before {
  content: $icon-ruler-pencil;
}

.icon-c-info::before {
  content: $icon-c-info;
}

.icon-undo::before {
  content: $icon-undo;
}

.icon-packages::before {
  content: $icon-packages;
}

.icon-f-chat-sm::before {
  content: $icon-f-chat-sm;
}

.icon-magic::before {
  content: $icon-magic;
}

.icon-tool-hand::before {
  content: $icon-tool-hand;
}

.icon-redo::before {
  content: $icon-redo;
}

.icon-f-delete::before {
  content: $icon-f-delete;
}

.icon-f-add::before {
  content: $icon-f-add;
}

.icon-fullscreen::before {
  content: $icon-fullscreen;
}

.icon-view::before {
  content: $icon-view;
}

.icon-lock::before {
  content: $icon-lock;
}

.icon-unlocked::before {
  content: $icon-unlocked;
}

.icon-flag-solid::before {
  content: $icon-flag-solid;
}

.icon-notes::before {
  content: $icon-notes;
}

.icon-awarded::before {
  content: $icon-awarded;
}

.icon-arrow-right::before {
  content: $icon-arrow-right;
}

.icon-arrow-up-n-out::before {
  content: $icon-arrow-up-n-out;
}

.icon-arrow-left::before {
  content: $icon-arrow-left;
}

.icon-arrow-down::before {
  content: $icon-arrow-down;
}

.icon-arrow-up::before {
  content: $icon-arrow-up;
}

.icon-bin::before {
  content: $icon-bin;
}

.icon-update::before {
  content: $icon-update;
}

.icon-t-warning::before {
  content: $icon-t-warning;
}

.icon-hourglass::before {
  content: $icon-hourglass;
}

.icon-merge::before {
  content: $icon-merge;
}
