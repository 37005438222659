@import '~@estimateone/frontend-components/src/css/variables';

/**
 * Timeline
 */

.timelinePopover {
  cursor: default;
  display: inline-block;
  vertical-align: middle;
}

.tooltip {
  padding: 24px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px $iron-100;
  background-color: white;
}

.timeline {
  display: flex;
  flex-direction: column;
}

/**
 * Description
 */

.description p {
  margin-bottom: 4px;
  padding: 0 0 0 8px;
}

.description p:last-child {
  margin-bottom: 0;
}

.description {
  display: none;
}

.selected .description {
  margin-top: 8px;
  display: block;
}

/**
 * Icons
 */

.icon {
  position: relative;
  z-index: 100;
  width: 10px;
  height: 10px;
  margin-top: 7px;
}

.selected .circleIcon {
  background-color: $primary-practical-blue;
}

.selected .triangleIcon path {
  stroke: $primary-practical-blue;
  fill: $primary-practical-blue;
}

.triangleIcon path {
  stroke: $iron-100;
  fill: $iron-100;
}

.circleIcon {
  display: block;
  border-radius: 50%;
  background-color: $iron-100;
}
