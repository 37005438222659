%tag {
  padding: $spacer-1;
  cursor: default;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: $primary-practical-blue;
  color: $white;
  font-size: 75%;
  font-weight: $font-medium;
  line-height: 1rem;
  white-space: nowrap;
}

%tag-lg {
  padding: $spacer-2;
  font-size: $font-size-md;
  font-weight: $font-regular;
}

.tag {
  @extend %tag;

  &.tag-lg {
    @extend %tag-lg;
  }

  &.rfq-response {
    font-weight: $font-medium;
  }

  &.tag-info {
    background-color: $primary-link-blue;
  }

  // Actionable
  &.tag-actionable,
  &.email_error,
  &.obsolete {
    cursor: pointer;
  }

  /* Colours */
  &.awarded,
  &.approved,
  &.archived,
  &.current,
  &.declined,
  &.hard_copy,
  &.has-docs,
  &.lost,
  &.obsolete,
  &.public,
  &.quoted,
  &.quoting,
  &.sub-status-paid,
  &.discounted {
    color: $primary-practical-blue;
  }

  &.awarded,
  &.approved,
  &.public,
  &.discounted {
    background: $primary-magic-green;
  }

  &.private,
  &.private-label,
  &.retender-label {
    background: $energy-500;
    color: $energy-800;
  }

  &.sub-status-paid {
    background: $power-300;
  }

  &.email_error,
  &.sub-status-outstanding {
    background: $color-error;
  }

  &.not-accessed,
  &.no_response,
  &.not-downloaded,
  &.pending,
  &.request_approved,
  &.sub-status-cancelled {
    background: $iron-050;
    color: $iron-600;
  }

  &.archived {
    background-color: $iron-200;
  }

  &.procurement-awarded {
    margin-left: $spacer-1;
    background-color: $magic-200;
    color: $primary-practical-blue;
    font-weight: $font-medium;
  }

  &.profile-tag {
    margin-top: $spacer-2;
    background-color: $magic-200;
    color: $primary-practical-blue;
    font-weight: $font-medium;
  }

  /* Status */
  &.current {
    background: $practical-300;
  }

  &.declined,
  &.lost {
    background: $color-declined;
  }

  &.downloaded {
    background: $primary-magic-green;
    color: $magic-050;
  }

  &.itp-request {
    background: $volt-600;
  }

  &.hard_copy {
    background: $west-300;
  }

  &.has-docs {
    background: $energy-300;
  }

  &.obsolete {
    background: $crimson-300;
  }

  &.quoted {
    background: $power-300;
  }

  &.quoting {
    background: $inch-300;
  }

  &.drafts {
    background: $energy-300;
    color: $primary-practical-blue;
  }

  &.nbc {
    background: $iron-100;
    color: $magic-700;
  }

  &.new {
    margin-left: $spacer-1;
    padding: $spacer-1 $spacer-2;
    background: $primary-link-blue;
  }

  &.beta {
    margin-left: $spacer-1;
    padding: $spacer-1 $spacer-2;
    background: $west-400;
  }

  &.newOrRevisedFile {
    background: $magic-200;
    color: $power-900;
  }

  &.unlocked {
    background: $magic-200;
    color: $primary-practical-blue;
  }

  &.locked {
    background: $iron-050;
    color: $iron-600;
  }

  &.views {
    background: $practical-100;
    color: $primary-practical-blue;
  }

  // Icons
  .icon-left {
    margin-right: $spacer-1;
  }

  .icon-right {
    margin-left: $spacer-1;
  }
}

.label,
%label {
  &-danger,
  &-success,
  &-warning {
    text-transform: uppercase;
  }

  &-danger {
    background: $color-error;
  }

  &-success {
    background: $power-300;
  }

  &-warning {
    background: $energy-500;
    color: $energy-800;
  }
}

.file-status,
.stage-label {
  margin-left: $spacer-2;
}

.file-status {
  &.private {
    &:before {
      content: 'Internal Only';
    }
  }

  &.public {
    &:before {
      content: 'Public';
    }
  }
}

.doc_status,
.rfq-status {
  &.approved {
    &::before {
      @extend .icon;
      @extend .icon-xs;
      @extend .icon-check;
      margin: 0 $spacer-1;
      font-weight: $font-semibold;
    }
  }
}

.rel-status {
  &.awarded {
    &:before {
      content: 'Awarded';
    }
  }

  &.declined {
    &:after {
      content: 'NQ';
    }
  }

  &.e1-request {
    &:before {
      content: 'E1';
    }
  }

  &.email_error {
    &:before {
      content: 'Email Error';
    }
  }

  &.hard_copy {
    &:before {
      content: 'Hard Copy';
    }
  }

  &.has-docs {
    &:before {
      content: 'Has Docs';
    }
  }

  &.not-accessed {
    &:before {
      content: 'Not Accessed';
    }
  }

  &.itp-request {
    &:before {
      content: 'ITP';
    }
  }

  &.lost {
    &:before {
      content: 'Lost';
    }
  }

  &.quoted {
    &::after {
      content: 'Quoted';
    }
  }

  &.quoting {
    &:before {
      content: 'Quoting';
    }
  }
}

.correspondence-status-tag,
.locked-project-label {
  @extend %tag;

  &,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.correspondence-status-tag {
  &.nrr {
    background: $iron-050;
    color: $iron-600;

    &:before {
      content: 'NRR';
    }
  }

  &.resolved {
    background: $primary-magic-green;
    color: $primary-practical-blue;

    &:before {
      content: 'Resolved';
    }
  }

  &.unresolved {
    background: $energy-500;
    color: $energy-800;

    &:before {
      content: 'Unresolved';
    }
  }
}

.locked-project-label {
  cursor: pointer;

  &,
  &:hover {
    @extend %label-warning;
  }
}
