@import '../_styleguide/buttons.scss';
@import './buttons.scss';

$watchlist-actioned-user-margin-top: $space-4;

.empty-watchlist-prompt {
  img {
    height: 200px;
    margin-top: $space-48;
  }
}

.watchlist-actioned-user {
  margin-top: $watchlist-actioned-user-margin-top;
}

.watchlist-open-table {
  .quote-date-disclaimer {
    font: $font-ps-reg;
  }

  .dateDue {
    position: relative;

    .disclaimerSymbol {
      position: absolute;
      right: -0.5rem;
      font: $font-ps-heavy;
    }
  }

  .project-address {
    color: $iron-600;
    font: $font-tiny-reg;

    em {
      color: $black;
      font-style: normal;
      font-weight: bold;
    }
  }

  .project-id {
    &:before {
      content: '#';
    }

    &:after {
      content: ' - ';
    }
  }
}

.watchlist-open-table tbody td.watchlist-select-project-assignee {
  vertical-align: middle;
}

// Watchlist interest selector - reference: .btn-small, assets/css/app/buttons.scss
// font size set to xs, line height left as per $font-size-md
$watchlist-interest-selector-lineheight: #{$font-lh-md * $font-size-xs};
$watchlist-interest-selector-padding: #{$space-8 * 2};
$watchlist-actioned-user-lineheight: #{$font-lh-sm * $font-size-sm};
$avatar-table-height: 32px;

// Watchlist assignment div - has to align with watchlist interest selector button
/* stylelint-disable scss/operator-no-newline-before, scss/operator-no-newline-after */
[data-hook='assign-project'] {
  height: calc(
    $watchlist-interest-selector-lineheight + $watchlist-interest-selector-padding +
    ($button-border-width * 2) + $watchlist-actioned-user-margin-top +
    $watchlist-actioned-user-lineheight
  );
  padding: calc(
    0.5 *
    (
      $watchlist-interest-selector-lineheight + $watchlist-interest-selector-padding +
      ($button-border-width * 2) - $avatar-table-height
    )
  )
    0;
  align-content: flex-start;
}
/* stylelint-enable scss/operator-no-newline-before, scss/operator-no-newline-after */
