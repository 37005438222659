@import '~@estimateone/frontend-components/src/css/variables';
@import '~@estimateone/frontend-components/src/components/HintCard/styles.module.scss';

.shareContactsCardContainer {
  margin-bottom: $space-16;
  padding-bottom: $space-16;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid $iron-100;
  border-radius: $space-4;
  background-color: unset;

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: $space-4;

    .header {
      font: $font-p-heavy;
    }

    .text {
      font: $font-ps-reg;
    }
  }

  .buttonContainer {
    display: flex;

    button,
    a {
      white-space: nowrap;

      +button,
      +a {
        margin-left: $spacer-2;
      }
    }
  }
}
