@import '~@estimateone/frontend-components/src/css/variables';

.listInfo {
  padding-left: $space-16;

  li {
    margin-bottom: $space-8;
  }

  :last-child {
    margin-bottom: 0;
  }
}
