@import '~@estimateone/frontend-components/src/css/variables';

.companyInfoContainer {
  margin-left: $spacer-4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.officeLocation {
  margin-top: $spacer-2;
}

.returnLinkContainer {
  margin-bottom: $spacer-4;
}

.emailText {
  font: $font-ps-heavy;
}

.phoneText {
  font: $font-ps-reg;
}

.headerSubtitle {
  margin-bottom: $spacer-2;
  margin-left: $spacer-6;
}
