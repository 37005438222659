@import '~@estimateone/frontend-components/src/css/variables';

.cardTitleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  .cardHeading {
    margin: 0;
  }

  .cardSubheading {
    color: $iron-600;
    font-size: 14px;
  }
}

.cardStats {
  display: flex;
  flex-direction: row;
  gap: $space-32;

  .statTitle {
    @extend %font-mini-caps;
    margin: 0;
  }

  .statCount {
    margin: $space-4 0 0 0;
    display: inline;
    color: $primary-practical-blue;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  .verticalRule {
    border-left: 1px solid $iron-200;
  }
}
