.file-uploader {
  #document_upload_button {
    display: inline-flex;
    line-height: 1em;
  }

  .uploader,
  .uploader-s3 {
    margin-bottom: $spacer-3;
    text-align: center;

    .drag_target {
      padding: $spacer-5;
      border: 2px dashed $iron-100;
      border-radius: 4px;
      background: $white; /* Old browsers */
      color: $primary-practical-blue;

      &.qq-upload-drop-area-active,
      &.upload-active {
        border-color: $primary-magic-green;
        background: $magic-050;
      }

      .close {
        position: absolute;
        z-index: 2000;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: block;

        a {
          height: 20px;
        }

        a:hover {
          color: $mine-shaft;
        }
      }

      .upload-section {
        padding: $spacer-2;

        .instructions {
          h3 {
            margin-top: $spacer-3;
          }
        }

        .upload-options {
          margin-top: $spacer-3;

          label {
            font: $font-tiny-reg;
          }
        }
      }
    }
  }
}

.progress-section {
  text-align: center;

  .widget,
  .label {
    margin-bottom: $spacer-2;
    display: block;
  }

  .widget {
    height: 4rem;
    padding: $spacer-3 0;
    font: $font-heading-2;
  }

  .label {
    color: $primary-practical-blue;
    font: $font-ps-reg;
  }

  .progress {
    height: 9px;
    margin: 0;
    padding: 0;

    .progress-bar {
      background: $primary-link-blue;
    }
  }

  .progress-bar-label {
    margin-bottom: $spacer-1;
    color: $primary-practical-blue;
    font: $font-tiny-reg;
    text-align: left;
  }
}

.uppy-container {
  .uppy-DragDrop-inner {
    .uppy-DragDrop-label {
      display: inline-block;

      &:after {
        content: '\00a0';
      }
    }
  }

  .with-extra-content {
    border: 2px dashed $iron-200;
    border-radius: 4px;

    .uppy-DragDrop-container {
      border: none;

      .uppy-DragDrop-inner {
        .uppy-DragDrop-label,
        .uppy-DragDrop-note {
          color: $iron-600;
          font-size: $font-size-lg;
        }
      }
    }
  }

  .uppy-DragDrop-container {
    max-width: 100%;
    padding: $spacer-5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed $iron-200;
    border-radius: 4px;
    background-color: $white;
    font: $font-heading-3;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  // http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
  .uppy-DragDrop-input {
    position: absolute;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
  }

  .uppy-DragDrop-arrow {
    display: none;
  }

  .uppy-DragDrop--isDraggingOver {
    border: 2px dashed $primary-magic-green;
    background: $magic-050;
  }

  .uppy-DragDrop-label {
    cursor: pointer;
    display: block;
    color: $primary-practical-blue;
  }

  .uppy-DragDrop-note {
    cursor: pointer;
    border-bottom: 4px solid $primary-magic-green;
    color: $primary-practical-blue;
  }
}
