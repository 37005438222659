@import '~@estimateone/frontend-components/src/css/variables';

.tradesContainer {
  margin-bottom: $space-16;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tradesHeader {
  margin-top: $space-8;
  margin-bottom: $space-4;
  font: $font-tiny-heavy;
}

.coreTradeTag {
  margin-top: $space-8;
  margin-bottom: $space-8;
}
