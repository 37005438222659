h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: $spacer-6 0 $spacer-5;
  font: $font-heading-1;
}

h2 {
  margin: $spacer-5 0;
  font: $font-heading-2;
}

h3 {
  margin: $spacer-5 0 $spacer-3;
  font: $font-heading-3;
}

h4 {
  font: $font-intro;
}

h5 {
  font: $font-p-heavy;
}

h6 {
  font: $font-ps-heavy;
}

.font-regular {
  font-weight: $font-regular !important;
}

.font-medium {
  font-weight: $font-medium !important;
}

.font-semibold {
  font-weight: $font-semibold !important;
}

.link {
  cursor: pointer;
}

a {
  &:focus,
  &:hover {
    outline: none;
    color: unset;
    text-decoration: none;
  }

  &.link {
    @extend %a-link;
  }

  &.primary {
    @extend %a-primary;
  }

  &.secondary {
    @extend %a-secondary;
  }

  &.danger {
    @extend %a-danger;
  }

  &.success {
    @extend %a-success;
  }

  &.tab-link {
    @extend %a-link;
  }

  &.text-white {
    &:focus,
    &:hover {
      color: $white;
    }
  }

  &.link-return {
    color: $iron-600;
    font: $font-tiny-reg;

    &::before {
      @extend .icon;
      @extend .icon-chevron-left;
      margin-right: $spacer-1;
      font-size: 0.6em;
    }
  }
}

%a-link {
  background: linear-gradient(to top, $primary-magic-green 1px, transparent 1px);
  color: $primary-practical-blue;
  text-decoration: none;

  &:active {
    background: linear-gradient(to top, $primary-practical-blue 1px, transparent 1px);
  }

  &:focus {
    outline: thick auto $primary-link-blue;
    outline-offset: $spacer-1;
  }

  &:hover {
    background: linear-gradient(to top, $magic-500 35%, transparent 35%);

    .small & {
      background: linear-gradient(to top, $magic-500 15%, transparent 15%);
    }
  }

  &.wrap-ellipsis {
    max-width: 100%;
    display: inline-block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

%a-primary {
  font-weight: $font-medium;
}

%a-secondary {
  @include link-style($iron-600, $primary-practical-blue);
  font-weight: $font-medium;
}

%a-danger {
  @include link-style($color-error, $color-error);

  &:hover {
    background: linear-gradient(to top, $color-error 1px, transparent 1px);
  }
}

%a-success {
  @include link-style($primary-magic-green, $primary-magic-green);

  &:hover {
    background: linear-gradient(to top, $primary-magic-green 1px, transparent 1px);
  }
}

li {
  a {
    background: none;
  }
}

p {
  margin-bottom: $spacer-2;
}

span {
  &.highlight {
    padding: 1px;
    background: $energy-100;
    color: $primary-practical-blue;
    font-weight: $font-semibold;
  }
}

.small {
  label {
    font-weight: $font-medium;
  }
}

.text-white {
  color: $white !important;
}

.muted {
  color: $iron-400 !important;
}

%success {
  color: $primary-magic-green !important;
}

.success {
  @extend %success;
}

%info {
  color: $primary-link-blue !important;
}

.info {
  @extend %info;
}

%danger {
  color: $color-error !important;
}

.danger {
  @extend %danger;
}

%grey {
  color: $iron-600 !important;
}

.grey {
  @extend %grey;
}

.construction {
  color: $color-construction;
}

.estimating {
  color: $color-estimating;
}

.dt-past,
.date_past .hidden_datetime {
  @include date-style($color-date-past);
}

.dt-today,
.date_today .hidden_datetime {
  @include date-style($color-date-today);
}

.dt-tomorrow,
.date_tomorrow .hidden_datetime {
  @include date-style($color-date-tomorrow);
}

.dt-future,
.date_future .hidden_datetime {
  @include date-style($color-date-future, $font-regular);
}

.empty-text-placeholder p {
  font: $font-ps-reg;
  font-style: italic;
}

.upgrade_prompt {
  color: $primary-magic-green;
  font-weight: $font-semibold;
}

.company-name,
.recent-note,
.timestamp,
.unread-count {
  padding: $spacer-1 0 0 0;
  font: $font-tiny-reg;
}

.recent-note {
  &.default-note {
    color: $iron-400;
  }

  &.note-updated-relative {
    color: $primary-link-blue;
  }
}

.company-name,
.timestamp {
  color: $iron-600;
  white-space: nowrap;
}

.unread-count {
  display: block;
  color: $primary-link-blue;
}

.date-ago {
  font: $font-tiny-heavy;
}

.date-timestamp {
  @extend .timestamp;
}
