@import '~@estimateone/frontend-components/src/css/variables';

.stageQuotesTableHeaderActions {
  width: 1px;
  white-space: nowrap;
}

.quoteGroupHeaderContent {
  display: flex;
  justify-content: space-between;

  .quoteGroupHeaderInfo {
    padding: $spacer-1 0;
    display: flex;
    align-items: center;

    &.deleted {
      opacity: 0.6;
    }

    .packageNameDescription {
      margin-left: $spacer-1;
      font-weight: $font-regular;
    }
  }
}

table {
  p {
    &.companyName,
    &.creatorName {
      margin: 0 0 0.25rem;
      font-size: 0.9rem;
      white-space: nowrap;
    }

    &.contactName,
    &.createdAt {
      margin: 0;
      color: $iron-700;
      font-size: 0.8rem;
      white-space: nowrap;
    }
  }
}

.quoteRowActions {
  white-space: nowrap;

  > a,
  > button {
    + a,
    + button {
      &:not(:first-child) {
        margin-left: $spacer-1;
      }
    }
  }
}
