@import '~@estimateone/frontend-components/src/css/variables';

.header:hover .edit {
  width: 1em;
  height: 1em;
  color: $magic-500;
}

.header {
  cursor: pointer;
}
