@import '~@estimateone/frontend-components/src/css/variables';

.filterDropdown {
  position: relative;

  .contents {
    position: absolute;
    z-index: 1000;
    top: 3.5em;
    min-width: 10em;
    min-height: 2em;
    padding: $spacer-4;
    border: 1px solid $iron-100;
    border-radius: 12px;
    box-shadow: 0 0 40px rgba($black, 0.08);
    background: $white;
  }
}

.filterButton {
  padding: 1em;
  transition: 200ms;
  border: 2px solid $iron-100;
  border-radius: 24px;
  background-color: $white;
  color: $primary-practical-blue;
  font-weight: $font-medium;
  line-height: 1;

  &:focus {
    border-color: $primary-practical-blue;
  }

  &:hover {
    background-color: $iron-025;
  }

  &.isActive {
    border-color: $primary-magic-green;
    background-color: $magic-050;

    &:hover {
      background-color: $magic-200;
    }
  }

  &.isOpen {
    border-color: $primary-magic-green;
    background-color: $magic-200;
  }

  .subLabel {
    margin-right: $spacer-2;
    color: $iron-600;
    font-size: $font-size-sm;
  }
}
