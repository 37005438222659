@import '~@estimateone/frontend-components/src/css/variables';

.projectNamePlaceholder {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: $space-8;
  }
}

.createNewOption {
  display: flex;
  align-items: center;
  color: $primary-practical-blue;
  font-weight: $font-medium;
}

.createNewOptionIcon {
  margin-right: $space-8;
  display: flex;
  align-items: center;
}

.projectNameOption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    flex: 1 1;
  }

  .status {
    margin-left: $space-12;
    border: 1px solid $iron-400;
    border-radius: $space-4;

    span {
      min-width: $space-80;
      display: flex;
      justify-content: center;
      color: $iron-400;
      font-weight: $font-regular;
    }
  }
}

.stateName {
  width: 100px;
  display: inline-block;
  color: $iron-400;
}
