@import '~@estimateone/frontend-components/src/css/variables';

.projectSection {
  margin-bottom: $space-48;
}

.projectSubbiesEmpty {
  padding-top: $space-24;
  padding-bottom: $space-24;
  border-top: 1px solid $iron-200;
  border-bottom: 1px solid $iron-200;
  line-height: $space-24;
  text-align: center;
}

.headingTagContainer {
  position: relative;
  bottom: $spacer-1;
  margin-left: $spacer-1;
  display: inline-block;
}
