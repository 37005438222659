/** Components **/
@mixin table-row-border($side) {
  border-#{$side}: 1px solid $iron-050;
}

%table-border {
  @include table-row-border('bottom');
}

%table-header-current-sorting {
  background-color: $iron-100;
}

%table-row-separator {
  @include table-row-border('top');
}

// Sort icons
@mixin table-icon-sort-base {
  &::after {
    @extend .icon;
    @extend .icon-xs;
    margin-left: $spacer-2;
  }
}

@mixin table-icon-sortable {
  &::after {
    @extend %icon-reorder-before;
    color: $iron-300;
  }
}

@mixin table-icon-sort-asc {
  &::after {
    @extend %icon-reorder-up-before;
    color: $primary-magic-green;
  }
}

@mixin table-icon-sort-desc {
  &::after {
    @extend %icon-reorder-down-before;
    color: $primary-magic-green;
  }
}

@mixin table-header-sort-icons {
  .sorting,
  .sorting_asc,
  .sorting_desc {
    @include table-icon-sort-base;
    background-image: none;
  }

  .sorting {
    @include table-icon-sortable;
  }

  .sorting_asc {
    @include table-icon-sort-asc;
  }

  .sorting_desc {
    @include table-icon-sort-desc;
  }
}
