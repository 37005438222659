@import '~@estimateone/frontend-components/src/css/variables';

.packageOptionLabel {
  display: flex;
  justify-content: space-between;
}

.fullSetOption {
  color: $crimson-500;
}

.dueDate {
  white-space: nowrap;
}
