@import '~@estimateone/frontend-components/src/css/variables';

.noDataText {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $iron-600;
  font: $font-p-reg;
  font-style: italic;
  text-align: center;
}

.nonItalic {
  font-style: normal;
}
