// Sourced and adapted from app/Resources/css/_shared/loading_spinners.scss
@import '~@estimateone/frontend-components/src/css/variables';

.loadingSpinnerContainer {
  margin-top: $spacer-6;
}

.inline {
  display: inline;
}

.loadingSpinner {
  width: 56px;
  height: 56px;
  display: block;
  opacity: 0.9;
  background-color: transparent;
  background-repeat: no-repeat;

  &.dark {
    background-image: url('./img/ajax-loader-db.gif');
  }

  &.light {
    background-image: url('./img/ajax-loader-wb.gif');
  }

  &.centre {
    margin: 0 auto;
  }

  &.medium {
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
  }

  &.small {
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
  }

  &.tiny {
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }

  &.minute {
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
  }
}
