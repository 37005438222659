@import '~@estimateone/frontend-components/src/css/variables';

.confirmationModal {
  padding-top: $spacer-5;
  display: flex;
  justify-content: center;
  text-align: left;
}

.content {
  flex-basis: 70%;
}

.heading {
  margin: 0;
  color: $primary-magic-green;
  font-size: 4rem;
}

.subHeader {
  margin: $spacer-4 0;

  > h2 {
    margin: 0;
    font: $font-heading-3;
  }
}

.confirmationInfo {
  padding-bottom: $spacer-5;
  font: $font-p-reg;

  > p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    > strong {
      white-space: nowrap;
    }
  }
}

.supportHeading {
  margin: $spacer-3 0;
  font: $font-intro;
}
