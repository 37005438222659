@import '~@estimateone/frontend-components/src/css/variables';

.actionBar {
  margin-bottom: $space-16;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.actionBarTitle {
  margin-top: 0;
  margin-bottom: 0;
  font: $font-heading-3;
}

.loadingSection {
  height: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.packageInfo {
  display: flex;
  justify-content: space-between;
}

.statActions {
  margin: $space-8 0 0 0;
  display: flex;
  align-items: flex-start;
  gap: $space-16;
}

.sectionHeader {
  background-color: $steel;
}

.sectionMargin {
  margin-bottom: 2rem;
}

.awardToHeader {
  padding-bottom: $space-24;
}

.notesWidgetSection {
  padding-bottom: $space-32;
}
