@import '~@estimateone/frontend-components/src/css/variables';

.documentsHeader {
  margin-bottom: 1em;
  display: flex;
  flex-flow: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
}

.documentActionsContainer {
  display: flex;
  gap: $space-8;
}

.docsyncNotifications {
  display: block;
}
