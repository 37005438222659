@import '~@estimateone/frontend-components/src/css/variables';

.helpBanner {
  margin-bottom: $space-24;
}

.helpBannerFooter {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  gap: $space-16;
}

.helpBannerTitle {
  display: flex;
  flex-flow: row wrap;
  font-size: $font-regular;
  font-weight: $font-semibold;
  line-height: 1.5em;
  gap: $space-8;
}

.highlightedText {
  font-size: $font-regular;
  font-weight: $font-semibold;
}
