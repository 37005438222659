@import '../../../../css/_shared/variables';

.manualMergeItem {
  padding: $spacer-5 0;
  display: block;
  justify-content: space-between;
  border-bottom: 1px solid $iron-100;

  div {
    div {
      justify-content: center;
    }
  }
}

.manualMergeSearchCard {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
}

.manualMergeActions {
  margin: 0 $spacer-5;
  flex-basis: 15%;
  align-self: center;
  white-space: nowrap;

  div + div {
    margin-top: 5px;
  }
}

.company {
  padding: 0.25rem;
  list-style-type: none;
  text-decoration: none;

  &:hover {
    background-color: $magic-050;
    color: $practical-900;
  }

  a {
    padding: $spacer-1 0;
    display: flex;
    justify-content: space-between;
    color: $iron-600;
  }
}

.manualMergeButton {
  float: right;
}

.grey {
  color: $iron-600;
}

.searchCompanyHeader {
  margin: $spacer-2 0;
}
