@import '~@estimateone/frontend-components/src/css/variables';

.accordion {
  margin-bottom: $space-12;

  .accordionTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-8;

    span {
      font-size: $font-size-sm;
    }
  }

  .noDataText {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: $iron-600;
    font: $font-p-reg;
    font-style: italic;
    text-align: center;
  }

  .tendersTableContainer {
    overflow: hidden;

    .projectDetailsContainer {
      .tenderNameContainer {
        display: flex;
        gap: $space-8;
      }

      .tenderName {
        font: $font-ps-heavy;
      }

      .projectAddress {
        margin-top: $space-4;
        font: $font-ps-reg;
      }
    }
  }
}
