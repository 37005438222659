@import '~@estimateone/frontend-components/src/css/variables';

.new-company-profile {
  margin: $spacer-4 0 0 0;

  .large-text {
    margin: $spacer-2 0 $spacer-4 0;
    display: inline-block;
    font: $font-heading-3;
  }

  .row {
    margin-bottom: $spacer-4;
  }
}

.profile-wrapper {
  display: grid;
  flex: 1 0 auto;
  grid-template-rows: min-content auto min-content;
}

/**
 * Eggy note, 20-v-2022
 * This is an alpha measure to get us across the line for Profiles
 * The end goal is to create a React component to replace the header
 * This task already exists in the subbie team backlog
 */

.profiles-mfe-wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;

  .header {
    padding: 1rem 0;

    .company-menu {
      margin-bottom: 0;
      align-items: center;
    }
  }
}

%profileOnboardingMFEForm {
  h1[class^='ProfileOnboarding-module__formHeader'] {
    margin: $space-12 0;
    color: $primary-practical-blue;
  }
}

.splash .subbie-profile-onboarding-mfe {
  @extend %profileOnboardingMFEForm;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 1px 8px rgb(0 0 0 / 12%),
    0 3px 4px rgb(0 0 0 / 14%),
    0 3px 3px -2px rgb(0 0 0 / 20%);
  background: white;
  text-align: left;
}

.subbie-profile-onboarding-mfe-for-directory-opt-in {
  @extend %profileOnboardingMFEForm;
}
