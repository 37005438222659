@import '~@estimateone/frontend-components/src/css/variables';

p.dueDateDisclaimer {
  color: $iron-600;
  font-size: .8em;
}

.quotesDueDate {
  flex-grow: 1;
  font: $font-ps-reg;
  white-space: nowrap;

  .quotesDueText {
    float: right;
  }
}
