@import '~@estimateone/frontend-components/src/css/variables';

.title {
  margin-bottom: $space-16;
  font: $font-heading-3;
}

.addressBookMessage {
  margin-bottom: $space-48;
  display: flex;
  column-gap: $space-4;
  align-items: center;
}
