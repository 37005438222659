@import '~@estimateone/frontend-components/src/css/variables';

.header {
  margin-bottom: $space-24;
  display: grid;
  grid-template-rows: auto;
  gap: $space-24;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.footer {
  display: grid;
  grid-template-rows: auto;
  gap: $space-24;

  p {
    margin: 0;
    font: $font-ps-reg;
  }
}

.buttonContainer {
  margin-top: $space-48;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-16;
}
