@import '~@estimateone/frontend-components/src/css/variables';

.alert {
  display: grid;
  align-items: flex-start;
  grid-template-columns: auto 1fr;
  gap: $space-16;

  p {
    margin: 0;
    font-weight: $font-regular;

    strong {
      font: $font-p-heavy;
    }
  }
}
