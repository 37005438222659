@import '~@estimateone/frontend-components/src/css/variables';

.notesPane {
  display: flex;
}

.latestNote {
  width: 144px;
  margin-left: $space-12;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.placeholder {
  width: 144px;
  margin-left: $space-12;
  overflow: hidden;
  color: $iron-400;
  font: $font-ps-reg;
  text-align: left;
  text-overflow: ellipsis;
}

.notesButton:focus .notesIconEmpty {
  stroke: $practical-600;
  fill: $practical-600;
}
