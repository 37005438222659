@import '~@estimateone/frontend-components/src/css/variables';

.noteHistoryItemContainer {
  padding: $space-12;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: $iron-025;
  gap: $space-8;
}

.noteHistoryItemNoteTextContainer {
  display: flex;
}

.noteHistoryItemFlag {
  padding-top: $space-2;
  padding-right: $space-8;
}

.noteHistoryItemNoteText {
  color: $primary-practical-blue;
  font: $font-p-reg;
  line-height: 150%;
}

.noteHistoryItemNoteDetails {
  color: $iron-600;
  font: $font-tiny-reg;
}
