@import '~@estimateone/frontend-components/src/css/variables';

.emptyStateHelpContent {
  padding-top: 2rem;

  i {
    font-size: 64px;
  }
}

.successContent {
  display: flex;
  align-items: center;
}
