.upgrade-container {
  .upgrade-container-wrapper {
    width: 1240px !important;
    margin: 0 auto;
    padding: 10px 35px 0;
  }

  .centered {
    margin: 0 auto;
  }

  .wide-gray-banner {
    margin-top: -25px;
    margin-right: -50%;
    margin-left: -50%;
    background-color: rgb(238 243 247 / 80%);
  }

  .upgrade-title {
    margin-bottom: 25px;

    &.max-width {
      width: 430px;
    }

    &.text-centered {
      text-align: center;
    }

    &.short-title {
      h1 {
        padding-top: 0;
        color: $san-juan;
        font-size: 36px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
      }

      h5 {
        color: $tundora;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
  }

  .testimonial-container {
    padding: 30px 0 0 15px;

    &.no-heading {
      padding-top: 0;
    }

    &.max-width {
      p {
        max-width: 430px;
      }
    }

    .testimonial {
      max-width: 575px;
      padding: 25px 0 10px;

      .testimonial-message {
        color: $mako;
        font-size: 14px;
        line-height: 20px;
      }

      .testimonial-author-details {
        padding-top: 15px;

        .testimonial-author-name {
          margin-left: -20px;

          p {
            padding-top: 10px;
            color: $san-juan;
            font-size: 12px;
            font-weight: bold;
            line-height: 25px;
          }
        }

        .testimonial-author-avatar {
          display: inline-block;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
          }
        }
      }

      .testimonial-company-icon {
        img {
          width: 72px;
          height: 72px;
          border: 1px solid rgb(184 195 212 / 40%);
          border-radius: 50%;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }

  .product-option-container {
    padding-top: 25px;

    .membership {
      .product {
        max-width: 550px;
        margin: 25px 0 20px;
        padding: 15px;
        border: 1px solid rgb(184 195 212 / 26%);
        border-radius: 8px;
        background: linear-gradient(180deg, $white 0%, $catskill-white 100%);

        &.product-feature-badge:after {
          position: absolute;
          z-index: 1;
          top: -25px;
          right: -25px;
          width: 50px;
          height: 50px;
          padding-top: 8px;
          vertical-align: middle;
          border-radius: 50%;
          background-color: $cerulean;
          color: white;
          font-size: 11px;
          text-align: center;
          text-transform: uppercase;
        }

        &.best-value:after {
          content: 'best value';
        }

        &.two-months-free:after {
          content: 'sale!';
          font-size: 12px;
          line-height: 3em;
        }
      }

      .mem-price {
        padding-right: 0;
        padding-left: 10px;
        border-left: 1px solid rgb(184 195 212 / 26%);

        span {
          margin: 0;
          padding: 0;
          display: inline-block;

          &.dollar {
            color: $san-juan;
            font-size: 36px;
            font-weight: bold;
            line-height: 46px;
          }

          &.cent {
            vertical-align: top;
            color: $san-juan;
            font-size: 14px;
            font-weight: bold;
            line-height: 33px;
          }

          &.frequency {
            display: inline-block;
            font-size: 17px;
          }
        }
      }

      .extra-details {
        div {
          display: inline-block;
          line-height: 14px;

          &.gst {
            padding-right: 10px;
            border-right: 1px solid $wistful;
            color: $mako;
            font-size: 14px;
            font-weight: bold;
          }

          &.muted {
            padding-left: 5px;
          }
        }
      }

      a {
        &.btn-purchase {
          width: 100%;
          margin-top: 20px;
          padding: 7px;
          border: none;
        }
      }
    }

    .for {
      padding: 0 3px;
      color: $dusty-gray;
      font-size: 12px;
      line-height: 16px;
    }

    .usual-price {
      padding-top: 12px;
      font-weight: bold;
    }

    .select2-container {
      display: inline-block;
    }
  }

  .benefits-container {
    margin-top: 30px;
    padding: 0 40px 0 35px;

    @media (min-width: 768px) {
      min-height: 475px;
    }

    .benefits {
      padding: 25px 25px 10px 15px;

      .benefits-icon {
        float: left;
        width: 10%;
        display: inline-block;
        text-align: center;

        img {
          padding-top: 25px;
        }
      }

      .benefits-message {
        float: right;
        width: 85%;
        display: inline-block;

        h4 {
          margin-top: 0;
          margin-bottom: 8px;
          color: $san-juan;
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
        }
      }
    }
  }

  .border-right {
    border-right: 1px solid rgb(184 195 212 / 26%);
  }

  .left-buffer {
    padding-left: 15px;
  }

  .bottom-buffer {
    padding-bottom: 20px;
  }

  h1 {
    padding-top: 50px;
    color: $san-juan;
    font-size: 36px;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: -1px;
  }

  h2 {
    margin-bottom: 10px;
    color: $san-juan;
    font-size: 19px;
    font-weight: bold;
    line-height: 21px;

    &.upgrade-options {
      display: inline-block;
    }
  }

  h3 {
    &.pricing-title {
      color: $san-juan;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      text-align: center;
    }
  }

  h4 {
    margin-bottom: 10px;
  }

  h5 {
    color: $mako;
    font-size: 19px;
    line-height: 26px;
  }

  p {
    margin-bottom: 8px;
    color: $mako;
    font-size: 13px;
    line-height: 17px;
  }
}

.non-ab-upgrade-template {
  ul.membership {
    padding: 25px 0 20px;

    h3 {
      margin-left: 35px;
      padding: 8px 15px;
      display: inline-block;
      border-bottom: 1px solid $alto;
      border-radius: 4px 4px 0 0;
      background: $ebony-clay;
      color: $white;
      font-size: 12px;

      span {
        color: $silver;
      }
    }

    li {
      margin-bottom: 24px;
      padding: 25px 25px 25px 30px;
      list-style: none;
      background: $gallery;

      .mem-price {
        float: left;
        min-width: 120px;
        display: block;
        font-family: 'Arial', 'Helvetica', sans-serif;
        font-size: 32px;
        font-weight: bold;

        span {
          color: $silver-chalice;
          font-size: 22px;
        }
      }

      .mem-info {
        float: left;
        padding-top: 5px;
      }
    }

    a.select-btn {
      float: right;
      width: 139px;
      height: 47px;
      margin-top: 5px;
      display: block;
      background: url('#{$image-path}select-btn-trans.png') right top no-repeat;
      text-indent: -9999px;
    }
  }
}

.purchase-description {
  padding-bottom: 10px;

  h1 {
    padding-bottom: 15px;
    color: $san-juan;
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
  }

  p {
    padding-top: 15px;
    color: $tundora;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  a {
    color: $matisse;
    font-weight: bold;
    text-decoration: underline;
  }
}

.pay_payment_options {
  .form-group {
    display: inline-block;
  }
}

.pay_creditcard {
  .panel {
    h2 {
      padding: 15px 25px;

      i {
        float: right;
        margin-top: -3px;

        &:before {
          font-size: 22px;
        }
      }

      img {
        float: right;
        width: 50px;
        margin-top: -8px;
        margin-left: 10px;

        &.mastercard {
          width: 42px;
        }
      }
    }
  }
}

$comparison-col-width: 233px;

div.builder-upgrade-container {
  margin-top: 50px;

  div.empower-content {
    h1 {
      color: $astronaut;
      font-size: 36px;
      font-weight: bold;
    }

    p {
      width: $comparison-col-width * 3;
      margin: 30px auto;
      color: $tundora;
      font-size: 16px;
    }
  }

  table.builder-upgrades-table {
    td.questions-contact {
      min-width: 280px;
      padding-left: 19px;
      text-align: left;

      div {
        padding: 15px;
        border: 1px dotted $silver;
        border-radius: 4px;

        i.icon-top-right {
          float: right;
          margin-top: -7px;
          margin-right: -7px;
          color: $silver;
          font-size: 20px;
        }

        h4 {
          margin-bottom: 1.2em;
          font-weight: bold;
        }

        p,
        h4 {
          color: $dove-gray;
          font-size: 14px;
        }

        p {
          line-height: 21px;

          &:not(:last-child) {
            margin-bottom: 1.2em;
          }

          a.support-tel {
            font-weight: bold;
          }

          a.support-email {
            color: $astral;
          }
        }
      }
    }
  }

  div.testimonials {
    width: $comparison-col-width * 3;
    margin: 60px auto 0;

    h4 {
      margin-bottom: 20px;
      color: $san-juan;
      font-size: 19px;
    }

    p {
      font-size: 13px;
    }
  }

  ul.builder-client-logos {
    margin-top: 20px;
    text-align: center;

    li {
      margin-right: 110px;
      display: inline-block;
      list-style: none;

      &:last-child {
        margin: 0;
      }
    }
  }
}

div.comparison-table-container {
  table.comparison-table {
    &.rounded-corner-stripes {
      tbody {
        tr:nth-child(odd) {
          td {
            span {
              background-color: $athens-gray;
            }
          }
        }
      }
    }

    th,
    td {
      &.table-inner-top-left {
        border-top-left-radius: 5px;
      }

      &.table-inner-top-right {
        border-top-right-radius: 5px;
      }

      &.table-inner-bottom-left {
        border-bottom-left-radius: 5px;
      }

      &.table-inner-bottom-right {
        border-bottom-right-radius: 5px;
      }
    }

    thead tr {
      th {
        border-right: 1px solid $alto;
        border-left: 1px solid $alto;
        box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 25%);
        text-align: center;

        &:first-child {
          border: none;
          box-shadow: inset -5px 0 11px -8px black;
        }

        &:last-child {
          border: none;
          box-shadow: inset 5px 0 11px -8px black;
        }

        img {
          margin-top: 10px;
          margin-bottom: 19px;
        }
      }
    }

    tbody tr {
      &:first-child {
        td:last-child {
          box-shadow: inset 5px 0 11px -8px black;
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid $alto;
          box-shadow: 0 2px 2px 0 rgb(0 0 0 / 25%);

          &:first-child {
            border-bottom-left-radius: 4px;
          }
        }

        th {
          border: none;
        }
      }

      &.plans {
        span {
          padding-top: 16px;
          padding-bottom: 12px;
          display: block;
          border: 5px solid white;
          color: $tundora;
          font-size: 16px;
          font-weight: bold;
        }
      }

      &.pricing {
        td {
          padding-bottom: 12px;

          span.price {
            height: 85px;
            display: block;
            color: $astronaut;
            font-size: 36px;
            font-weight: bold;

            &.annual-price {
              &:after {
                content: 'per year';
                display: block;
                font-size: 14px;
              }
            }

            .excluding-tax {
              font-size: 0.4em;
            }
          }

          button {
            width: 195px;
            padding-top: 11px;
            padding-bottom: 11px;
            font-size: 16px;
          }
        }
      }

      &.product-feature {
        td[class^='feature-'] {
          &.feature-excluded {
            span::after {
              background-position-x: -30px;
            }
          }

          span {
            width: 100%;
            height: 66px;
            padding-top: 15px;
            display: inline-block;
            border: 5px solid white;
            border-radius: 1px;

            &::after {
              content: '\00a0';
              width: 30px;
              height: 25px;
              display: inline-block;
              background-image: url('#{$image-path}sprite_tick_cross.png');
              background-repeat: no-repeat;
            }
          }
        }
      }

      th {
        position: relative;
        min-width: 280px;
        padding: 14px 50px 14px 20px;
        border-top: 1px dotted $silver;
        border-bottom: 1px dotted $silver;
        box-shadow: inset -5px 0 11px -8px black;
        font-size: 14px;
        font-weight: normal;
        text-align: left;

        i.icon {
          position: absolute;
          top: 7px;
          right: 7px;
          cursor: help;
          opacity: 0.5;
          color: $astral;
          font-size: 20px;
        }
      }

      td {
        min-width: $comparison-col-width;
        text-align: center;

        &:first-child {
          width: 280px;
        }

        &:not(:last-child) {
          border-right: 1px solid $alto;
          border-left: 1px solid $alto;
        }

        &.column-bottom {
          vertical-align: bottom;
        }
      }
    }
  }
}

.tooltipster-default {
  &.tooltipster-builder-upgrade {
    max-width: 260px;

    div.tooltipster-content {
      width: 100%;
      padding: 5px 8px;
      display: inline-block;
      font-size: 12px !important;
      font-weight: normal;
      line-height: 12px !important;
    }
  }
}
