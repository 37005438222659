@import '../../../../css/_shared/legacy/colours';

.manual-address-trigger {
  float: right;
  margin-top: -8px;
  margin-left: 8px;
  padding: 0;
  border: none;
  border-bottom: 1px dotted $silver;
  background: none;
  color: $bali-hai;
  font-size: 11px;
  text-decoration: none;
}
