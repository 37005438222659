@import '~@estimateone/frontend-components/src/css/variables';

.formField {
  margin-top: $space-24;
  /* stylelint-disable selector-pseudo-class-disallowed-list */
  & > :global(.react-datepicker-wrapper) {
    display: block;
  }
}

.dateFieldText {
  padding-top: $space-64;
  font: $font-tiny-reg;
}

.dateFieldTextBold {
  font: $font-tiny-heavy;
}
