$pie-chart-size: 64px;

.results-container {
  gap: $space-16;
}

.results-summary {
  padding: $spacer-3;
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  background: $steel;
  color: $primary-link-blue;
  text-align: center;

  .response-item {
    margin: $spacer-2 0;
  }

  .caption {
    margin-top: $spacer-2;
    color: $primary-practical-blue;
    font: $font-ps-reg;
  }

  .counter {
    color: inherit;
    font-size: 48px;
    font-weight: $font-semibold;
    line-height: $pie-chart-size;
  }
}

.results-summary-new {
  .response-item {
    margin: 0;
  }

  .caption {
    margin-top: $spacer-1;
    font-size: 12px;
  }

  .counter {
    font-size: 32px;
    line-height: unset;
  }

  .divider {
    border-left: 1px solid $iron-200;
  }
}

.results-summary-engagement {
  justify-content: unset;
}

.results-summary-right {
  margin-left: auto;
}

.results-summary-left {
  flex-grow: 1;
}
