@import '~@estimateone/frontend-components/src/css/variables';

.coverageIndicator {
  width: 6px;
  height: 16px;
  margin-right: 2px;
  display: inline-block;
  border-radius: 3px;
}

.rfqsQuoted {
  border: 1px solid $primary-magic-green;
  background-color: $primary-magic-green;
}

.rfqsQuoting {
  border: 1px solid $primary-magic-green;
  background-color: $magic-100;
}

.rfqsNotQuoting {
  border: 1px solid $iron-400;
  background-color: $iron-400;
}

.rfqsNoResponse {
  border: 1px solid $iron-400;
  background-color: $white;
}
