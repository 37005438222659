.password-strength-container {
  .password-strength {
    width: 100%;
    height: 3px;
    border-radius: 16px;
    background-color: $heather;

    .password-strength-bar {
      float: left;
      width: 0;
      height: 100%;
    }

    .weak {
      background-color: $chestnut-rose;
    }

    .medium {
      background-color: $saffron;
    }

    .good {
      background-color: $dusty-gray;
    }

    .great {
      background-color: $caribbean-green;
    }
  }

  .description {
    margin-top: 2px;
  }
}
