@import '~@estimateone/frontend-components/src/css/variables';

$min-screen-width: 1152px;

@mixin page-layout() {
  display: grid;
  column-gap: $space-24;

  // doesn't really matter what this is since it's not supported:
  grid-template-columns: $space-64 1fr 2fr $space-64;

  @media (min-width: $min-screen-width) {
    grid-template-columns: 1fr 300px 600px 1fr;
  }
}

@mixin profile-page-layout() {
  @include page-layout;
  margin: $space-32 $space-32 0;

  // doesn't really matter what this is since it's not supported:
  grid-template-columns: $space-64 1fr 1fr $space-64;
  font-family: $default-font-family;
  gap: $space-32 0;

  & > * {
    grid-column: 2 / 4;
  }

  @media (min-width: $min-screen-width) {
    grid-template-columns: 1fr 340px 340px 1fr;
  }
}

.profileSettings {
  @include profile-page-layout;
}

.profileInsights {
  @include profile-page-layout;
  grid-template-columns: 1fr 480px 480px 1fr;

  @media (min-width: $min-screen-width) {
    grid-template-columns: 1fr 480px 480px 1fr;
  }
}

.companyProfile {
  @include page-layout;
  margin: $space-32 $space-32 0;
  grid-template-areas: '. profileHeader profileHeader .'
    '. profileBadges profileBadges .'
    '. aside profileContent .';
  grid-template-rows: auto;
  row-gap: $space-40;

  &.sliderCompanyProfile {
    margin: 0;
    column-gap: 0;
    grid-template-areas: '. profileBadges profileBadges .'
      '. aside profileContent .';
    grid-template-columns: auto 1fr 2fr auto;
  }
}
