@import '~@estimateone/frontend-components/src/css/variables';

.keywordMatchTable {
  .rowStyles:hover td:first-child {
    background-color: white;
  }

  .keyword {
    font: $font-ps-heavy;

    :hover {
      background-color: white;
    }
  }

  .extract {
    color: $iron-600;

    em {
      color: $practical-900;
      font-style: normal;
    }
  }
}

.refineKeywordsLink {
  margin-bottom: $space-8;
  margin-left: auto;

  .refineKeywordsLinkText {
    font: $font-ps-heavy;
  }
}
