.loading-spinner-db {
  width: 56px;
  height: 56px;
  margin: auto;
  display: block;
  opacity: 0.9;
  background: url('#{$image-path}ajax-loader-db.gif') no-repeat transparent;
}

.loading-spinner-wb {
  width: 56px;
  height: 56px;
  margin: auto;
  display: block;
  opacity: 0.9;
  background: url('#{$image-path}ajax-loader-wb.gif') no-repeat transparent;
}

.loading-spinner-minute {
  width: 13px;
  height: 13px;
  background-size: 13px 13px;
}

.loading-spinner-tiny {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
}

.loading-spinner-small {
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
}

.loading-spinner-medium {
  width: 35px;
  height: 35px;
  background-size: 35px 35px;
}

.loading-spinner-minute,
.loading-spinner-tiny,
.loading-spinner-small,
.loading-spinner-medium {
  &.central {
    width: 100%;
    height: 100%;
    background-position: center center;
  }

  &.inline {
    display: inline-block;
  }
}
