.super-slider-outer-wrapper {
  position: fixed;
  z-index: 50;
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
  background-color: $primary-practical-blue;
  inset: 0;
}

.super-slider-outer-container {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: -1400px;
  height: 100%;
  display: block;
  transition: right 0.3s ease-in;

  .super-slider-outer-dismiss {
    position: absolute;
    z-index: 2000;
    top: 12px;
    left: calc(-1rem - 20px);
    opacity: 0.9;
    border: none;
    background: none;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .super-slider-outer {
    z-index: 300;
    width: $slider-width;
    height: 100%;
    overflow: hidden;
    background: $white;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -100px;
      margin-left: -50px;
      color: $iron-600;
      font-size: 4rem;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.super-slider-header {
  padding: $spacer-5;
  background: $primary-practical-blue;
  color: $white;

  &.has-nav {
    padding-bottom: 0;
  }

  h2 {
    margin: $spacer-1 0;
    color: inherit;
  }

  .abcompany-id,
  .correspondence-reference {
    opacity: 0.6;
    font: $font-ps-heavy;
  }

  .has-hiding-btn {
    min-height: 2.6rem;
  }

  .nav-tabs {
    @include slider-header-nav-tabs;
  }
}

.super-slider-sub-bar {
  padding: $spacer-4 $spacer-5;
  background-color: $steel;
}

.super-slider-body {
  padding: $spacer-5;
  overflow: auto;
  font: $font-p-reg;

  &.no-top-space {
    padding-top: 0;
  }

  .alert {
    margin-bottom: $spacer-5;
  }

  .nav-tabs {
    @include slider-body-nav-tabs;
  }

  .section-header {
    @include section-header;
  }

  .section-header--first {
    margin-top: 0;
  }

  .d-table-row {
    label {
      padding: $spacer-2 $spacer-5 $spacer-2 0;
    }
  }
}

.super-slider-footer {
  padding: $spacer-3 $spacer-5;
  background-color: $primary-practical-blue;
}

body.super-slider-active {
  .super-slider-instance.active .super-slider-outer-wrapper {
    right: 0;
    display: block;
    visibility: visible;
    opacity: 0.5;
  }

  .super-slider-instance.active .super-slider-outer-container {
    right: 0;
  }
}

#rfq-super-slider {
  position: relative;
  z-index: 1010;
}

html.super-slider-body-scrolling {
  overflow: hidden;
}
