@import '~@estimateone/frontend-components/src/css/variables';

.header {
  max-width: 100%;
  height: $space-48;
  display: flex;
  align-items: center;
}

.headerLeft {
  margin-left: $space-16;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.headerLeftSpan {
  margin-left: $space-12;
  color: $practical-900;
  font: $font-p-reg;
}

.headerRight {
  margin-right: $space-16;
  display: flex;
  justify-content: flex-end;
}

.alertBanner {
  margin-bottom: $space-32;
  padding: $space-4;
}
