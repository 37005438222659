@import '~@estimateone/frontend-components/src/css/variables';

.wrapper {
  display: grid;
  gap: $space-8;

  label {
    font: $font-p-reg;
  }
}

.radioButtonContent {
  margin: $space-8 0 0 $space-32;
  display: none;

  &.show {
    display: block;
  }
}
