@import '~@estimateone/frontend-components/src/css/variables';

.accountSettingsInfoCard {
  width: 320px;
  padding: $space-24;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: $steel;
  color: $primary-practical-blue;
  font: $font-tiny-reg;
  gap: 16px;
}

.accountSettingsInfoCardHeading {
  font: $font-p-heavy;
}
