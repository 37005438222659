@import '~@estimateone/frontend-components/src/css/variables';

.heading {
  margin-top: 0;
  margin-bottom: 0;
  color: $primary-practical-blue;
  font: $font-heading-2;
}

.companyNameContent {
  margin-top: $space-24;
  margin-bottom: $space-4;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.headingWithoutEdit {
  margin-top: $space-24;
  margin-bottom: 0;
  color: $primary-practical-blue;
  font: $font-heading-2;
}

.divide {
  color: $steel;
}

.createdAt {
  align-self: start;
  color: $primary-practical-blue;
  font: $font-tiny-reg;
}

.headingWrapper {
  display: grid;
  align-items: center;
  grid-template: 100px / 100px auto; // to accomodate logo sizing
  gap: $space-32;
}

.logo {
  max-width: 100px;
  max-height: 100px;
  justify-self: center;
}
