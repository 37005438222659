@import '~@estimateone/frontend-components/src/css/variables';

.leftNavPanel {
  box-sizing: border-box;
  min-width: 240px;
  max-width: 240px;
  height: 100vh;
  padding: $space-16 35px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid $iron-200;

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin-right: unset;
  }

  nav ul li a {
    border-bottom: unset;
  }
}

.insightsNav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.insightsNavTag {
  margin: $space-4 0 0 $space-4;
}
