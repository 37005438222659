.otherSubTradesContainer {
  width: auto;
  max-height: 350px;
  margin-left: -30px; /* gutter size offset */
  display: flex;
  overflow-y: scroll;

  .subTradesMasonryColumn {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
}

.onboardingFiltersImage {
  width: 60%;
  margin: 0 auto;
  border-radius: 40px;
  box-shadow: 0 0 8px #aaaaaa;
}
