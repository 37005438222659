@import '~@estimateone/frontend-components/src/css/variables';

.creditCardForm {
  .ewayIframeWrapper {
    height: 35px;

    > div {
      height: 35px;
    }
  }
}

.acceptedCards {
  font: $font-ps-reg;
}
