@import '~@estimateone/frontend-components/src/css/variables';

.actionContainer {
  display: flex;
  justify-content: space-between;
}

.notifyStepTableHeading {
  margin: 0;
  font: $font-heading-3;
}

.notifyStepTableHeadingToggleContainer {
  display: flex;
  align-items: baseline;
  gap: $space-8;

  p {
    margin-bottom: 0;
  }
}

.notifyStepTableHeadingHelp {
  display: flex;
  align-items: center;
  gap: 6px;
}

.notifyStepPreviewHeading {
  margin: 0 0 $space-24;
  font: $font-heading-3;
}

.notifyTableHeaderCheckbox {
  margin: 0 auto;

  svg {
    margin-top: -6px;
    margin-left: 0;
  }
}

.notifyStepMessageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notifyStepEmailSubject {
  width: 100%;
}

.notifyStepEmailMessageContainer {
  margin-top: $space-24;
  display: flex;
  flex-direction: column;
}

.notifyStepEmailMessage {
  width: 100%;
  margin-top: $space-8;
  border: 2px solid $iron-100;
  border-radius: 4px;
}

.notifyStepEmailMessageError {
  border-color: $crimson-600;
}

.notifyStepEmailMessage:focus-within {
  border-color: $primary-practical-blue;
}

.notifyTableHeaderCheckboxColumn {
  max-width: 120px;
  display: flex;
  flex-direction: column;
  gap: $space-8;
}

.notifyTableHeaderSubText {
  font-weight: normal;
}

.notifyTableHeaderCheckboxColumnCheck {
  margin: auto;

  svg {
    margin-left: -18px;
  }
}

.notifyTablePackageTitleContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $space-8;
}

.notifyStepEmptyStateText {
  color: $iron-600;
  font-style: italic;
}

.notifyStepNoPackagesEmptyStateText {
  margin-top: $space-16;
  color: $iron-600;
  font-style: italic;
  text-align: center;
}

.notifyStepDisabledData {
  color: $iron-300;
}

.submitButtonContainer {
  display: flex;
  gap: $space-16;
}

.notificationCellNumbers {
  max-width: 120px;
  margin-right: $space-32;
}

.notifyStepEmailMessageErrorText {
  margin-top: $space-8;
  color: $crimson-600;
  font-size: $font-size-sm;
  font-weight: $font-semibold;
  line-height: 1.5;
}

.fullHeightWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: none;
}
