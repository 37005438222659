@import '~@estimateone/frontend-components/src/css/variables';

.statsPanelContainer {
  padding: $space-24;
  display: flex;
  justify-content: space-around;
  border-radius: $space-8;
  background-color: $steel;
}

.statsPanelDivider {
  width: $space-2;
  opacity: 0.5;
  background-color: $iron-200;
}

.statNumberContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: $space-8;
}

.statNumber {
  color: $practical-500;
  font-size: 3rem;
  font-weight: $font-semibold;
  line-height: 1;
}

.statChartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-8;
}

.percentChart {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $practical-500;
  font-weight: $font-semibold;
}

.statsPanelHelp {
  margin-top: $space-8;
  display: flex;
  justify-content: flex-end;
  font-size: $font-size-sm;
  line-height: 150%;
  gap: $space-4;
}

.statNumberLabel {
  font-size: $font-size-sm;
}
