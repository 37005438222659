@import '~@estimateone/frontend-components/src/css/variables';

.suggestCorrectionSection {
  font: $font-tiny-reg;

  .collapsibleContent {
    &.isCollapsed {
      display: none;
    }
  }

  .textarea {
    width: 100%;
    padding: $spacer-3;
    border: 2px solid $iron-100;
    border-radius: 4px;
    font: $font-p-reg;
  }
}
