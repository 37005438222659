@import '~@estimateone/frontend-components/src/css/variables';

.procoreConnectModalContainer {
  display: flex;
  flex-direction: column;
  color: $primary-practical-blue;
  font: $font-p-reg;
}

.procoreConnectModalHeading {
  margin-top: 0 !important;
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.procoreConnectModalDescription {
  margin-bottom: $space-16;
  font: $font-p-reg;
}

.procoreConnectModalList {
  margin-bottom: $space-24;

  li {
    margin-bottom: $space-4 !important;
  }
}

.procoreConnectModalDescriptionLink {
  font: $font-p-med;
}

.procoreConnectModalButtonContainer {
  margin-top: $space-40;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}
