@import '~@estimateone/frontend-components/src/css/variables';

.exportPackageRegisterHeading {
  margin: $space-16 0 0;
  font: $font-heading-2;
}

.exportPackageRegisterBody {
  margin: $space-48 0;
  font: $font-p-reg;
}

.exportPackageRegisterDescription {
  margin: 0 0 $space-32;
}

.exportPackageRegisterFooter {
  display: flex;
  justify-content: space-between;

  button {
    width: 248px;
    height: $space-48;
  }
}

.exportPackageRegisterAddendum {
  margin-bottom: $space-32;
}

.exportPackageRegisterAddendumWarning {
  font: $font-p-heavy;
}

.exportPackageRegisterAddendumInfo {
  font: $font-p-reg;
}
