@import '~@estimateone/frontend-components/src/css/variables';

.tooltip {
  position: absolute;
  min-width: 142px;
  border-radius: $space-4;

  .tooltipBody {
    padding: $space-16;
    border-radius: $space-4;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%),
      0 3px 4px 0 rgb(0 0 0 / 14%),
      0 1px 8px 0 rgb(0 0 0 / 12%);
    background-color: $white;
  }

  .indicator {
    width: $space-8;
    height: $space-16;
    border-radius: $space-4;
    background-color: $magic-700;
  }

  .viewsContainer {
    display: flex;
    align-items: center;
    font: $font-ps-reg;
    gap: $space-4;
  }

  .month {
    margin-top: $space-12;
    color: $iron-600;
    font: $font-tooltip;
  }
}
