@import '~@estimateone/frontend-components/src/css/variables';

$bar-height: 3.5rem; // line height + 1rem padding each side

.container {
  height: $bar-height;
  margin: $spacer-2 0;
  overflow: hidden;
}

.bar {
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px $iron-100;

  .warning & {
    box-shadow: inset 0 0 0 2px $energy-600;
  }

  .danger & {
    box-shadow: inset 0 0 0 2px $color-error;
  }

  .fill {
    height: $bar-height;
    padding: $spacer-3 0;
    transition: width .4s;
    border-radius: 4px;
    background: $primary-magic-green;
    font: $font-p-heavy;

    .warning & {
      background: $energy-050;
    }

    .danger & {
      background: $crimson-050;
    }
  }

  .content {
    margin-top: -$bar-height;
    padding: $spacer-3;
    display: flex;
  }

  .icon {
    width: $spacer-5;

    svg {
      vertical-align: middle;
    }
  }

  .message {
    margin-right: $spacer-3;
    font: $font-p-heavy;
  }

  .errorToggle {
    margin-left: $spacer-3;
  }
}

.errorsContainer {
  margin-top: $spacer-3;
}

.errors {
  list-style: none;

  li {
    margin-top: $spacer-3;

    &:first-child {
      margin-top: 0;
    }
  }
}

.autoUnzipContainer {
  text-align: right;
}
