@import '~@estimateone/frontend-components/src/css/variables';

.bannerContainer {
  margin-bottom: -$space-24;
  padding: $space-12 $space-24;
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid $iron-100;
  background-color: $ground;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mainText {
  margin-bottom: 0;
  font: $font-p-med;
}

.learnMoreLink {
  font: $font-p-reg;
}
