@import '~@estimateone/frontend-components/src/css/variables';

.heading {
  margin: 0;
  padding: 0;
}

.hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
}
