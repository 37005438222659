@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeader {
  padding: $spacer-5;
  background: $primary-practical-blue;
  color: $white;

  h2 {
    margin: $spacer-1 0;
  }

  .watchlistSelector {
    color: $primary-practical-blue;
  }
}

.sliderBack {
  align-items: flex-start;
}

.sliderBackButton {
  display: flex;
  flex-direction: row;

  ul {
    margin: 0 $spacer-4;
  }
}

.leftIcon {
  color: #ffffff;
  font: $font-ps-heavy;
}

.backText {
  color: #ffffff;
  font: $font-ps-heavy;
}
