@import '~@estimateone/frontend-components/src/css/variables';

.contractSizeWrapper {
  width: 100%;
  display: flex;
  gap: $space-16;

  > div {
    width: 100%;
  }
}
