@import '~@estimateone/frontend-components/src/css/variables';

.addNewOption {
  color: $primary-link-blue;
  font-weight: $font-semibold;
}

.companyContactLabel {
  margin-right: $spacer-2;
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;

    li {
      display: inline;
      line-height: 1;

      & + li {
        margin-left: $spacer-3;
      }
    }
  }
}

.selectContainer {
  display: flex;
  flex-direction: column;

  &.hasError {
    /* stylelint-disable selector-pseudo-class-disallowed-list */
    :global(.quote-ab-company-select__control) {
      border-color: $color-error;
    }
  }

  .hasLabel {
    margin-top: $spacer-2;
  }
}
