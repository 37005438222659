@import '~@estimateone/frontend-components/src/css/variables';

.licenseTable {
  width: 100%;
  border-collapse: collapse;

  thead tr th {
    padding: $spacer-3;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $iron-050;
    font: $font-tiny-heavy;
  }

  tbody tr {
    &:hover {
      td {
        background-color: $magic-100;
      }
    }

    td {
      padding: $spacer-3;
      vertical-align: top;
      border-bottom: $iron-050 solid 1px;
      font: $font-ps-reg;

      .sharedLicenseHeading {
        margin-right: $spacer-3;
        display: inline-block;
      }

      div {
        &:not(:last-child) {
          margin-bottom: $spacer-2;
        }

        .productNameSpan {
          margin-right: $spacer-2;
        }
      }

      .upgradeText {
        margin-right: $spacer-2;
        color: $primary-magic-green;
        font-weight: bold;
      }

      .preDiscountedPrice {
        margin-right: $spacer-2;
        text-decoration: line-through;
      }

      .postDiscountedPrice {
        color: $primary-magic-green;
      }

      .creditCardLastFourDigitsText {
        margin-right: $spacer-2;
      }

      .discountTag {
        margin-right: $spacer-1;
      }
    }
  }
}
