@import '~@estimateone/frontend-components/src/css/variables';

.aboutUsSection {
  margin-bottom: $space-24;
}

.aboutUsHeadingWrapper {
  margin-bottom: $space-16;
  display: flex;
  align-items: center;
}

.aboutUsHeader {
  margin: 0 $space-40 0 0;
  color: $primary-practical-blue;
  font: $font-p-heavy;
}

.aboutUsSkeleton {
  margin-bottom: $space-40;
}

.aboutUsEmptyContent {
  color: $iron-600;
  font: $font-ps-reg;
}

.aboutUsEmptyContentForOwnAccount {
  color: $practical-900;
  font: $font-ps-reg;

  .heading {
    margin-bottom: auto;
  }

  .subHeading {
    margin-bottom: 0;
    font: $font-ps-reg;
  }

  .content {
    margin: $space-16 0;
    font: $font-ps-reg;
  }

  .lists {
    margin: $space-4 0 $space-16 0;
    padding-left: $space-4;

    li {
      margin: $space-4 0 0 $space-12;
      font: $font-ps-reg;
    }
  }
}
