.audit-print {
  font-family: 'Lucida Grande', 'HelveticaNeue', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;

  &.container {
    width: 900px;
    margin: 30px auto;
    padding: 50px 105px;

    &.audit-print {
      width: 1170px !important;
    }

    .generated-at {
      margin: 30px 0;
    }

    .disclaimer {
      margin-top: 25px;
      color: $boulder;
      font-size: 11px;
      line-height: 1.4em;
    }

    table td,
    table th {
      padding: 5px 0;
      vertical-align: top;
      text-align: left;
    }

    table {
      width: 100%;

      &.particulars {
        margin-bottom: 35px;
      }

      &.audit-trail th {
        border-bottom: 1px solid $iron-500;
      }

      &.audit-trail td {
        border-bottom: 1px solid $iron-100;
      }

      th {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      td {
        font-size: 12px;

        &.subcontractor-information {
          line-height: 24px;
        }

        &.subtitle {
          width: 25%;
          font-weight: bold;
        }
      }
    }

    .left {
      float: left;
      width: 71%;
    }

    .right {
      float: right;
      width: 28%;
      text-align: right;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 31px;
      font-weight: normal;
    }

    h2 {
      color: $iron-500;
      font-size: 15px;
      font-weight: normal;
    }

    h3 {
      margin: 20px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid $iron-100;
      color: $jelly-bean;
      font-size: 13px;
    }

    .logo {
      // todo; rules for height and widths
    }
  }

  .btn {
    margin-bottom: 0;
    padding: 6px 12px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 4px;
    background-image: none;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4286;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    user-select: none;
  }

  .btn-info {
    border-color: $turquoise;
    background-color: $viking;
    color: $white;
  }
}

.stage-response-export {
  padding-top: 100px;
  background: none;

  .header-main {
    display: none;
  }

  .container {
    background: none;
  }

  .footer {
    display: none;
  }

  .action-area {
    clear: both;
    margin: 20px 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select-all-trades {
    font-size: 13px;
  }
}

.stage-rfq-pdf-export {
  @media all {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    font-size: 11px;

    &.orient-portrait {
      &.size-A4 {
        .pageOuter {
          height: 1000px;
          page-break-after: always;
        }

        .pageInner {
          height: 885px;
        }

        table {
          width: 680px;
        }
      }

      &.size-A3 {
        .pageOuter {
          height: 1400px;
          page-break-after: always;
        }

        .pageInner {
          height: 1290px;
        }

        table {
          width: 980px;
        }
      }
    }

    &.orient-landscape {
      &.size-A4 {
        .pageOuter {
          clear: both;
          height: 650px;
          page-break-after: always;
        }

        .pageInner {
          height: 575px;
        }

        table {
          width: 980px;
        }
      }

      &.size-A3 {
        .pageOuter {
          height: 880px;
          page-break-after: always;
        }

        .pageInner {
          height: 855px;
        }

        table {
          width: 1390px;
        }
      }
    }

    @page {
      margin: 0.5cm 1cm;
      size: 11.69in 8.27in;
    }

    span {
      padding: 2px 5px;
      border-radius: 3px;

      &.br {
        background: $roman !important;
      }

      &.bg {
        background: $ocean-green !important;
        color: $white !important;
      }

      &.by {
        background: $peach !important;
      }

      &.bb {
        background: $bourbon !important;
        color: $peach !important;
      }
    }

    table {
      width: 680px;
      padding-top: 10px;
      table-layout: fixed;
      border-collapse: collapse !important;
      border: 1px solid white !important;
      font-size: 11px;
      line-height: 16px;

      td,
      th {
        padding: 8px;
        vertical-align: top;
        border: 1px solid white !important;
        font-size: 9px;
        line-height: 11px;
      }

      th {
        height: 24px;
        background: black !important;
        color: white !important;
        text-align: left;
      }

      tr {
        max-height: 40px;

        &:nth-child(even) {
          background: $link-water !important;
        }

        &:nth-child(odd) {
          background: $link-water !important;
        }

        &.sup {
          background: $gallery !important;
        }

        &.tocTableHeading,
        &.indexTableHeader {
          td {
            background: $black !important;
            color: $white !important;
          }
        }

        &.indexTableHeader {
          td {
            &.companyName {
              width: 21%;
            }

            &.contactDetails {
              width: 28%;
            }

            &.documentStatus {
              width: 14%;
            }

            &.addendumStatus {
              width: 15%;
            }

            &.quoteResponse {
              width: 12%;
            }

            &.comments {
              width: 22%;
            }
          }
        }

        &.discHeading {
          td {
            background: $alto !important;
          }
        }

        &.supsHeading {
          td {
            background: $silver !important;
          }
        }

        &.tc {
          td {
            background: $alto !important;
            color: $cod-gray !important;
            font-weight: bold;
          }
        }

        &.trade_head {
          background: $alto !important;
          color: $cod-gray !important;
          font-weight: bold;
          text-transform: uppercase;
        }

        td {
          max-height: 40px;

          .contactName {
            float: left;
            width: 45%;
          }

          .contactEmail {
            float: right;
            width: 53%;
          }
        }
      }
    }

    .loader {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 320px;
      height: 200px;
      margin-top: -100px;
      margin-left: -160px;
      padding: 30px;
      background: $white !important;
      color: $iron-500 !important;
      text-align: center;

      .atebits {
        margin-bottom: 30px;
      }
    }

    .discHeading {
      background: $gallery !important;
      font-weight: bold;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 2px;
      color: $cerulean !important;
      font-family: 'Arial', 'Helvetica', sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 1em;
    }

    h2 {
      margin-top: 4px;
      margin-bottom: 10px;
      color: $cod-gray !important;
      font-family: 'Arial', 'Helvetica', sans-serif;
      font-size: 18px;
      font-weight: bold;
    }

    h3.listName {
      position: absolute;
      top: 10px;
      right: 10px;
      float: right;
      font-size: 18px;
    }

    .pageOuter:last-child {
      page-break-after: auto;
    }

    .footer {
      clear: both;
      height: 22px;
      margin: 0;
      padding: 0;

      .logo {
        float: left !important;
        width: 40% !important;
        height: 18px !important;
        margin-top: 4px !important;
        background-size: auto 18px !important;
      }

      .pageDate {
        float: right;
        color: $boulder !important;
        font-size: 10px;
        line-height: 1.2em;
        text-align: right;
      }
    }

    /** DO NOT CHANGE **/
    .pgHeader {
      padding: 0;
      padding-bottom: 25px;
    }

    .pageInner {
      height: 875px;
      padding: 0;
      overflow: hidden;
    }

    .pageOuter {
      height: 990px;
    }
  }

  @media print {
    #post_generate {
      z-index: 0;
      width: 0;
      height: 0;
      display: none;
    }
  }

  .hide {
    display: none;
  }
}

.addressbook-pdf-export {
  @media all {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    font-size: 11px;

    &.orient-portrait {
      &.size-A4 {
        .pageOuter {
          height: 1000px;
          page-break-after: always;
        }

        .pageInner {
          height: 885px;
        }

        table {
          width: 680px;
        }
      }

      &.size-A3 {
        .pageOuter {
          height: 1400px;
          page-break-after: always;
        }

        .pageInner {
          height: 1290px;
        }

        table {
          width: 980px;
        }
      }
    }

    &.orient-landscape {
      &.size-A4 {
        .pageOuter {
          clear: both;
          height: 650px;
          page-break-after: always;
        }

        .pageInner {
          height: 575px;
        }

        table {
          width: 980px;
        }
      }

      &.size-A3 {
        .pageOuter {
          height: 880px;
          page-break-after: always;
        }

        .pageInner {
          height: 855px;
        }

        table {
          width: 1390px;
        }
      }
    }

    @page {
      margin: 0.5cm 1cm;
      size: 11.69in 8.27in;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 2px;
      color: $cerulean !important;
      font-family: 'Arial', 'Helvetica', sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 1em;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 10px;
      color: $cod-gray !important;
      font-family: 'Arial', 'Helvetica', sans-serif;
      font-size: 23px;
      font-weight: 500;
    }

    h3.listName {
      position: absolute;
      top: 10px;
      right: 10px;
      float: right;
      font-size: 18px;
    }

    table {
      width: 680px;
      padding-top: 10px;
      table-layout: fixed;
      border-collapse: collapse;
      border: 1px solid white;
      font-size: 11px;
      line-height: 16px;

      td,
      th {
        padding: 8px;
        vertical-align: top;
        border: 1px solid white;
        font-size: 9px;
        line-height: 11px;
      }

      th {
        height: 24px;
        border: 1px solid white;
        background: black !important;
        color: white !important;
        font-size: 9px;
        line-height: 11px;
        text-align: left;
      }

      tr {
        max-height: 40px;

        &:nth-child(even) {
          background: $link-water !important;
        }

        &:nth-child(odd) {
          background: $link-water !important;
        }

        &.indexTableHeader {
          td {
            &.companyName {
              width: 23%;
            }

            &.contactName {
              width: 18%;
            }

            &.contactRole {
              width: 18%;
            }

            &.contactEmail {
              width: 28%;
            }

            &.contactPhone {
              width: 13%;
            }
          }
        }

        &.tocTableHeading,
        &.indexTableHeader {
          td {
            background: $black !important;
            color: $white !important;
          }
        }

        &.discHeading {
          td {
            background: $alto !important;
          }
        }

        &.tc {
          td {
            background: $alto !important;
            color: $cod-gray !important;
            font-weight: bold;
          }
        }

        &.trade_head {
          background: $alto !important;
          color: $cod-gray !important;
          font-weight: bold;
          text-transform: uppercase;
        }

        td {
          max-height: 40px;
        }
      }
    }

    #tempSpace {
      padding: 45px;
    }

    .loader {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 320px;
      height: 200px;
      margin-top: -100px;
      margin-left: -160px;
      padding: 30px;
      background: $white !important;
      color: $iron-500 !important;
      text-align: center;

      .atebits {
        margin-bottom: 30px;
      }
    }

    .tablePage {
      padding-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
    }

    .tocOuter h2:before {
      content: 'Table of Contents';
    }

    .tocTableHeadingTrade {
      width: 70%;
      background: $cod-gray !important;
      color: $white !important;
      font-weight: bold;
    }

    .tocTableHeadingPage {
      width: 30%;
      background: $cod-gray !important;
      color: $white !important;
      font-weight: bold;
    }

    .discHeading {
      background: $gallery !important;
      font-weight: bold;
    }

    /** DO NOT CHANGE **/
    .footer {
      clear: both !important;
      height: 22px !important;
      margin: 0;
      padding: 0;

      .logo {
        float: left !important;
        width: 40% !important;
        height: 18px !important;
        margin-top: 4px !important;
        background-size: auto 18px !important;
      }

      .pageDate {
        float: right;
        color: $boulder !important;
        font-size: 10px;
        line-height: 1.2em;
        text-align: right;
      }
    }

    .pgHeader {
      position: relative;
      padding: 0;
      padding-bottom: 25px;
    }

    .pageInner {
      height: 875px;
      padding: 0;
      overflow: hidden;
    }

    .pageOuter {
      position: relative;
      width: 100%;
      height: 990px;
      page-break-after: always;

      &:last-child {
        page-break-after: auto;
      }
    }
  }

  @media print {
    #post_generate {
      z-index: 0;
      width: 0;
      height: 0;
      display: none;
    }
  }

  .hide {
    display: none;
  }
}
