.preference-group {
  margin-bottom: $spacer-3;
}

.addressbook-builder {
  .img-box {
    top: 0;
    right: 1px;

    img {
      max-width: 180px;
      max-height: 90px;
    }
  }
}

.tcs-accept {
  margin-left: $spacer-3;
  display: flex;

  .checkbox {
    margin-right: $spacer-2;
  }
}

div.account-identify-modal {
  p {
    font-size: 14px;
  }

  .additional-info {
    ul {
      list-style-type: none;
      font-size: 14px;

      li:not(:last-child) {
        margin-bottom: 0.6em;
      }
    }
  }

  .account-decision-buttons {
    margin-top: 10px;

    button {
      width: 250px;
    }
  }
}
