@import '~@estimateone/frontend-components/src/css/variables';

.tradeList {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  gap: $space-4;

  .andMoreText {
    line-height: $space-16;
  }
}
