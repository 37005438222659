@import '~@estimateone/frontend-components/src/css/variables';

.accountActivityInfoWrapper {
  padding: $space-12 $space-24;
  border-bottom: 1px solid $iron-100;
  background-color: $iron-025;
}

.activityInfoText {
  color: $iron-600;
  font: $font-p-reg;
}
