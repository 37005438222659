@import '~@estimateone/frontend-components/src/css/variables';

.person {
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr 1fr;
  gap: $space-8;

  :nth-child(3) {
    text-align: right;
    white-space: normal;
    justify-self: end;
  }
}
