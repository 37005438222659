@import 'mixins';

.recently-awarded-projects-modal {
  min-width: 780px;
  color: $ebony-clay;
  text-align: center;

  .modal-header {
    padding-bottom: 0;
    border-bottom: none;

    h1 {
      margin-right: 30px;
      margin-left: 30px;
      padding-top: 40px;
      padding-bottom: 0;
      color: $san-juan;
      font-size: 23px;
      font-weight: bold;
      line-height: 32px;
    }

    p {
      padding-top: 25px;
      padding-bottom: 25px;
      font-size: 17px;
    }

    button.close {
      font-size: 24px;
    }
  }

  .modal-body {
    padding: 30px 40px;

    .row {
      margin-bottom: 12px;
      padding-bottom: 12px;

      .column-titles {
        font-size: 16px;
        text-align: left;
      }

      p {
        font-size: 14px;
        line-height: 21px;
        text-align: left;
      }
    }
  }

  a.upgrade-link {
    margin-top: 35px;
    padding: 16px 35px;
    border-color: $viking;
    font-size: 16px;

    div {
      margin-right: 24px;
      font-size: 16px;
      line-height: 30px;

      i {
        float: right;
        font-size: 1.5em;
      }
    }
  }
}
