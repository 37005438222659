@import '~@estimateone/frontend-components/src/css/variables';

.contactContainer {
  padding: $space-12;
  display: flex;
  flex-direction: column;
  border: 1px solid $iron-100;
  border-radius: $space-4;
  gap: $space-8;
}

.contactWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  color: $iron-300;
  word-break: break-all;
  gap: $space-8;
}

.nameColumn,
.contactDetailsColumn {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  gap: $space-4;
}

.nameRow {
  display: flex;
  flex-flow: row wrap;
  gap: $space-4;
}

.contactDetailsColumn {
  align-items: flex-end;
}

.name {
  margin: 0;
  font: $font-p-med;
  justify-self: flex-start;
}

.email,
.phone {
  margin: 0;
  align-self: flex-start;
  font: $font-p-reg;
}

.avatarWrapper {
  position: relative;
  align-self: start;
}

.meatballMenu {
  margin-left: $space-8;
}

.tag {
  font: $font-ps-med;
}

@media only screen and (min-width: $breakpoint-md) {
  .contactWrapper {
    grid-template-columns: auto 1fr 1fr min-content;

    > :nth-child(2) {
      margin-right: $space-8;
    }
  }

  .email,
  .phone {
    align-self: flex-end;
  }
}
