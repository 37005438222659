@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../css/_shared/borders';

.modalHeader {
  width: 100%;
  margin-left: 1.5em;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.modalSectionTitle {
  margin-bottom: $space-24;
  font: $font-p-heavy;
}

.benefitsList {
  margin-right: -0.5rem; // avoid lines wrapping
  list-style: none;

  svg {
    margin: 0.38rem 0.25rem 0.5rem;
    vertical-align: middle;
    stroke: $primary-magic-green;
  }

  li {
    font: $font-ps-reg;
  }
}

.summaryTableContainer {
  padding: $space-16;
  border: 1px solid $iron-100;
  border-radius: $border-radius-base;

  .summaryTable {
    width: 100%;

    .chosenSubscriptionRow {
      td {
        padding-bottom: $space-8;
      }
    }

    .totalRow {
      td {
        padding-top: $space-8;
      }
    }

    td:last-child {
      text-align: right;
    }

    .breakdownTableContainer {
      padding-top: $space-8;
      padding-bottom: $space-8;
      border-top: 1px solid $iron-100;
      border-bottom: 1px solid $iron-100;
    }

    .breakdownTable {
      width: 100%;

      td {
        font-size: $font-size-sm;

        &:first-child {
          text-align: left;
        }
      }
    }

    .tooltipAndInfoCtaContainer {
      display: flex;
      justify-content: flex-end;

      .calculationInfo {
        cursor: pointer;
        text-align: right;
        text-decoration: underline;
      }
    }
  }
}

.planDetailsCard {
  margin-bottom: $space-24;
  display: flex;

  .planDetailsCardContainer {
    display: flex;
    flex-direction: column;

    .planDetailsCardTitle {
      margin-bottom: 0;
      display: inline;
      color: $primary-practical-blue;
      font: $font-p-reg;
    }

    .planDetailsCardDesc {
      margin-bottom: 0;
      display: inline;
      color: $iron-600;
      font: $font-tiny-reg;
      line-height: 1.5 !important;
    }
  }

  .e1Logo {
    min-width: 3rem;
    min-height: 3rem;
    margin-right: $space-12;
    border: 1px solid $iron-200;
    border-radius: 4px;
    background: url('../../../images/EstimateOne-Logo-Primary-Practical.svg') no-repeat center center;
    background-size: 33px 36px;
  }
}

.updatePaymentMethod {
  margin-top: $space-4;
  color: $iron-600;
  font: $font-tiny-reg;
}

.creditCardBrandImage {
  height: 1.2em;
}

.creditCardExpiry {
  width: 50%;
  color: $iron-600;
  font-size: 0.8em;
  text-align: right;
}

.creditCardEnquire {
  margin-top: $space-4;
  color: $iron-600;
  font: $font-tiny-reg;
}

.payNowButton {
  margin-top: 15%;
}

.billingContactDetails {
  padding: $space-12;
  display: grid;
  /* stylelint-disable-next-line declaration-colon-space-after */
  grid-template-areas:
    'name name tag'
    'email email email';
  border: 1px solid $iron-100;
  border-radius: $border-radius-base;
  gap: 0;

  .name {
    grid-area: name;
  }

  .tag {
    grid-area: tag;

    span {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .email {
    grid-area: email;

    p {
      overflow-wrap: anywhere;
    }
  }

  .billingContactDetailsLoaderContainer {
    width: 100%;
    margin-top: -3px; // I don't want the default spacing - align with the tag
  }

  p {
    margin: 0;

    &.billingContactName {
      margin-bottom: $space-2;
      font: $font-p-med;
    }
  }
}

.doneButton {
  margin-top: 170px;
}

.anyProblems {
  margin-top: $space-4;
  color: $iron-600;
  font: $font-ps-reg;

  a {
    color: $iron-600;
    text-decoration: underline;
  }
}

input {
  &:hover {
    cursor: pointer;
  }
}

.downloadInvoice {
  margin-top: $space-8;
}

.errorBanner {
  margin: 0 $space-32;
  padding: $space-16;
  display: flex;
  flex-direction: row;
  gap: $space-8;

  p {
    margin: 0;
    font: $font-p-reg;
  }
}

.noUpgradeOptionPage {
  text-align: center;

  p {
    margin: $space-16 0;
    font: $font-p-reg;
  }
}
