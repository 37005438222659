@import '~@estimateone/frontend-components/src/css/variables';

.alert {
  display: flex;
  gap: $space-8 $space-16;

  span {
    font: $font-p-reg;
  }

  .closeButton {
    margin-left: auto;
  }
}
