@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../css/_shared/legacy/colours';

.form-group-custom-select {
  &.has-error {
    .react-select-container {
      .react-select__control {
        border-color: $medium-carmine;

        &.react-select__control--is-focused {
          box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 6px $fuzzy-wuzzy-brown;
        }
      }
    }
  }

  &.inline-select {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    > label {
      margin-right: 5px;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .help-block {
      position: absolute;
    }
  }

  .react-multi-select-container,
  .react-select-container {
    min-width: 110px;
    font-size: 13px;

    .react-select__control {
      min-height: 34px;
      border-radius: 3px;

      &.react-select__control--is-focused {
        border-color: $viking;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%);
      }

      .react-select__indicators {
        .react-select__dropdown-indicator {
          padding: 6px;

          > svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .react-multi-select-container {
    .react-select__control {
      background-color: transparent;
    }
  }

  .react-select__clear-indicator {
    &:hover {
      cursor: pointer;
    }
  }

  .react-select__group-heading {
    color: $cod-gray;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .react-select__multi-value {
    padding: 1px 6px 1px 3px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 4px;
    background: $puerto-rico;
    color: $white;
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    padding: 0;
  }

  .react-select__multi-value__label {
    padding-left: 0;
    color: $white;
    font-size: 13px;
  }

  .react-select__multi-value__remove {
    opacity: 0.7;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background: transparent;
      color: $mine-shaft;
    }
  }
}
