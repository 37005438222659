@import '~@estimateone/frontend-components/src/css/variables';

.drawerFooterButtonsWrapper {
  display: flex;
  justify-content: space-between;

  .button {
    width: 192px;
  }
}
