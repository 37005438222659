@import '~@estimateone/frontend-components/src/css/variables';

.supplierConnectionDetailsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.supplierConnectionContact {
  display: grid;
  grid-template-columns: 1fr auto auto;
  font: $font-ps-reg;

  .name {
    font: $font-ps-med;
  }

  .phoneNumber {
    padding: 0 $space-16;
    border-right: 1px solid $iron-200;
  }

  .email {
    padding-left: $space-16;
  }
}

.label {
  @extend %font-mini-caps;
  margin-top: $space-12;
  margin-bottom: $space-4;
  color: $iron-600;
}

.supplierConnectionContactNote {
  padding-bottom: $space-8;
  font: $font-ps-reg;
}
