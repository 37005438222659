@mixin status-icon($icon-class, $color) {
  @extend #{$icon-class};
  display: flex;
  align-items: center;

  &:before {
    @extend .icon;
    margin-right: $spacer-2;
    align-self: center;
    color: $color;
    font-size: 1.2em;
  }
}

@mixin status-button-style($icon-class, $color) {
  font-weight: $font-medium;

  &,
  &:active {
    border-color: $color;
    background-color: mix($color, #ffffff, 10%);
    color: $color;
  }

  &:focus,
  &:hover {
    border-color: $color;
    background-color: mix($color, #ffffff, 25%);
    color: $color;
  }

  .icon {
    color: $color;
  }

  .invitation-interest-levels-status,
  .project-interest-levels-status {
    @include status-icon($icon-class, $color);
  }
}

.interest-levels-dropdown {
  &[data-status='interested'],
  &[data-status='watching'] {
    &,
    &.open {
      button {
        @include status-button-style('.icon-interested', $color-interested);
      }
    }
  }

  &[data-status='declined'],
  &[data-status='not-interested'],
  &[data-status='hidden'] {
    &,
    &.open {
      button {
        @include status-button-style('.icon-not-interested', $color-not-interested);
      }
    }
  }

  &[data-status='quoted'] {
    &,
    &.open {
      button {
        @include status-button-style('.icon-quoted', $color-quoted);
      }
    }
  }

  &[data-status='quoting'] {
    &,
    &.open {
      button {
        @include status-button-style('.icon-quoting', $color-quoting);
      }
    }
  }
}
