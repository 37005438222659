@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeading {
  margin: 0;
  color: $white;
  font: $font-heading-2;
}

.sliderSteps {
  display: flex;
  justify-content: center;

  li {
    span {
      padding: 0 $space-16;
    }
  }

  li:last-of-type {
    span {
      padding: 0;
    }
  }
}

.modalHeading {
  margin-top: 0 !important;
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.modalActions {
  margin-top: $space-40;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.noDriveItemsText {
  margin: $space-16;
  color: $iron-600;
  font: $font-ps-reg;
  font-style: italic;
  text-align: center;
}
