@import '~@estimateone/frontend-components/src/css/variables';

.watchlistContent {
  margin: -$space-32;
  padding: $space-32;
  background-color: $steel;

  h2 {
    margin-top: 0;
    margin-bottom: $space-12;
  }

  p {
    margin-bottom: $space-32;
  }
}
