@import '~@estimateone/frontend-components/src/css/variables';

.packageSidebarContainer {
  position: sticky;
  top: 24px;
  max-height: 95vh;
  padding: $space-12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  border-radius: $space-8;
  background-color: $iron-025;
}

.packageSidebarTitle {
  margin-bottom: $space-12;
  font: $font-p-heavy;
}

.packageSelection {
  padding: 10px 8px 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border: none;
  outline: inherit;
  background: none;
  color: inherit;
  font: inherit;

  &:hover {
    border-radius: $space-4;
    background-color: rgb(102 102 102 / 5%);
  }

  &:active {
    outline: $space-4 solid $practical-500;
  }
}

.activePackageSelection {
  align-self: stretch;
  border-radius: $space-4;
  background-color: rgb(102 102 102 / 10%);
}

.disabledPackageSelection {
  color: $iron-300;

  &:hover {
    background-color: transparent;
  }

  &:active {
    outline: none
  }
}

.packageLabel {
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
  font: $font-ps-reg;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectedPackageLabel {
  display: flex;
  align-items: center;
  font: $font-ps-med;
  gap: $space-4;
}

.disabledPackageLabel {
  cursor: not-allowed;
  display: flex;
  align-items: center;
  color: $iron-300;
  font: $font-ps-med;
  gap: $space-4;
}

.selectedPackageIndicator {
  min-width: 4px;
  height: 16px;
  border-radius: 0 2px 2px 0;
  background-color: $magic-600;
}

.emptyPackageTag {
  cursor: not-allowed;
  color: $energy-800;

  .alertIcon {
    width: 20px;
    height: 20px;
    stroke: $energy-800;
  }
}

.disabledStatus {
  cursor: not-allowed;
  color: $iron-300;
}

.invitedTag {
  color: $practical-500;
}
