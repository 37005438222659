@import '~@estimateone/frontend-components/src/css/variables';

.syncStatusContainer {
  margin-right: $space-16;
  display: flex;
  flex-direction: row;
  align-items: center;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  span {
    margin-left: $space-4;
    color: $primary-practical-blue;
    font: $font-tiny-heavy;
  }

  .integrateIcon {
    fill: $primary-magic-green;
  }

  .menuButton {
    margin-left: $space-8;
    padding-bottom: $space-8;
  }

  .lastSynced {
    cursor: default;
    color: $iron-600;
    font-size: $font-size-sm;
  }

  .syncActionItem {
    color: $practical-900;
    font-size: $font-size-md;
  }

  .syncActionItemDivider {
    margin: 0 $space-32 0 $space-24;
    border: 0.5px solid #e6e6e6;
  }

  .updateIcon {
    animation: spin 4s linear infinite;
  }
}

.alertContainer {
  margin-top: $space-32;

  .bigSpacer {
    margin-top: $space-8;
    padding: $space-16;
  }

  .smallSpacer {
    margin-top: $space-8;
  }

  .alert {
    position: absolute;
    width: 1296px;

    .alertTable {
      width: 100%;
      margin-top: $space-8;
      border-collapse: collapse;
    }
  }
}
