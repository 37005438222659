@import '~@estimateone/frontend-components/src/css/variables';

.selectInstruction {
  margin-bottom: $space-16;
  color: $primary-practical-blue;
  font: $font-p-reg;
}

.row {
  font: $font-ps-reg;
}

.selectedRow {
  font: $font-ps-reg;
  font-weight: bold;
}

.disabledRow {
  background-color: $white;
  color: $iron-500;
  font: $font-ps-reg;

  &:hover {
    background-color: $white;
  }
}

.noProjectsText {
  margin: $space-16;
  color: $iron-600;
  font: $font-ps-reg;
  font-style: italic;
  text-align: center;
}

.noProjectsAlertContainer {
  display: flex;
  flex-direction: row;
  gap: $space-8;
}

.alertIcon {
  padding: $space-4;
  flex-shrink: 0;
}

.noProjectsAlertText {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: $space-16;

  p {
    margin: 0;
  }

  li {
    margin-left: $space-8;
  }
}

.selectCompany {
  margin-bottom: $space-16;
}
