.recipient {
  white-space: nowrap;
}

.edit-correspondence-ctn {
  hr {
    margin: 0 0 $spacer-2;
  }
}

.correspondence-email-item,
.mail-item {
  .recipient-name,
  .sender-name,
  .item-subject {
    margin-right: $spacer-1;
    font-weight: $font-medium;
  }

  .recipient-email,
  .sender-email {
    margin-right: $spacer-1;
    color: $iron-600;
    font: $font-tiny-reg;

    &:before {
      content: '(';
    }

    &:after {
      content: ');';
    }
  }

  .single-line {
    height: 1.6em;
    overflow: hidden;
  }
}

.mail-item {
  .contact-name,
  .mail-id {
    font-weight: $font-medium;
  }
}

/* Formats mail-items */
.mail-stand-alone-container {
  button {
    border-radius: 0 !important;
  }

  .container {
    width: 100%;
  }

  .thank-you-modal {
    width: 90%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 6px;

    .modal-header {
      border-bottom: none;
      background: $white;

      .e1-modal-close {
        opacity: 1;

        span {
          color: $heather;
          font-size: 26px;
          font-weight: bold;
        }
      }
    }

    .modal-body {
      padding: 0 20px;
      background: $white;
      text-align: center;

      .thank-you {
        color: $dove-gray;
        text-align: center;

        .icon-ok-circle {
          color: $aqua-forest;
          font-size: 87px;
        }

        .title {
          margin-bottom: 20px;
          font-size: 24px;
          font-weight: bold;
          line-height: 46px;
        }

        .message {
          margin-bottom: 32px;
          font-size: 15px;
          line-height: 36px;
        }
      }
    }
  }

  .reply-footer {
    position: fixed;
    z-index: 3;
    bottom: 0;
    width: 100%;
    height: 45px;
    margin: 0 -15px;
    padding: 0;

    button {
      float: left;
      width: 50%;
      height: 45px;
      margin: 0;
      padding: 0;
      border: none;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;

      &:first-child {
        box-shadow: inset 0 1px 0 0 rgb(70 184 218 / 50%);
        background-color: $viking;
        color: $white;
      }

      &:not(:first-child) {
        box-shadow: inset 0 1px 0 0 $heather;
        background-color: $catskill-white;
        color: $mako;
      }

      &:only-child {
        width: 100%;
      }
    }
  }
}

.mail-print {
  // Set the width of the iframe container to at most a bit less than a4 size.
  // Now the height we set the iframes to will be long enough to contain all their content
  // when the print function cuts their width down to fit an a4 sheet of paper.
  max-width: 7.8in;

  .correspondence-details-container {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid $legacy-iron;
    background-color: $catskill-white;

    h3,
    h4 {
      padding: 0.75em 0 0.75em 10px;
    }

    h4 {
      display: inline-block;
    }

    h4.corr-ref {
      color: $bali-hai;
    }
  }

  .mail-item-container {
    .mail-actions,
    a.btn {
      visibility: hidden;
    }

    div.recipients {
      .show-more,
      .hide-more {
        max-width: 960px !important;
      }
    }
  }
}

/* Because select-2 appends to the bottom of the page - we must globally apply the css */
span.select2-results {
  .contact-details {
    padding-top: 5px;
    display: block;
    opacity: 0.75;
    color: $bali-hai;
  }

  .contact-email {
    display: block;
    color: $tundora;
  }

  .select2-results__option--highlighted {
    .contact-details {
      padding-top: 5px;
      display: block;
      opacity: 0.75;
      color: $white;
    }

    .contact-email {
      display: block;
      color: $white;
    }
  }
}
