@import '../../../css/_shared/variables';
@import '../../../css/_shared/legacy/colours';

.alert {
  width: 330px;
  margin-bottom: 10px;
  padding: 20px 12px;
  border: solid 1px $oxford-blue;
  border-radius: 4px;
  box-shadow: 0 5px 15px 0 rgb(52 63 79 / 30%);
  background: $fiord;
  font-family: 'Lato', 'Arial', sans-serif;

  .icon {
    display: none;
  }

  .close {
    position: relative;
    top: -12px;
    right: 2px;
    float: right;
    padding: 0;
    cursor: pointer;
    opacity: 0.5;
    border: 0;
    background: transparent;
    color: $heather;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    text-shadow: 0 1px 0 $white;

    &:hover {
      opacity: 1;
      color: $white;
    }
  }

  .msgContainer {
    padding-left: 10px;
    color: $white;
    font-size: 14px;

    .headline {
      margin: 0 0 2px;
      display: block;
      color: $heather;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
