@import '~@estimateone/frontend-components/src/css/variables';

.divider {
  position: relative;
  width: 0;
  height: 0;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    width: 1px;
    height: 50px;
    transform: translate(50%, 50%);
    background-color: $iron-100;
  }
}

.title {
  margin-top: 0;
  color: $primary-practical-blue;
  font: $font-heading-3;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $iron-100;
  border-bottom: 1px solid $iron-100;

  &.sliderContainer {
    border-top: 0;
  }
}

.profileHeader {
  grid-area: profileHeader;
}

.profileBadges {
  grid-area: profileBadges;
}

.aside {
  grid-area: aside;
}

.profileContent {
  grid-area: profileContent;

  &.sliderProfileContent {
    padding-left: $space-24;
  }
}
