@import '~@estimateone/frontend-components/src/css/variables';

.selectedContactContainer {
  margin: $space-16 0 $space-32;
  display: flex;

  p {
    margin: $space-16 0 $space-16 $space-16;
    align-items: center;
  }

  .profileSection {
    margin-right: auto;
    display: flex;

    .nameSection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: var(--space-4, 4px);

      .nameField {
        margin: 0 $space-16;
      }

      .linkField {
        margin-left: $space-16;
        color: $primary-practical-blue;
        font-size: 12px;
        text-decoration-line: underline;
      }
    }
  }

  .contactDetailSection {
    display: flex;

    .email {
      padding-left: $space-16;
      border-left: 1px solid $iron-200;
      text-decoration-line: underline;
    }
  }
}

.contactHelpText {
  margin-bottom: 0;
  color: $iron-600;
  font: $font-ps-reg;
}

.primaryContactLabel {
  margin-bottom: $space-8;
}
