@import '~@estimateone/frontend-components/src/css/variables';

.connectionPreferenceTypeContainer {
  display: flex;
  gap: $space-4;
}

.connectionPreferenceTypeTag {
  height: $space-24;
  padding: $space-2 $space-4;
  display: flex;
  gap: $space-4;

  & svg {
    max-width: $space-12;
    max-height: $space-12;
    margin-right: 0;
    stroke: $iron-600;
  }
}
