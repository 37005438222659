@import '~@estimateone/frontend-components/src/css/variables';

.sliderHeading {
  margin: 0;
  color: $white;
  font: $font-heading-2;
}

.sliderFooter {
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.alert {
  margin-top: $space-48;
}

.alertContents {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tipIcon {
  margin-right: $space-12;
}

.modalTextContainer {
  margin-bottom: $space-32;
  padding: 0 $space-16;

  p {
    margin-bottom: $space-16;
  }
}

.modalHeading {
  margin-bottom: $space-32;
}

.modalAlertContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: $space-8;
}

.modalAlertIcon {
  flex-shrink: 0;
  stroke: $crimson-600;
  fill: $crimson-600;
}

.modalFileList {
  margin-bottom: $space-32;
  font: $font-p-heavy;

  p {
    margin-bottom: $space-8;
  }

  li {
    margin-left: $space-16;
  }
}

.modalButtonContainer {
  padding: 0 $space-16;
  display: flex;
  justify-content: space-between;
  gap: $space-16;
}

.procoreItemsDescription {
  margin-bottom: $space-24;
  color: $primary-practical-blue;
  font: $font-ps-reg;

  p:nth-child(1) {
    margin-bottom: $space-16;
  }
}
