@import '~@estimateone/frontend-components/src/css/variables';

.headerDaysBetween {
  padding-bottom: $space-4;
}

.headerExample {
  font: $font-tiny-reg;
}

.editableIntegerField {
  text-align: center;
}
