@import '~@estimateone/frontend-components/src/css/variables';

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subscriptionLength {
  font: $font-p-reg;
}

.price {
  font: $font-p-heavy;
}

.paymentDetailsHeading {
  font: $font-heading-3;
}

.paymentSelector {
  padding-top: $spacer-3;
}

.termsContainer {
  padding-top: $spacer-2;
  padding-bottom: $spacer-4;
  display: flex;
}

.terms {
  padding-left: $spacer-3;
  font: $font-tiny-reg;
  text-align: left;
}
