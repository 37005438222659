@import '~@estimateone/frontend-components/src/css/variables';

.sscWatchlistModalHeader {
  margin: 0 0 $space-32;
}

.sscWatchlistModalBody {
  margin-bottom: $space-48;
  font: $font-p-reg;

  p {
    margin-bottom: $space-16;
  }
}

.sscWatchlistModalFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-16;
}
