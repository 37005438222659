@import '~@estimateone/frontend-components/src/css/variables';

.qualificationsContent {
  display: flex;
}

.showHideDetails {
  padding-left: $space-8;
  font-size: $font-size-sm;
}
