@import '~@estimateone/frontend-components/src/css/variables';

.returnLink {
  &,
  &:hover {
    color: $iron-600;
    font: $font-tiny-reg;
    text-decoration: none;
  }
}
