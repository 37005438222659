.saved-search-container {
  &.at-capacity {
    .saved-search-card-add-new {
      display: none;
    }
  }

  &[data-phrase-count='0'] {
    .table-container,
    .cards-container {
      visibility: collapse;
    }

    .saved-search-zero-state {
      display: block;
    }
  }

  .btn-primary {
    border-bottom: none;
  }

  .table-container,
  .cards-container {
    visibility: visible;
  }

  .saved-search-zero-state {
    display: none;
  }
}

.saved-search-zero-state {
  padding: 25px 0 40px;
  border: 2px dashed $silver;
  border-radius: 4px;
  background: $gallery;

  h3 {
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
  }

  .zero-state-explainer {
    font-size: 14px;
  }
}

.saved-searches-table {
  th {
    font-size: 0.85em;
    text-transform: uppercase;
  }

  a {
    cursor: pointer;
    color: $san-juan;
    font-weight: bold;
    text-decoration: underline;
  }

  .icon-close {
    color: $apple-blossom;
    font-size: 0.75em;
  }
}

.saved-search-card {
  height: 130px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

  &:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  }

  &.saved-search-card-add-new {
    border: 1px dotted $silver-chalice;
    background-color: $gallery;
    color: $tundora;
  }

  &[data-category-type='0'] {
    .badge-invert {
      border-color: $mine-shaft;
      color: $mine-shaft;
    }
  }

  &[data-category-type='1'] {
    .badge-invert {
      border-color: $astral;
      color: $astral;
    }
  }

  &[data-category-type='2'] {
    .badge-invert {
      border-color: $puerto-rico;
      color: $puerto-rico;
    }
  }

  &[data-category-type='2049'] {
    .badge-invert {
      border-color: $bourbon;
      color: $bourbon;
    }
  }

  &[data-category-type='2050'] {
    .badge-invert {
      border-color: $bourbon;
      color: $bourbon;
    }
  }

  .card-body {
    color: $dusty-gray;

    .new-count {
      label {
        width: 125px;
        margin: 0;
      }
    }
  }

  .text-muted {
    color: $silver;
  }

  .badge-invert {
    border-width: 1px;
    border-style: solid;
    background: none;
  }
}

.add-saved-search-modal {
  &.create-failed {
    .validation-error {
      display: block;
    }
  }

  .validation-error {
    margin: -22px -27px 20px;
    display: none;
    border-radius: 0;
  }

  .btn-primary {
    border-bottom: none;
  }
}
