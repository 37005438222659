@import '~@estimateone/frontend-components/src/css/variables';

svg.contractIcon {
  stroke: $practical-600;
}

.errorIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
