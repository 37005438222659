@import '~@estimateone/frontend-components/src/css/variables';

.quoteViewPage {
  display: flex;
  flex-direction: column;

  .sectionSubtitle {
    margin-top: $spacer-5;
    font-weight: $font-semibold;
  }

  .errorMessage {
    color: $color-error;
  }

  .quoteMessage {
    white-space: pre-line;
  }

  .quoteAmount {
    color: $primary-magic-green;
    font: $font-heading-2;
  }

  .actionList {
    margin: 0;
    list-style: none;

    li {
      margin-bottom: $spacer-1;
    }
  }

  /* notes */
  .notesTip {
    color: $iron-700;
  }

  .noteTextarea {
    margin: $spacer-3 0;
  }

  .quoteActivityDigest {
    list-style: none;

    .entryNote,
    .entryLog {
      margin: $spacer-2 0;
      padding: $spacer-3;
      background-color: $steel;

      .author {
        margin-top: $spacer-2;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .date {
        margin-left: $spacer-2;
        display: inline-block;
        color: $iron-700;
        font: $font-tiny-reg;
      }
    }

    .entryLog {
      background-color: $magic-050;

      em {
        font-style: normal;
        font-weight: bold;
      }

      .date {
        margin-left: 0;
      }
    }
  }

  .versions {
    padding: $spacer-4;
    border: 2px solid $iron-100;
    border-radius: 4px;

    .uploadedDetails {
      font: $font-tiny-reg;
    }
  }
}

.cardTitle {
  font-weight: $font-semibold;
}

.cardSubtitle {
  font-size: $font-size-lg;
  line-height: $font-lh-lg;
  overflow-wrap: break-word;
}

.contactAddress {
  font: $font-ps-reg;
}

.deleteQuoteButtonContent {
  color: $crimson-600;
}

.modalContent {
  margin: $spacer-5 0;
}
