@import '~@estimateone/frontend-components/src/css/variables';

.contentContainer {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.headingWrapper {
  display: grid;
}

.heading {
  margin-bottom: $space-32;
  font: $font-heading-3;
}

.subHeading {
  margin-bottom: $space-24;
  font: $font-p-reg;
}

.optionHeading {
  margin-bottom: $space-16;
  font: $font-p-heavy;
}

.optionHeading .asterisk {
  vertical-align: super;
  font-size: $font-tiny-reg;
}

.validationError {
  color: $crimson-600;
}

.formContainer {
  max-height: 50vh;
  overflow-y: auto;
}

.checkboxGroupContainer {
  display: flex;
  column-gap: $space-4;
  align-items: center;
}
