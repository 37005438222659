@import '~@estimateone/frontend-components/src/css/variables';

.btnFilter {
  transition: 200ms;
  border: 1px solid rgba($primary-practical-blue, 0.3);
  border-radius: 22px;
  background-color: $white;
  color: $iron-600;
  font-weight: $font-medium;

  &:hover {
    background-color: $iron-025;
    color: $primary-practical-blue;
  }

  &.btnFilterActive {
    border-color: $primary-magic-green;
    background: $magic-050;
    color: $primary-practical-blue;

    &:hover {
      background-color: $magic-200;
    }

    label {
      margin: 0;
      padding-right: 0.4rem;
      opacity: 0.6;
      font: $font-ps-reg;
    }
  }

  &.btnFilterHighlighted {
    border-color: $primary-magic-green;
    background-color: $magic-200;
    color: $primary-practical-blue;
  }
}
